import { useAppStore } from '@/store/app.ts'
import { useProductListStore } from '@/store/productListStore'
import { getCurrencyUtil } from '@gohighlevel/ghl-proposals-common'

export const useCurrency = () => {
  const appStore = useAppStore()
  const productListStore = useProductListStore()
  return getCurrencyUtil(
    appStore.currency?.value as string,
    productListStore?.currencyDetails?.currency
  )
}
