<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'
import { useAppStore } from '@/store/app'
import { PlusIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIDrawer,
  UIDrawerContent,
  UIFormItem,
  UIInput,
} from '@gohighlevel/ghl-ui'
import { ref, computed } from 'vue'
import AddCustomValueModal from './variables/AddCustomValueModal.vue'
import { DOC_VAR_PREFIX } from '@/helper/const'
import { DocumentCustomVariable } from '@gohighlevel/ghl-proposals-common'
import { useBuilderStore } from '@/store/builder'

const builderStore = useBuilderStore()
const appStore = useAppStore()
const storeAction = useActionBarStore()
const docLinkedVariables = computed(
  () => builderStore.getAllLinkedDocumentVariables() || []
)

const variableList = computed(() => {
  const docDefaultVariables = appStore.document?.variables || []
  return [...docDefaultVariables, ...docLinkedVariables.value]
})

function onClose() {
  storeAction.toggleDocumentVariable()
}

const searchString = ref('')

const showNewVariableModal = ref(false)

function newVariable(newVariable: {
  fieldKey: string
  value: string
  type: string
}) {
  // when new variables comes, append document to the key.
  newVariable.fieldKey = `${DOC_VAR_PREFIX}.${newVariable.fieldKey}`
  appStore.updateDocumentVariable(newVariable)
}

function updateVariableValue(value: string, fieldKey: string) {
  appStore.updateDocumentVariable({ fieldKey, value })
}

function deleteCustomVariable(value: string) {
  appStore.deleteDocumentCustomVariable(value)
}

const nonEditableVariables = ['document.createdDate', 'document.refNumber']
const docLinkedVariablesFieldKeys = computed(() =>
  docLinkedVariables.value.map(variable => variable.fieldKey)
)
</script>

<template>
  <UIDrawer
    :width="340"
    id="elements-drawer"
    :show="storeAction.documentVariableActive"
    @update:show="onClose"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-screen"
  >
    <UIDrawerContent
      id="pages-drawer-content"
      :title="$t('common.documentVariables')"
    >
      <AddCustomValueModal
        @update:newVariable="newVariable"
        v-model:show="showNewVariableModal"
        :availableVariables="variableList.map(variable => variable.fieldKey)"
      />
      <div class="-mx-6 -my-4 overflow-auto max-h-[78vh] px-6 py-4">
        <div class="flex flex-col justify-items-end proposal-pages-scroll">
          <div class="flex justify-items-center mb-6 space-x-1">
            <UIInput
              id="search"
              :placeholder="$t('common.search')"
              v-model="searchString"
            />
            <UIButton id="add_new" @click="showNewVariableModal = true"
              ><PlusIcon class="h-5 w-5"></PlusIcon
            ></UIButton>
          </div>
          <div
            class="standard-fields"
            v-for="(variables, index) in variableList"
            :key="index"
          >
            <UIFormItem
              :label="variables.fieldKey"
              v-if="
                variables.fieldKey
                  .toLocaleLowerCase()
                  .includes(searchString.toLocaleLowerCase())
              "
            >
              <DocumentCustomVariable
                :variable="variables"
                @deleted="fieldKey => deleteCustomVariable(fieldKey)"
                @updated="
                  (fieldKey, value) => updateVariableValue(value, fieldKey)
                "
                :readonly="
                  nonEditableVariables.includes(variables.fieldKey) ||
                  docLinkedVariablesFieldKeys.includes(variables.fieldKey)
                "
                :disabled="
                  docLinkedVariablesFieldKeys.includes(variables.fieldKey)
                "
              />
            </UIFormItem>
          </div>
        </div>
      </div>
    </UIDrawerContent>
  </UIDrawer>
</template>

<style>
/* .proposal-pages-scroll {
  height: calc(100vh - 160px);
  overflow-y: auto;
} */
</style>
