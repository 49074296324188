<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import CustomModal from '@/components/common/CustomModal.vue'
import { computed, ref, watch } from 'vue'
import {
  DefaultValidationObjectForDocument,
  ModalEvents,
} from '@gohighlevel/ghl-proposals-common'
import { useProductListStore } from '@/store/productListStore'
const productListStore = useProductListStore()

const props = defineProps({
  loading: { type: Boolean, default: false },
  show: { type: Boolean, default: false },
})
defineEmits([ModalEvents.ON_SUCCESS, ModalEvents.ON_CANCEL])
const showErrorModal = ref(false)
const showLoading = ref(false)
const validations = ref(DefaultValidationObjectForDocument)
const isAllValid = ref(false)
watch(props, updatedProps => {
  showErrorModal.value = updatedProps.show
  showLoading.value = updatedProps.loading
})

const scheduleErrors = computed(() => productListStore.getProductListErrors())
</script>

<template>
  <CustomModal
    :close-on-success="false"
    :loading="showLoading"
    :disabled="showLoading"
    :show="showErrorModal"
    :title="
      isAllValid ? $t('common.validDocument') : $t('common.invalidDocument')
    "
    :description="!isAllValid ? $t('validations.invalid.docMustFields') : ''"
    @on-cancel="$emit(ModalEvents.ON_CANCEL)"
    @on-success="$emit(ModalEvents.ON_SUCCESS)"
    :cancelActionText="isAllValid ? $t('common.cancel') : $t('common.ok')"
    :successActionText="isAllValid ? $t('common.send') : ''"
    :modalType="isAllValid ? 'success' : 'error'"
  >
    <div class="flex flex-col" v-if="!isAllValid">
      <div class="flex items-center mb-3">
        <span
          :class="`h-4 w-4 mr-2 ${
            validations.isRecipientsAdded ? 'text-success-500' : 'text-red-500'
          }`"
          ><CheckCircleIcon v-if="validations.isRecipientsAdded" />
          <XCircleIcon v-if="!validations.isRecipientsAdded"
        /></span>
        {{
          validations.isRecipientsAdded
            ? $t('validations.invalid.recipientsSelected')
            : $t('validations.invalid.missingPrimaryRecipient')
        }}
      </div>

      <div class="flex items-center">
        <span
          :class="`h-4 w-4 mr-2 ${
            validations.isProductListValid ? 'text-success-500' : 'text-red-500'
          }`"
          ><CheckCircleIcon v-if="validations.isProductListValid" />
          <XCircleIcon v-if="!validations.isProductListValid" />
        </span>
        {{
          validations.isProductListValid
            ? $t('validations.invalid.validProductListConfig')
            : $t('validations.invalid.productListConfig')
        }}
      </div>
      <div class="flex items-center">
        <span>
          <ul style="list-style-type: disc">
            <li v-for="(scheduleError, index) in scheduleErrors" :key="index">
              {{ scheduleError }}
            </li>
          </ul>
        </span>
      </div>
    </div>
  </CustomModal>
</template>
