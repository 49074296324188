import { UserService } from '@/service/UserService'
import { ref } from 'vue'

export const useUsers = () => {
  const loading = ref(false)
  const fetchUser = async (userId: string): Promise<any> => {
    try {
      loading.value = true
      const data = await UserService.getUserById(userId)
      return data
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }
  return {
    loading,
    fetchUser,
  }
}
