<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'
import { useBuilderStore } from '@/store/builder'
import { UIDrawer, UIDrawerContent, UITextLgMedium } from '@gohighlevel/ghl-ui'

const store = useBuilderStore()
const storeAction = useActionBarStore()
function onClose() {
  storeAction.toggleSettings()
}
</script>

<template>
  <UIDrawer
    :width="340"
    id="elements-drawer"
    :show="storeAction.settingsActive"
    @update:show="onClose"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-screen"
  >
    <UIDrawerContent
      id="doc-settings-drawer-content"
      :title="$t('common.documentSettings')"
    >
      <div>
        <div class="flex flex-col justify-items-end proposal-pages-scroll">
          <div
            v-for="(page, index) in store.pages"
            class="w-full flex justify-center items-center mb-5"
            :key="index"
            :id="page.id"
          >
            <div
              class="flex justify-center border w-40 h-52 shadow hover:shadow-lg cursor-pointer"
            >
              <div class="flex justify-center w-full items-center h-full">
                <UITextLgMedium>
                  {{ index + 1 }}
                </UITextLgMedium>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UIDrawerContent>
  </UIDrawer>
</template>

<style>
/* .proposal-pages-scroll {
  height: calc(100vh - 160px);
  overflow-y: auto;
} */
</style>
