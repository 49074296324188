<script lang="ts" setup>
import { Editor, EditorContent } from '@tiptap/vue-3'
import {
  FontFamily,
  LineHeight,
  Link,
  RequiredExtensions,
  TextAlign,
  TextBlocks,
  TextSize,
  Underline,
  Color,
} from '@gohighlevel/ghl-text-editor'
import Document from '@tiptap/extension-document'
import Gapcursor from '@tiptap/extension-gapcursor'
import Paragraph from '@tiptap/extension-paragraph'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import { PropType, computed, reactive, ref, watch } from 'vue'
import {
  MagicPlaceholderExtension,
  ELEMENTS_META,
  IElement,
} from '@gohighlevel/ghl-proposals-common'
import { columnResizing, tableEditing } from '@tiptap/pm/tables'
import { EditorState } from '@tiptap/pm/state'
import { useElementOption } from '@/composable/elementOptions'
import { useDocument } from '@/composition'
import { useBuilderStore } from '@/store/builder'
import { TableCellAddAttributes } from '@/util/tableCellExtend'
import TableMenuComponent from '../common/TableMenuComponent.vue'

const editor = ref()
const store = useBuilderStore()
const isEditable = computed(() => store.isDocumentEditable)
const { loadingCustomValues } = useDocument()

const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
    required: true,
  },
  customValues: {
    type: Object as PropType<Record<string, string>>,
    required: true,
  },
})

const { updateOption } = useElementOption()

editor.value = new Editor({
  extensions: [
    Document,
    Paragraph,
    Text,
    MagicPlaceholderExtension.configure({
      placeholderValues: props.customValues,
      loading: loadingCustomValues.value,
    }),
    Gapcursor,
    Table.configure({
      resizable: true,
    }).extend({
      addProseMirrorPlugins() {
        return [
          columnResizing({
            handleWidth: this.options.handleWidth,
            cellMinWidth: this.options.cellMinWidth,
            View: this.options.View,
            lastColumnResizable: this.options.lastColumnResizable,
          }),
          tableEditing({
            allowTableNodeSelection: this.options.allowTableNodeSelection,
          }),
        ]
      },
    }),
    TableRow,
    TableHeader,
    TableCell.extend(TableCellAddAttributes),
    RequiredExtensions,
    TextBlocks,
    StarterKit,
    TextStyle,
    FontFamily,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Underline,
    Link,
    LineHeight,
    TextSize,
    Color,
  ],
  onUpdate({ editor }) {
    updateOption(
      ELEMENTS_META.TEXT,
      editor.getHTML(),
      props?.element?.id,
      props?.element?.type
    )
  },
  autofocus: true,
  content: props.element.component.options.text,
  editable: isEditable.value,
  parseOptions: {
    preserveWhitespace: true,
  },
  editorProps: {
    attributes: (state: EditorState) => {
      return {
        ...state.doc.attrs,
        style: 'font-family: sans-serif', // Default font-family
      }
    },
  },
})
watch(isEditable, () => {
  editor.value.setEditable(isEditable.value)
})

watch(
  () => store.moveElementDragState,
  val => {
    editor.value.setEditable(!val)
  }
)

editor.value.on('focus', () => {
  store.updateEditorInstance(editor.value)
})
editor.value.off('focus', () => {
  console.info('offfocus')
  // store.updateEditorInstance(undefined)
})
const activeElementId = computed(() => store.activeElementId)

const state = reactive({
  isActiveElement: computed(() => {
    if (!props.element) return false
    return activeElementId.value === props.element.id
  }),
})
</script>

<template>
  <TableMenuComponent v-if="state.isActiveElement" :editor="editor" />
  <div class="w-full overflow-auto">
    <EditorContent :editor="editor" />
  </div>
</template>
