<script setup lang="ts">
import { PropType, onMounted, ref } from 'vue'
import { Direction, IElement } from '../../types'
import { useClickOutSide } from '../../composition'
import { throttle } from '../../utils'
import ElementDropAbles from '../ElementDropAbles/ElementDropAbles.vue'

const emits = defineEmits([
  'onDragOver',
  'onDragExit',
  'onDropElement',
  'onDragEnter',
  'onDragStart',
  'onDragEnd',
  'click',
  'mouseOver',
  'mouseLeave',
  'onMoveStart',
  'onMoveEnd',
])
const props = defineProps({
  forInitial: {
    type: Boolean,
    required: false,
    default: false,
  },
  dragEnabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  rowIndex: {
    type: Number,
    required: true,
  },
  colIndex: {
    type: Number,
    required: true,
  },
  rowColIndex: {
    type: Number,
    required: false,
    default: -1,
  },
  isLastRowCol: {
    type: Boolean,
    required: false,
    default: true,
  },
  isLastRow: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLastCol: {
    type: Boolean,
    required: false,
    default: false,
  },
  element: {
    type: Object as PropType<IElement>,
    required: false,
  },
  pageId: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: false,
    default: '100%',
  },
  isNested: {
    type: Boolean,
    required: false,
    default: false,
  },
  draggable: {
    type: Boolean,
    required: false,
    default: false,
  },
  rowId: {
    type: String,
    required: false,
  },
  snapContainerClass: {
    type: String,
    required: true,
  },
  mainIndex: {
    type: Number,
    required: true,
  },
})

const direction = ref<Direction | null>()
const draggingOver = ref(false)
const wrapperRef = ref<HTMLElement | null>(null)
const { onClickOutSide } = useClickOutSide()

const onDragEnter = (ev: any, dir: Direction) => {
  ev.preventDefault()
  draggingOver.value = true
  emits('onDragEnter', ev, { dir, rowIndex: props.rowIndex })
}
const onDragOver = (ev: any, dir: Direction) => {
  ev.preventDefault()

  if (draggingOver.value === false) {
    draggingOver.value = true
  }
  if (direction.value !== dir) {
    direction.value = dir
  }
  emits('onDragOver', ev, { dir, rowIndex: props.rowIndex })
}

const onDragExit = (ev: any) => {
  draggingOver.value = false
  direction.value = null
  emits('onDragExit', ev)
}

const onDropElement = (
  ev: MouseEvent,
  payload: {
    dir: Direction
    rowIndex: number
    colIndex?: number
    currentPageId?: string
    rowColIndex?: number
  }
) => {
  ev.preventDefault()

  draggingOver.value = false
  direction.value = null
  emits('onDropElement', ev, {
    ...payload,
  })
}

onMounted(() => {
  onClickOutSide(
    wrapperRef,
    (e: MouseEvent) => {
      emits('click', e, null)
    },
    {
      capture: false,
      ignore: [
        '.overlay-actions',
        '.overlay-container',
        '.handle',
        `.v-binder-follower-container`,
        '.moveable-wrapper--ables',
        '#active-elements-drawer',
        '.n-modal-container',
      ],
    }
  )
})
const onDragStart = (evt: DragEvent, type: string) => {
  const dragImage = document.getElementById(`draggable-element-${type}`)
  if (!dragImage) return
  dragImage.style.display = 'block'

  evt.dataTransfer.dropEffect = 'move'
  evt.dataTransfer.effectAllowed = 'move'
  evt.dataTransfer?.setDragImage(dragImage as Element, 0, 0)
}
</script>

<template>
  <div
    class="relative w-full h-full items-center z-1 ghl-dropzone flex-1 z-[50]"
    :class="{
      'ghl-dropzone--activated': draggingOver,
    }"
    v-if="forInitial"
    :ondragenter="(ev: any) => onDragEnter(ev, 'top')"
    :ondragover="(ev: any) => throttle(onDragOver(ev, 'top'), 500)"
    :ondragleave="onDragExit"
    :ondrop="(ev: MouseEvent) => onDropElement(ev,  {dir: 'top', rowIndex: 0, colIndex: -1, currentPageId: props.pageId})"
  ></div>
  <template v-else>
    <div class="relative clearfix docView" :style="{ width }">
      <section
        ref="wrapperRef"
        class="flex relative clearfix boundBox docView"
        @click="e => $emit('click', e, element)"
        @mouseleave="e => $emit('mouseLeave', e)"
        @mouseover="e => $emit('mouseOver', e, element)"
        :key="element?.id"
        :class="{
          'sortable-chosen sortable-ghost': dragEnabled,
        }"
        :draggable="draggable"
        style="transform: translateZ(0px); z-index: 49"
        @dragstart="(evt: DragEvent) => onDragStart(evt, element?.type as string)"
        :data-id="element?.id"
        :data-type="element?.type"
        :data-page-id="pageId"
      >
        <ElementDropAbles
          v-if="dragEnabled"
          :key="`${element?.id}-nested-dropables`"
          :drag-enabled="dragEnabled"
          :rowIndex="rowIndex"
          :colIndex="colIndex"
          :isLastRow="isLastRow"
          :pageId="pageId"
          :isNested="isNested"
          :row-col-index="rowColIndex"
          @on-drag-over="(...args: any[]) => $emit('onDragOver', ...args)"
          @on-drag-exit="(...args: any[]) => $emit('onDragExit', ...args)"
          @on-drop-element="
            (...args: any[]) => {
              $emit('onDropElement', ...args)
            }
          "
          :top="true"
          :left="true"
          :right="true"
          :bottom="true"
          left-able-class-name="col-dropables"
          :rowId="props.rowId"
        />
        <slot name="target"></slot>
        <slot name="overlay-section"></slot>
      </section>
    </div>
  </template>
</template>

<style lang="scss">
.ghl-dropzone {
  &--activated {
    &::after {
      background-color: var(--primary-600) !important;
    }
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    height: 4px;
    width: 100%;
    transform: translateY(-50%);
    top: 0%;
  }
}
</style>
