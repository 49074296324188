<script setup lang="ts">
import { PropType, ref } from 'vue'
import BlockElementRenderer from './BlockElementRenderer.vue'
import {
  ELEMENTS_LOOKUP,
  ELEMENTS_STYLES,
  IElement,
  Splitter,
  ElementDropAbles,
} from '@gohighlevel/ghl-proposals-common'
import { useBuilderStore } from '@/store/builder'
import { useElementOption } from '@/composable/elementOptions'
import { useActionBarStore } from '@/store/actionBar'
defineProps({
  elements: {
    type: Array as PropType<IElement[]>,
    required: true,
  },
  dragEnabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  pageId: {
    type: String,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  snapContainerClass: {
    type: String,
    required: true,
  },
  boundContainerClass: {
    type: String,
    required: true,
  },
})
const emits = defineEmits([
  'onDropElement',
  'onMoveStart',
  'onMoveEnd',
  'click',
  'mouseOver',
  'mouseLeave',
])

const store = useBuilderStore()
const actionBarStore = useActionBarStore()
const currentActiveRowId = ref('')
const { updateStyles } = useElementOption()
const onMouseDownOnSplitter = (e: MouseEvent, rowId: string) => {
  e.stopPropagation()
  store.setActiveElementId(null)
  currentActiveRowId.value = rowId
}

const onMouseUpOnSplitter = () => {
  currentActiveRowId.value = ''
}

const onChange = ({
  left,
  right,
}: {
  left: { width: string; id: string }
  right: { width: string; id: string }
}) => {
  updateStyles(ELEMENTS_STYLES.WIDTH, `${left.width}`, left.id)
  updateStyles(ELEMENTS_STYLES.WIDTH, `${right.width}`, right.id)
}

const onDropElement = (...args: any[]) => {
  actionBarStore.toggleDragState(false)
  store.moveElementDragState = false
  emits('onDropElement', ...args)
}
</script>

<template>
  <template
    v-for="(element, idx) in elements"
    :key="`${element.id}-BlockElementsRenderer`"
  >
    <BlockElementRenderer
      v-if="element.type !== ELEMENTS_LOOKUP.ROW"
      :key="element.id + `-block-element-renderer`"
      :element="element"
      :dragEnabled="dragEnabled"
      @on-move-start="(...args) => $emit('onMoveStart', ...args)"
      @on-move-end="(...args) => $emit('onMoveEnd', ...args)"
      @click="(...args) => $emit('click', ...args)"
      @mouseOver="(...args) => $emit('mouseOver', ...args)"
      @mouseLeave="(...args) => $emit('mouseLeave', ...args)"
      @on-drop-element="(...args) => $emit('onDropElement', ...args)"
      :rowIndex="idx"
      :colIndex="0"
      :rowColIndex="-1"
      :isLastRow="idx === elements?.length - 1"
      :isLastCol="true"
      :pageId="pageId"
      width="100%"
      :pageIndex="pageIndex"
      :snapContainerClass="snapContainerClass"
      :boundContainerClass="boundContainerClass"
      :mainIndex="idx"
    />
    <div
      v-else
      class="row flex relative docView"
      style="margin: 16px 0px"
      :id="element.id"
    >
      <div
        style="position: relative; flex: 1; display: flex; width: 100%"
        class="docView"
      >
        <template
          v-for="(column, colIdx) in element.children"
          :key="column?.id"
        >
          <Splitter
            v-if="colIdx > 0 && colIdx < element.children.length"
            :container-id="element.id"
            :left-column-id="element.children[colIdx - 1]?.id"
            :right-column-id="column?.id"
            :left-width="
              element.children[colIdx - 1]?.responsiveStyles.large.width ||
              `${100 / element.children.length}%`
            "
            :right-width="
              column.responsiveStyles.large.width ||
              `${100 / element.children.length}%`
            "
            @on-mouse-down="e => onMouseDownOnSplitter(e, element?.id)"
            @on-mouse-up="onMouseUpOnSplitter"
            @on-change="onChange"
            v-bind:key="'splitter-' + element.id + '-' + column.id"
            :class="dragEnabled ? 'visibility-hidden' : ''"
          />
          <div
            class="docView"
            style="
              display: flex;
              flex: 1 1 auto;
              flex-direction: column;
              min-width: 0;
              position: relative;
            "
            :id="column.id"
            :style="{
              width:
                column.responsiveStyles.large.width ||
                `${100 / element.children.length}%`,
            }"
          >
            <template
              v-for="(elem, rowColIndex) in column.children"
              :key="element?.id + elem?.id + `-block-element-renderer`"
            >
              <BlockElementRenderer
                v-if="elem"
                :key="
                  element?.id + elem?.id + `-block-element-renderer-nested-key`
                "
                :element="elem"
                :dragEnabled="dragEnabled"
                @on-move-start="(...args) => $emit('onMoveStart', ...args)"
                @on-move-end="(...args) => $emit('onMoveEnd', ...args)"
                @click="(...args) => $emit('click', ...args)"
                @mouseOver="(...args) => $emit('mouseOver', ...args)"
                @mouseLeave="(...args) => $emit('mouseLeave', ...args)"
                @on-drop-element="(...args) => $emit('onDropElement', ...args)"
                :rowIndex="idx"
                :colIndex="colIdx"
                :isLastRow="idx === elements?.length - 1"
                :isLastCol="colIdx === element.children?.length - 1"
                :pageId="pageId"
                :rowId="element.id"
                :rowColIndex="rowColIndex"
                :isLastRowCol="rowColIndex === column.children?.length - 1"
                :isNested="true"
                :pageIndex="pageIndex"
                :snapContainerClass="snapContainerClass"
                :boundContainerClass="boundContainerClass"
                :main-index="colIdx"
              />
            </template>
            <span
              v-if="dragEnabled"
              :ondragover="event => event.preventDefault()"
              :ondrop="(ev: DragEvent) => onDropElement(ev,  {
                dir: 'bottom',
                rowIndex:idx,
                colIndex: colIdx,
                rowColIndex:column.children.length,
                rowId: element.id,
                currentPageId: pageId
              })"
              class="relative block bg-transparent bottom-0 left-0 w-full z-0 flex-1"
            ></span>
          </div>
        </template>
      </div>
      <ElementDropAbles
        v-if="dragEnabled"
        :dragEnabled="dragEnabled"
        :bottom="true"
        :top="true"
        :left="false"
        :right="false"
        :row-col-index="-1"
        :row-index="idx"
        :col-index="-1"
        :page-id="pageId"
        @on-drop-element="(...args) => onDropElement(...args)"
        :is-nested="false"
        top-able-class-name="h-[30px] row-dropables"
        bottom-able-class-name="h-[30px] !bottom-0 row-dropables"
        :key="element.id + `-row-dropables`"
      />
    </div>
  </template>
</template>

<style lang="scss">
.row {
  &:hover {
    .splitter {
      visibility: visible;
    }
  }
}
</style>
