<script setup lang="ts">
import {
  WithCommonProvider,
  SignatureElement,
} from '@gohighlevel/ghl-proposals-common'

const WrappedComponent = WithCommonProvider(SignatureElement)
</script>

<template>
  <WrappedComponent />
</template>
