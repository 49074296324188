<script lang="ts" setup>
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import Postmate from 'postmate'

import MediaPicker from './components/common/MediaPicker.vue'
import { useAppStore } from './store/app'
import { CommonProvider } from '@gohighlevel/ghl-proposals-common'
import { useBuilderStore } from './store/builder'
import { useElementOption } from './composable/elementOptions'
import { useI18n } from 'vue-i18n'
import { setSentryMeta } from '@gohighlevel/ghl-proposals-common'
const i18n = useI18n()

if (process.env.NODE_ENV === 'development') {
  // Postmate.debug = true;
}

const appStore = useAppStore()
const handshake = new Postmate.Model({
  'updated-token': (token: string) => appStore.refreshToken(token),
})
window.getToken = function () {
  return appStore.tokenId
}
handshake.then(async ({ model }: any) => {
  appStore.setupApp({ ...model, handshake })
  setSentryMeta({
    userId: model.userId,
    companyId: model.companyId,
    locationId: '',
    email: model.email,
    locale: model.locale,
  })
  setLocales(model.locale || 'en-US')
})

const setLocales = (lang: string) => {
  let locale = lang
  locale = locale.replace('_', '-')
  const localLang =
    i18n.availableLocales.indexOf(locale) > -1 ? locale : 'en-US'
  localStorage.setItem('locales', localLang)
  i18n.locale.value = localLang
  appStore.updateLocale(localLang)
}
</script>

<template>
  <CommonProvider
    :storeMap="{ useAppStore, useBuilderStore }"
    :compositionMap="{
      useElementOption,
    }"
  >
    <UIContentWrap :full-screen="true">
      <MediaPicker />

      <router-view v-if="appStore.tokenId"></router-view>
    </UIContentWrap>
  </CommonProvider>
</template>

<style lang="scss"></style>
