<script lang="ts" setup>
import { PropType, watch, ref, onBeforeUnmount } from 'vue'
import {
  ELEMENTS_STYLES,
  ELEMENTS_META,
  IElement,
  backgroundPositionOptions,
  backgroundRepeatOptions,
  backgroundSizeOptions,
} from '@gohighlevel/ghl-proposals-common'
import { useElementOption } from '../../../composable/elementOptions.js'
import ColorPickerComponent from '../../common/ColorPickerComponent.vue'
import {
  UIButton,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UISelect,
  UIInputNumber,
  UIDivider,
  UITextSmRegular,
  UISpace,
} from '@gohighlevel/ghl-ui'
import { Image01Icon, Copy01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { useEmitter } from '@/composable/useEmitter'
import { useBuilderStore } from '@/store/builder'

const emitter = useEmitter()
const store = useBuilderStore()

emitter.on('media-library-selected', onSelectImage)
onBeforeUnmount(() => {
  emitter.off('media-library-selected', onSelectImage)
})

const props = defineProps({
  element: Object as PropType<IElement>,
})
const { updateStyles, updateOption } = useElementOption()

const bgColor = ref(props.element?.responsiveStyles?.large?.backgroundColor)

watch(props, _newValue => {
  bgColor.value = props.element?.responsiveStyles?.large?.backgroundColor
})

function onSelectImage(image: any) {
  if (!image) return
  updateOption(ELEMENTS_META.SRC, image.url)
}

function openMediaPicker() {
  emitter.emit('open-media-picker', true)
}

function onDuplicatePage() {
  store.duplicatePage(props.element?.id as string)
}
</script>

<template>
  <div class="hl-proposal-sidebar-content">
    <ColorPickerComponent
      v-model="bgColor"
      :label="$t('common.pageBackgroundColor')"
      @update:modelValue="
        updateStyles(
          ELEMENTS_STYLES.BACKGROUND_COLOR,
          bgColor,
          props.element?.id,
          props.element?.type
        )
      "
    />
    <UITextSmRegular>{{ $t('common.image') }}</UITextSmRegular>
    <UIDivider />
    <UIFormItem :label="$t('common.url')" :show-feedback="false" class="mb-4">
      <UIInputGroup>
        <UIInput
          placeholder="(eg) www.hostedsite.com/vid.."
          id="image-src"
          :model-value="element?.component.options.src"
          @update:model-value="val => updateOption(ELEMENTS_META.SRC, val)"
        />
        <UIButton id="image-picker" variant="outline" @click="openMediaPicker">
          <Image01Icon class="w-4 h-4" />
        </UIButton>
      </UIInputGroup>
    </UIFormItem>
    <UIFormItem
      :label="$t('common.imagePosition')"
      :showFeedback="false"
      class="mb-4"
    >
      <UISelect
        id="select-id-bg-pos"
        :value="element?.responsiveStyles.large.backgroundPosition"
        :options="backgroundPositionOptions"
        :placeholder="$t('common.selectPosition')"
        @update:value="
          val => updateStyles(ELEMENTS_STYLES.BACKGROUND_POSITION, val)
        "
      />
    </UIFormItem>
    <UIFormItem :label="$t('common.repeat')" :showFeedback="false" class="mb-4">
      <UISelect
        id="select-id-bg-repeat"
        :value="element?.responsiveStyles.large.backgroundRepeat"
        :options="backgroundRepeatOptions"
        :placeholder="$t('common.selectBackgroundRepeat')"
        @update:value="
          val => updateStyles(ELEMENTS_STYLES.BACKGROUND_REPEAT, val)
        "
      />
    </UIFormItem>
    <div class="flex gap-4">
      <div class="w-1/2">
        <UIFormItem
          :label="$t('common.size')"
          :showFeedback="false"
          class="mb-4"
        >
          <UISelect
            id="select-id-bg-size"
            :value="element?.responsiveStyles.large.backgroundSize"
            :options="backgroundSizeOptions"
            :placeholder="$t('common.selectSize')"
            @update:value="
              val => updateStyles(ELEMENTS_STYLES.BACKGROUND_SIZE, val)
            "
          />
        </UIFormItem>
      </div>
      <div class="w-1/2">
        <UIFormItem
          :label="$t('common.opacity')"
          :showFeedback="false"
          class="mb-4"
        >
          <UIInputNumber
            class="w-full"
            size="large"
            id="select-id-bg-repeat"
            :value="element?.responsiveStyles.large.opacity"
            :options="backgroundRepeatOptions"
            :placeholder="$t('common.selectBackgroundOpacity')"
            @update:value="
              val => updateStyles(ELEMENTS_STYLES.BACKGROUND_OPACITY, val)
            "
            :min="0"
            :max="100"
            :showButton="false"
          >
            <template #suffix>%</template>
          </UIInputNumber>
        </UIFormItem>
      </div>
    </div>
    <UIDivider />
    <UISpace class="px-2">
      <UIButton
        @click="onDuplicatePage"
        id="page-duplicate-btn"
        :text="true"
        class="text-gray-600"
        icon-placement="right"
      >
        <Copy01Icon class="w-4 h-4 mr-2" />
        {{ $t('common.duplicatePage') }}</UIButton
      >
    </UISpace>
  </div>
</template>

<style lang="scss">
.hl-form-content-hz {
  align-items: center !important;
}
</style>
