<script setup lang="ts">
import { UITextSmRegular, UIDatepicker, UISwitch } from '@gohighlevel/ghl-ui'
import {
  IntervalType,
  SCHEDULING_OPTIONS,
  EndType,
  ShortWeekDays,
  ShortMonths,
  ScheduleRRuleOptions,
} from '../../const'
import * as yup from 'yup'
import {
  dayjsTZ,
  getCurrentDate,
  formatDateSubtractDays,
  getFormatDate,
} from '../../utils'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { Field } from 'vee-validate'
import { computed, ref, onMounted, PropType } from 'vue'
import {
  prepareIntervalLabel,
  prepareScheduleDetails,
  createRRule,
  calculateAllOccurrences,
} from '../../utils/schedule'
import { Lightbulb01Icon } from '@gohighlevel/ghl-icons/24/outline'
import dayjs from 'dayjs'

const emits = defineEmits(['onScheduleUpdate'])
const props = defineProps({
  timezone: {
    type: String,
    required: true,
  },
  rrule: {
    type: Object as PropType<ScheduleRRuleOptions>,
    required: false,
  },
  isInternal: {
    type: Boolean,
    default: false,
  },
  enableByEndType: {
    type: Boolean,
    default: true,
  },
  isForTemplate: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const startDatePickerBorder = ref('1px solid rgb(248 113 113)')
const endDatePickerBorder = ref('1px solid rgb(248 113 113)')
const firstOccurrence = ref()
const intervalTypes = computed(() =>
  SCHEDULING_OPTIONS.INTERVAL_TYPE.filter(
    it => !it.internal || (it.internal && props.isInternal)
  )
)

const ALLOWED_DAYS = [...Array.from(Array(28), (_, i) => i + 1), -1]

const RRuleSchema = yup.object({
  intervalType: yup
    .mixed()
    .oneOf(Object.values(IntervalType), t('validations.enum.intervalType'))
    .required(t('validations.invalid.intervalType')),
  interval: yup
    .number()
    .typeError(t('validations.invalid.numberInterval'))
    .max(99)
    .positive(t('validations.invalid.positiveInterval'))
    .integer(t('validations.invalid.integerInterval'))
    .required(t('validations.invalid.interval')),

  dayOfMonth: yup
    .mixed()
    .when(['intervalType', 'numOfWeek'], (intervalType, numOfWeek) => {
      if (
        (intervalType === IntervalType.MONTHLY && numOfWeek === 0) ||
        intervalType === IntervalType.YEARLY
      ) {
        return yup
          .mixed()
          .oneOf(ALLOWED_DAYS, t('validations.enum.dayOfMonth'))
          .required(t('validations.invalid.dayOfMonth'))
      }
    }),
  dayOfWeek: yup
    .mixed()
    .when(['intervalType', 'numOfWeek'], (intervalType, numOfWeek) => {
      if (
        intervalType === IntervalType.WEEKLY ||
        (intervalType === IntervalType.MONTHLY && numOfWeek !== 0)
      ) {
        return yup
          .mixed()
          .oneOf(Object.values(ShortWeekDays), t('validations.enum.dayOfWeek'))
          .required(t('validations.invalid.dayOfWeek'))
      }
    }),
  numOfWeek: yup.mixed().when('intervalType', intervalType => {
    if (intervalType === IntervalType.MONTHLY)
      return yup
        .mixed()
        .oneOf([-1, 0, 1, 2, 3, 4], t('validations.enum.numOfWeek'))
        .required(t('validations.invalid.numOfWeek'))
  }),
  monthOfYear: yup.mixed().when('intervalType', intervalType => {
    if (intervalType === IntervalType.YEARLY)
      return yup
        .mixed()
        .oneOf(Object.values(ShortMonths), t('validations.enum.monthOfYear'))
        .required(t('validations.invalid.monthOfYear'))
  }),
  startDate: yup
    .mixed()
    .when('useStartAsPrimaryUserSigned', useStartAsPrimaryUserSigned => {
      if (!useStartAsPrimaryUserSigned)
        return yup
          .date()
          .typeError(t('validations.invalid.dateStartDate'))
          .min(
            getCurrentDate(),
            `${t('validations.min.startDate')} ${getCurrentDate()}`
          )
          .required(t('validations.invalid.startDate'))
    }),
  startTime: yup.mixed().when('intervalType', intervalType => {
    if ([IntervalType.MINUTELY, IntervalType.HOURLY].includes(intervalType))
      return yup.string().typeError(t('validations.invalid.stringStartTime'))
  }),
  endType: yup
    .mixed()
    .oneOf(Object.values(EndType), t('validations.enum.endType'))
    .required(t('validations.invalid.endType')),
  endDate: yup.mixed().when(['endType', 'startDate'], (endType, startDate) => {
    if (endType === EndType.BY) {
      return yup
        .date()
        .typeError(t('validations.invalid.dateEndDate'))
        .test('is-greater-than', t('validations.min.endDate'), function (val) {
          const { path, createError } = this
          const currentDate = getCurrentDate()
          const start =
            startDate && startDate !== 'Invalid Date'
              ? getFormatDate(startDate)
              : null

          const isPastCurrantDate = dayjs(val).isBefore(currentDate, 'day')
          const isPastStartDate = start
            ? dayjs(val).isBefore(start, 'day')
            : false

          if (isPastCurrantDate) {
            return createError({
              path,
              message: `${t('validations.min.endDate')} ${getCurrentDate()}`,
            })
          }

          if (isPastStartDate) {
            return createError({
              path,
              message: `${t('validations.min.endDate')} ${getFormatDate(
                startDate
              )}`,
            })
          }

          return true
        })
        .required(t('validations.invalid.endDate'))
    }
  }),
  endTime: yup
    .mixed()
    .when(['endDate', 'intervalType'], (endDate, intervalType) => {
      if (
        [IntervalType.MINUTELY, IntervalType.HOURLY].includes(intervalType) &&
        endDate
      )
        return yup.string().required(t('validations.invalid.endTime'))
    }),
  count: yup.mixed().when('endType', endType => {
    if (endType === EndType.AFTER)
      return yup
        .number()
        .typeError(t('validations.invalid.numberCount'))
        .max(99)
        .nullable()
        .positive(t('validations.invalid.positiveCount'))
        .integer()
        .required(t('validations.invalid.count'))
  }),
  daysBefore: yup
    .mixed()
    .when(
      ['intervalType', 'useStartAsPrimaryUserSigned'],
      (intervalType, useStartAsPrimaryUserSigned) => {
        if (
          !useStartAsPrimaryUserSigned &&
          [
            IntervalType.WEEKLY,
            IntervalType.MONTHLY,
            IntervalType.YEARLY,
          ].includes(intervalType)
        ) {
          const max = intervalType === IntervalType.WEEKLY ? 7 : 28
          // if (firstOccurrence.value && startDate) {
          //   const start =
          //     startDate && startDate != 'Invalid Date'
          //       ? getFormatDate(startDate)
          //       : getCurrentDate()
          //   const occurrence = formatDateSubtractDays(
          //     firstOccurrence.value,
          //     values.daysBefore || 0,
          //     'YYYY-MM-DD',
          //     props.timezone
          //   )
          //   if (dayjs(occurrence).isBefore(start, 'day')) {
          //     const diff = dayjs(firstOccurrence.value).diff(
          //       dayjs(start),
          //       'days'
          //     )
          //     max = diff < max ? diff : max
          //   }
          // }
          return yup
            .number()
            .typeError(t('validations.invalid.numberDaysBefore'))
            .min(0)
            .max(max, `${t('validations.max.daysBefore')} ${max}`)
            .nullable()
            .integer()
            .required(t('validations.invalid.daysBefore'))
        }
      }
    ),
  useStartAsPrimaryUserSigned: yup
    .boolean()
    .typeError(t('validations.invalid.booleanUseStartAsPrimaryUserSigned'))
    .required(t('validations.invalid.useStartAsPrimaryUserSigned')),
})

const { errors, validate, values, setFieldValue } = useForm({
  validationSchema: RRuleSchema,
  initialValues: {
    intervalType: props.rrule?.intervalType || '',
    interval: props.rrule?.interval || 0,
    dayOfMonth: props.rrule?.dayOfMonth || '',
    dayOfWeek: props.rrule?.dayOfWeek || '',
    numOfWeek: props.rrule?.numOfWeek || 0,
    monthOfYear: props.rrule?.monthOfYear,
    startTime: props.rrule?.startTime || '',
    endType: props.rrule?.endType || '',
    endTime: props.rrule?.endTime || '',
    count: props.rrule?.count || '',
    daysBefore: props.rrule?.daysBefore || 0,
    startDate: props.rrule?.startDate || '',
    endDate: props.rrule?.endDate || '',
    useStartAsPrimaryUserSigned:
      props.rrule?.useStartAsPrimaryUserSigned || true,
  },
})

const datePickerBorderColorChange = async () => {
  if (errors.value.startDate) {
    startDatePickerBorder.value = '1px solid rgb(248 113 113)'
  } else {
    startDatePickerBorder.value = '1px solid rgb(224, 224, 230)'
  }

  if (errors.value.endDate) {
    endDatePickerBorder.value = '1px solid rgb(248 113 113)'
  } else {
    endDatePickerBorder.value = '1px solid rgb(224, 224, 230)'
  }
}

const onChangeValues = async () => {
  const validation = await validate()
  findFirstOccurrence()
  const data = {
    ...values,
    endType: values.endType || EndType.NEVER,
    daysBefore: values.daysBefore || 0,
  }
  const formattedSchedule = prepareScheduleDetails(data)
  datePickerBorderColorChange()
  emits('onScheduleUpdate', formattedSchedule, validation)
}

function allowOnlyInteger($event: any) {
  const keyCode = $event.keyCode ? $event.keyCode : $event.which
  const isNumber = keyCode >= 48 && keyCode <= 57
  const newValue = `${$event.target.value}${$event.key}`
  if (!isNumber || Number(newValue) > 99) $event.preventDefault()
}

function startDateDisabled(date: Date) {
  const today = dayjs().format('YYYY-MM-DD')
  const isPastDate = dayjs(date).isBefore(today, 'day')
  return isPastDate
}

const startDate = ref()
const endDate = ref()
const useStartDateAsPrimaryUserSignDate = ref(true)

function endDateDisabled(date: Date) {
  const currentDate = dayjs().format('YYYY-MM-DD')
  const isPastDate = dayjs(date).isBefore(currentDate, 'day')
  const isBeforeStartDate = values.startDate
    ? dayjs(date).isBefore(dayjs(values.startDate).format('YYYY-MM-DD'), 'day')
    : false
  return isPastDate || isBeforeStartDate
}

const onChangeStartDate = (value: string) => {
  setFieldValue('startDate', dayjs(value).format('YYYY-MM-DD'))
  onChangeValues()
}

const onChangeEndDate = (value: string) => {
  setFieldValue('endDate', dayjs(value).format('YYYY-MM-DD'))
  onChangeValues()
}

const onChangeUseStartDateAsPrimaryUserSignedDate = (value: boolean) => {
  setFieldValue('useStartAsPrimaryUserSigned', value)
  if (value) {
    setFieldValue('startDate', '')
    startDate.value = undefined
    setFieldValue('startTime', '')
  }
  onChangeValues()
}

function findFirstOccurrence() {
  const data = {
    ...values,
    endType: values.endType || EndType.NEVER,
    daysBefore: values.daysBefore || 0,
  }
  if (
    values.startDate &&
    values.intervalType &&
    !values.useStartAsPrimaryUserSigned
  ) {
    const formattedSchedule = prepareScheduleDetails(data)
    const rrule = createRRule(formattedSchedule?.rrule, props.timezone)
    const allOccurrences = calculateAllOccurrences(rrule)
    firstOccurrence.value = allOccurrences[0]
  }
}

onMounted(() => {
  if (props.rrule?.startDate) {
    startDate.value = dayjs(props.rrule?.startDate, 'YYYY-MM-DD').valueOf()
  }
  if (props.rrule?.endDate) {
    endDate.value = dayjs(props.rrule?.endDate, 'YYYY-MM-DD').valueOf()
  }

  if (
    props.rrule?.useStartAsPrimaryUserSigned === false ||
    props.rrule?.useStartAsPrimaryUserSigned === true
  ) {
    useStartDateAsPrimaryUserSignDate.value =
      props.rrule.useStartAsPrimaryUserSigned
    setFieldValue(
      'useStartAsPrimaryUserSigned',
      props.rrule.useStartAsPrimaryUserSigned
    )
  }
  onChangeValues()
})
</script>

<template>
  <div class="mt-3 mb-6">
    <div class="mt-2">
      <UITextSmRegular class="text-gray-700 font-medium my-4">{{
        $t('common.frequencySettings')
      }}</UITextSmRegular>
    </div>

    <div class="bg-gray-100 rounded-md py-4">
      <div class="px-4 py-8 mb-8">
        <label class="text-sm font-normal text-gray-500 my-2"
          >{{ $t('common.howOften') }}?</label
        >
        <div class="mb-2">
          <Field
            id="intervalType"
            as="select"
            name="intervalType"
            class="w-full border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm p-2 border mt-2"
            :onchange="onChangeValues"
            :class="{
              'border-red-400': !!errors.intervalType,
            }"
          >
            <option value="">Select</option>
            <option
              v-for="{ value, label } in intervalTypes"
              :value="value"
              :key="value"
            >
              {{ label }}
            </option>
          </Field>
        </div>

        <div class="mb-2">
          <label
            class="text-sm font-normal text-black px-2 mb-2"
            v-if="
            values &&
            values?.intervalType &&
            ![
              IntervalType.MINUTELY,
              IntervalType.HOURLY,
              IntervalType.DAILY,
            ].includes(values.intervalType as IntervalType)
          "
            >on</label
          >
        </div>

        <div class="mb-2">
          <template v-if="values.intervalType === IntervalType.MONTHLY">
            <Field
              id="numOfWeek"
              as="select"
              name="numOfWeek"
              class="border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border p-2"
              :onchange="onChangeValues"
              :class="{
                'border-red-400': !!errors.numOfWeek,
              }"
            >
              <option value="">{{ $t('common.select') }}</option>
              <option :value="0">{{ $t('common.date') }}</option>
              <option :value="1">{{ $t('schedulingOptions.first') }}</option>
              <option :value="2">{{ $t('schedulingOptions.second') }}</option>
              <option :value="3">{{ $t('schedulingOptions.third') }}</option>
              <option :value="4">{{ $t('schedulingOptions.fourth') }}</option>
              <option :value="-1">{{ $t('schedulingOptions.last') }}</option>
            </Field>
          </template>

          <template
            v-if="
              values.intervalType === IntervalType.WEEKLY ||
              (values.intervalType === IntervalType.MONTHLY &&
                (values.numOfWeek > 0 || values.numOfWeek < 0))
            "
          >
            <Field
              :onchange="onChangeValues"
              id="dayOfWeek"
              as="select"
              name="dayOfWeek"
              class="border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border p-2"
              :class="{
                'border-red-400': !!errors.dayOfWeek,
                'ml-2': values.intervalType === IntervalType.MONTHLY,
                'w-full': values.intervalType === IntervalType.WEEKLY,
              }"
            >
              <option value="">{{ $t('common.select') }}</option>
              <option
                v-for="{ value, label } in SCHEDULING_OPTIONS.WEEKDAYS"
                :value="value"
                :key="value"
              >
                {{ label }}
              </option>
            </Field>
          </template>
          <Field v-else type="hidden" name="dayOfWeek"></Field>

          <template
            v-if="
              values.intervalType === IntervalType.YEARLY ||
              (values.intervalType === IntervalType.MONTHLY &&
                values.numOfWeek === 0)
            "
          >
            <Field
              id="dayOfMonth"
              as="select"
              name="dayOfMonth"
              class="border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border p-2"
              :onchange="onChangeValues"
              :class="{
                'border-red-400': !!errors.dayOfMonth,
                'ml-2': values.intervalType === IntervalType.MONTHLY,
              }"
            >
              <option value="">{{ $t('common.select') }}</option>
              <option
                v-for="{ value, label } in SCHEDULING_OPTIONS.DAYS"
                :value="value"
                :key="value"
              >
                {{ label }}
              </option>
            </Field>
          </template>

          <template v-if="values.intervalType === IntervalType.YEARLY">
            <Field
              id="monthOfYear"
              as="select"
              name="monthOfYear"
              class="border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border p-2 ml-2"
              :onchange="onChangeValues"
              :class="{
                'border-red-400': !!errors.monthOfYear,
              }"
            >
              <option value="">{{ $t('common.select') }}</option>
              <option
                v-for="{ value, label } in SCHEDULING_OPTIONS.MONTHS"
                :value="value"
                :key="value"
              >
                {{ label }}
              </option>
            </Field>
          </template>
        </div>

        <div class="mb-2">
          <template v-if="values.intervalType">
            <div class="mt-2">
              <label class="text-sm font-normal text-black px-2"
                >{{ $t('common.of') }} {{ $t('common.every') }}</label
              >
            </div>
            <Field
              id="interval"
              as="input"
              name="interval"
              class="w-20 border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border p-2 mt-2"
              type="number"
              :onchange="onChangeValues"
              @keypress="allowOnlyInteger"
              :class="{
                'border-red-400': !!errors.interval,
              }"
            />
            <label class="text-sm font-normal text-black ml-2">{{
              prepareIntervalLabel(
                values.intervalType as IntervalType,
                values.interval > 1
              )
            }}</label>
          </template>
          <Field v-else type="hidden" name="interval"></Field>
        </div>
      </div>

      <div class="px-4 py-8 mb-8">
        <div class="flex items-center my-4">
          <label class="text-sm font-normal text-black px-1">{{
            $t('schedulingOptions.useStartDateAsPrimarySignedDate')
          }}</label>
          <UISwitch
            :disabled="isForTemplate"
            id="schedule-use-start-date-as-primary-signed-date"
            size="small"
            v-model:value="useStartDateAsPrimaryUserSignDate"
            @update:value="onChangeUseStartDateAsPrimaryUserSignedDate"
          />
        </div>
      </div>
      <div class="px-4 py-8 mb-8" v-if="!values.useStartAsPrimaryUserSigned">
        <div class="mt-2">
          <label class="text-sm font-normal text-gray-500">{{
            $t('common.startDate')
          }}</label>
          <div>
            <UIDatepicker
              :key="`frequency-date-picker-start-date-picker`"
              @update:value="onChangeStartDate"
              name="startDate"
              placeholder="Start Date"
              id="frequency-date-picker-start-date"
              ref="startDatePickerRef"
              :format="'MMMM d, yyyy'"
              v-model:value="startDate"
              class="fq-start-date mt-2"
              :isDateDisabled="startDateDisabled"
              :inputAttributes="{ readonly: true, size: 16 }"
              type="date"
            >
            </UIDatepicker>
            <Field type="hidden" name="startDate"></Field>

            <div
              v-if="
              [IntervalType.MINUTELY, IntervalType.HOURLY].includes(
                values.intervalType as IntervalType
              )
            "
            >
              <div class="mb-1 mt-2">
                <label class="text-sm font-normal text-gray-500">{{
                  $t('common.startTime')
                }}</label>
              </div>
              <Field
                :onchange="onChangeValues"
                id="startTime"
                type="time"
                name="startTime"
                class="w-full border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border py-2 mt-2"
                :class="{
                  'border-red-400': !!errors.startTime,
                }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 py-8 mb-8">
        <div class="mb-1 mt-2">
          <label class="text-sm font-normal text-gray-500">{{
            $t('common.end')
          }}</label>
        </div>

        <Field
          id="endType"
          as="select"
          name="endType"
          :onchange="onChangeValues"
          class="w-full border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm p-2 border mt-2"
          :class="{
            'border-red-400': !!errors.endType,
          }"
        >
          <option value="">{{ $t('common.select') }}</option>
          <option :value="EndType.NEVER">
            {{ $t('schedulingOptions.never') }}
          </option>
          <option v-if="enableByEndType" :value="EndType.BY">
            {{ $t('schedulingOptions.by') }}
          </option>
          <option :value="EndType.AFTER">
            {{ $t('schedulingOptions.after') }}
          </option>
        </Field>
      </div>

      <div class="px-4 py-8 mb-8">
        <template v-if="values.endType === EndType.BY">
          <div class="mb-1 mt-2">
            <label class="text-sm font-normal text-gray-500">{{
              $t('common.endDate')
            }}</label>
          </div>
          <div>
            <UIDatepicker
              :key="`frequency-date-picker-end-date-picker`"
              @update:value="onChangeEndDate"
              placeholder="End Date"
              class="fq-end-date mt-2"
              id="by-date-picker"
              v-model:value="endDate"
              ref="endDatePickerRef"
              :format="'MMMM d, yyyy'"
              :isDateDisabled="endDateDisabled"
              :inputAttributes="{ readonly: true, size: 16 }"
            >
            </UIDatepicker>
            <Field type="hidden" name="endDate"></Field>
          </div>

          <div
            v-if="
            [IntervalType.MINUTELY, IntervalType.HOURLY].includes(
              values.intervalType as IntervalType
            )
          "
          >
            <div class="mb-1 mt-2">
              <label class="text-sm font-normal text-gray-500">{{
                $t('common.endTime')
              }}</label>
            </div>

            <Field
              :onchange="onChangeValues"
              id="endTime"
              type="time"
              name="endTime"
              class="w-full border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm border py-2 mt-2"
              :class="{
                'border-red-400': !!errors.endTime,
              }"
            />
          </div>
        </template>
        <template v-if="values.endType === EndType.AFTER">
          <Field
            :onchange="onChangeValues"
            id="count"
            as="input"
            name="count"
            class="w-20 border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm p-2 border mt-2"
            :class="{
              'border-red-400': !!errors.count,
            }"
            type="number"
            @keypress="allowOnlyInteger"
          />
          <label class="text-sm font-normal text-black ml-2">{{
            $t('schedulingOptions.occurrences')
          }}</label>
        </template>
      </div>

      <div class="px-4 py-8 mb-8">
        <div
          v-if="
          !values.useStartAsPrimaryUserSigned &&
          (!values.intervalType ||
          ![
            IntervalType.MINUTELY,
            IntervalType.HOURLY,
            IntervalType.DAILY,
          ].includes(values.intervalType as IntervalType))
        "
          className="flex items-center mt-6"
        >
          <label class="text-sm font-normal text-gray-500">Send invoice</label>
          <Field
            id="daysBefore"
            as="input"
            name="daysBefore"
            :onchange="onChangeValues"
            class="w-10 border-gray-300 focus:outline-none focus:ring-curious-blue-500 focus:border-curious-blue-500 rounded-md text-sm p-2 border mx-2"
            :class="{
              'border-red-400': !!errors.daysBefore,
            }"
            type="number"
            @keypress="allowOnlyInteger"
          />
          <label class="text-sm font-normal text-gray-500">{{
            $t('schedulingOptions.daysInAdvance')
          }}</label>
        </div>
      </div>

      <div class="px-4 py-8 mb-8">
        <div
          v-if="firstOccurrence && !values?.useStartAsPrimaryUserSigned"
          class="mt-8 text-sm text-gray-500 flex items-center"
        >
          <Lightbulb01Icon class="h-5 w-5 mr-1 text-gray-600" />
          <span
            >{{ $t('common.firstInvoice') }} {{ $t('common.willBe') }}
            {{ $t('common.dueOn') }}
            {{
              dayjsTZ(firstOccurrence, '', props.timezone).format(
                'MMMM D, YYYY'
              )
            }}
            {{ $t('common.and') }} {{ $t('common.willBe') }}
            {{ $t('common.sentOn') }}
            {{
              formatDateSubtractDays(
                firstOccurrence,
                values.daysBefore || 0,
                'MMMM D, YYYY',
                props.timezone
              )
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.n-date-picker.fq-start-date {
  .n-input {
    --n-border: v-bind('startDatePickerBorder') !important;
  }
}
.n-date-picker.fq-end-date {
  .n-input {
    --n-border: v-bind('endDatePickerBorder') !important;
  }
}
</style>
