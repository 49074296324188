<template>
  <div class="flex items-center">
    <UIFormItem :label="label" class="flex-width">
      <UIInput id="colorPicker" type="text" v-model="colorSelected" />
    </UIFormItem>
    <div class="ml-4">
      <UIColorPicker
        v-model="colorSelected"
        :selectedColor="modelValue"
      ></UIColorPicker>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import UIColorPicker from './UIColorPicker.vue'
import { UIInput, UIFormItem } from '@gohighlevel/ghl-ui'

export default defineComponent({
  components: { UIColorPicker, UIInput, UIFormItem },
  props: {
    label: {
      type: String,
      default: 'Select Color',
    },
    modelValue: String,
    selectedColor: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const colorPickerContainer = ref()
    const btnColorPicker = ref()
    const isOpen = ref(false)
    const colorSelected = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        return emit('update:modelValue', val)
      },
    })

    return {
      colorPickerContainer,
      btnColorPicker,
      isOpen,
      colorSelected,
    }
  },
})
</script>

<style>
[x-cloak] {
  display: none;
}
.color-picker-container {
  width: 300px;
  height: 400px;
  top: -70px;
  left: 50px;
}
.hu-color-picker.light {
  width: 280px !important;
}
.hu-color-picker {
  box-shadow: unset !important;
}
.hu-color-picker.light {
  background: unset !important;
}
/* .saturation canvas {
    width: 210px !important;
    height: 152px !important;
  } */
.color-show canvas {
  width: 260px !important;
}
.hu-color-picker .color-set {
  justify-content: center;
  padding: 10px 0px;
}
.flex-width {
  width: 80%;
}
</style>
