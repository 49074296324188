import config from '@/config'
import { requests } from '@/service'
import { ITaxParams } from '@gohighlevel/ghl-proposals-common'

export const TaxService = {
  fetchTaxes: (params: ITaxParams) => {
    return requests(config.TAX_SERVICE_URL).get('/', {
      params: params,
    })
  },
}
