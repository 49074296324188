<script lang="ts" setup>
import { UITextMdMedium } from '@gohighlevel/ghl-ui'
import { computed, PropType } from 'vue'
import { IElement } from '@gohighlevel/ghl-proposals-common'

const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
  },
})

const videoElementDimensions = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    height: styles?.height ? styles?.height + 'px' : '',
    width: styles?.width ? styles?.width + 'px' : '',
  }
})

// const alignment = computed(() => props.element?.responsiveStyles.large?.align)
const customStyleForImageParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    backgroundColor: styles?.backgroundColor,
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
    height: videoElementDimensions.value.height,
    maxWidth: videoElementDimensions.value.width,
    overflow: 'auto',
  }
})
</script>

<template>
  <div class="el-wrapper video-el">
    <div
      v-if="element?.component?.options?.html"
      :style="customStyleForImageParent"
    >
      <div class="iframe-container">
        <div
          class="c-video-iframe"
          :style="videoElementDimensions"
          v-html="element?.component?.options?.html"
        ></div>
      </div>
    </div>
    <div
      v-else
      class="h-[100px] bg-gray-100 text-center text-lg relative overflow-auto m-[2px]"
    >
      <UITextMdMedium
        class="break-words -translate-y-2/4 absolute left-0 top-[50%] w-full"
        >{{ $t('common.selectVideo') }}</UITextMdMedium
      >
    </div>
  </div>
</template>

<style lang="scss">
.video-el {
  .c-video-iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .iframe-container {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
  }
}
</style>
