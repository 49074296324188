<script setup lang="ts">
import { useAppStore } from '@/store/app'
import { ChevronLeftIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UISpace,
  UITextSmMedium,
  UIToolbar,
  UIToolbarGroup,
  UIInput,
} from '@gohighlevel/ghl-ui'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DocumentActions from './ValidateDocument/DocumentActions.vue'
import config from '@/config'
import { DocumentStatus } from '@gohighlevel/ghl-proposals-common'
import { useBuilderStore } from '@/store/builder'
import DocumentStateActions from './ValidateDocument/DocumentStateActions.vue'
import { useDocument } from '@/composition'
import { usePostmate } from '@/composition/usePostmate'

const route = useRoute()
const { redirectOnParentRoute } = usePostmate()
const { saveDocument } = useDocument()
function goBack() {
  redirectOnParentRoute({
    name: 'proposals-estimates',
    params: { locationId: route.params.locationId },
  })
}

const appStore = useAppStore()
const builderStore = useBuilderStore()
const documentTitle = ref('')

function listenForTitleChanges(val: string) {
  appStore.updateDocumentName(val)
}

const whiteLabelBaseUrl = computed(() => {
  return appStore?.whiteLabelViewerBaseUrl
})

onMounted(() => {
  documentTitle.value = appStore.document?.name
})

const getBaseURL = () => {
  if (process.env.NODE_ENV === 'development') {
    return config.DOCUMENT_VIEWER_URL
  }
  return whiteLabelBaseUrl.value || config.DOCUMENT_VIEWER_URL
}
const openPreviewLink = async (event: Event) => {
  event.preventDefault()
  if (appStore.document.status === DocumentStatus.DRAFT) {
    await saveDocument()
  }
  const baseUrl = getBaseURL()
  const newTab = window.open(
    `${baseUrl}/documents/preview/${route.params.documentId}?locale=${appStore.locale}`,
    '_blank'
  )
  newTab?.focus()
}
</script>

<template>
  <UIToolbar theme="light">
    <div class="w-1/12 flex flex-1 justify-start">
      <div class="inline-flex">
        <UIToolbarGroup border-right class="flex items-center">
          <UIButton id="btn-1" text @click="goBack">
            <ChevronLeftIcon class="w-5 h-5 text-gray-700" />
            <UITextSmMedium class="text-gray-700 font-medium">
              {{ $t('common.back') }}
            </UITextSmMedium>
          </UIButton>
        </UIToolbarGroup>
      </div>
    </div>
    <div class="w-6/12 md:w-3/12 flex flex-1 justify-center">
      <UIToolbarGroup class="inline-flex justify-center items-center w-full">
        <UIInput
          id="document-title"
          :class="`w-full text-lg text-gray-700 max-w-md border px-3 rounded py-1 hover:bg-gray-100 cursor-pointer overflow-x-auto text-center whitespace-nowrap shadow-none ${
            !builderStore.isDocumentEditable
              ? 'pointer-events-none opacity-50'
              : ''
          }`"
          v-model="documentTitle"
          @update:model-value="val => listenForTitleChanges(val)"
        />
      </UIToolbarGroup>
    </div>
    <div class="w-3/12 md:w-4/12 flex flex-1 justify-end">
      <UIToolbarGroup class="inline-flex justify-end items-center">
        <UISpace class="flex items-center space-x-3">
          <DocumentStateActions />
          <a
            class="no-underline text-primary-700 font-medium"
            href="javascript:void(0)"
            @click="openPreviewLink"
          >
            {{
              appStore.document.status !== DocumentStatus.DRAFT
                ? $t('common.view')
                : $t('common.preview')
            }}
          </a>
          <DocumentActions />
        </UISpace>
      </UIToolbarGroup>
    </div>
  </UIToolbar>
</template>

<style lang="scss">
#document-title {
  border: 1px;
  input {
    height: 28px;
    line-height: 28px;
  }
}
</style>
