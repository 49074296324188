import {
  FetchBusinessProductByLocationIdResponse,
  ICreateProductPayload,
  ILocationId,
} from '@gohighlevel/ghl-proposals-common'

import config from '@/config'
import { requests } from '@/service'

export const ProductService = {
  fetchProductListByLocationId: (params: ILocationId) => {
    return requests(
      config.PRODUCT_SERVICE_URL
    ).get<FetchBusinessProductByLocationIdResponse>('/', {
      params: {
        ...params,
        limit: 1000,
      },
    })
  },

  createNewProduct: (params: ICreateProductPayload) => {
    return requests(config.PRODUCT_SERVICE_URL).post('/', params)
  },
}
