export function convertObjToSnakeCase(
  obj: any,
  ignorePrefixes: string[] = []
): any {
  if (obj && typeof obj === 'object') {
    const snakeCaseObj: any = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        let shouldConvert = true
        for (const prefix of ignorePrefixes) {
          if (key.startsWith(prefix)) {
            shouldConvert = false
            break
          }
        }
        const snakeCaseKey = shouldConvert
          ? key
              .split('.')
              .map(part => part.replace(/([A-Z])/g, '_$1').toLowerCase())
              .join('.')
          : key
        snakeCaseObj[snakeCaseKey] = convertObjToSnakeCase(
          obj[key],
          ignorePrefixes
        )
      }
    }
    return snakeCaseObj
  } else {
    return obj
  }
}
