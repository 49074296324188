<script setup lang="ts">
import { PropType } from 'vue'
import {
  IElement,
  ELEMENTS_LOOKUP,
  FloatingCheckBoxElement,
  FloatingDateFieldElement,
  FloatingInitialsElement,
  FloatingTextFieldElement,
  SignatureElement,
  WithCommonProvider,
  GroupFieldsOptions,
  GroupFields,
} from '@gohighlevel/ghl-proposals-common'
import { useGroups } from '@/composition'
import { useBuilderStore } from '@/store/builder'
import { useProductListStore } from '@/store/productListStore'
const props = defineProps({
  pageIndex: { type: Number, required: true },
  parentId: { type: String, required: true },
  snapContainerClass: { type: String, required: true },
  boundContainerClass: { type: String, required: true },
  childComponentsWithDraggable: {
    type: Array as PropType<Array<IElement>>,
    required: true,
  },
})
const store = useBuilderStore()
const productListStore = useProductListStore()
const onClickGroup = (group: GroupFields<GroupFieldsOptions>) => {
  store.setActiveGroup(group?.id)
}
const SignatureComponent = WithCommonProvider(SignatureElement)
const TextFieldComponent = WithCommonProvider(FloatingTextFieldElement)
const DateFieldComponent = WithCommonProvider(FloatingDateFieldElement)
const InitialsFieldComponent = WithCommonProvider(FloatingInitialsElement)
const CheckBoxFieldComponent = WithCommonProvider(FloatingCheckBoxElement)
const { onCloneAndAddToGroup, setGroupTargetsOnSelection } = useGroups()

const movableComponentActive = (
  event: MouseEvent,
  element: IElement | null
) => {
  event && event.stopImmediatePropagation && event?.stopImmediatePropagation()
  // if the click is coming from text editor-toolbar or the embed model, then we should not set/unset the element.
  const editorToolBar = document.querySelector('#editor-toolbar')
  const embedModel = document.querySelector('.n-modal-body-wrapper')
  if (editorToolBar && editorToolBar?.contains(event?.target)) {
    return
  }
  if (embedModel && embedModel?.contains(event?.target)) {
    return
  }
  const group = element?.id ? store.getElementGroup(element?.id) : null
  if (element && group) {
    store.updateActiveElement(null)
  } else {
    store.updateActiveElement(element)
    setGroupTargetsOnSelection([])
  }
  productListStore.setActiveRow(null, null)
}
</script>

<template>
  <div
    v-for="element in childComponentsWithDraggable"
    :key="`${props.parentId}-${element?.id}`"
  >
    <SignatureComponent
      :key="`signature-${props.parentId}-${element?.id}`"
      v-if="
        element &&
        element.component.isDraggable &&
        element.type === ELEMENTS_LOOKUP.SIGNATURE
      "
      :snapContainer="`.${snapContainerClass}`"
      :boundContainer="`.${boundContainerClass}`"
      @on-active="(e: MouseEvent, isActive: boolean) => movableComponentActive(e, isActive ? element : null)"
      :element="element"
      :isActive="store.activeElementId === element.id"
    />
    <TextFieldComponent
      :key="`textfield-${props.parentId}-${element?.id}`"
      v-if="
        element &&
        element.component.isDraggable &&
        element.type === ELEMENTS_LOOKUP.TEXT_FIELD
      "
      :snapContainer="`.${snapContainerClass}`"
      :boundContainer="`.${boundContainerClass}`"
      @on-active="(e: MouseEvent, isActive: boolean) => movableComponentActive(e, isActive ? element : null)"
      :element="element"
      :isActive="store.activeElementId === element.id"
    />
    <DateFieldComponent
      v-if="
        element &&
        element.component.isDraggable &&
        element.type === ELEMENTS_LOOKUP.DATE_FIELD
      "
      :snapContainer="`.${snapContainerClass}`"
      :boundContainer="`.${boundContainerClass}`"
      @on-active="(e: MouseEvent, isActive: boolean) => movableComponentActive(e, isActive ? element : null)"
      :element="element"
      :isActive="store.activeElementId === element.id"
    />
    <InitialsFieldComponent
      v-if="
        element &&
        element.component.isDraggable &&
        element.type === ELEMENTS_LOOKUP.INITIALS_FIELD
      "
      :snapContainer="`.${snapContainerClass}`"
      :boundContainer="`.${boundContainerClass}`"
      @on-active="(e: MouseEvent, isActive: boolean) => movableComponentActive(e, isActive ? element : null)"
      :element="element"
      :isActive="store.activeElementId === element.id"
    />
    <CheckBoxFieldComponent
      :pageIndex="props.pageIndex?.toString()"
      :pageId="props.parentId"
      :id="element?.id"
      v-if="
        element &&
        element.component.isDraggable &&
        element.type === ELEMENTS_LOOKUP.CHECKBOX
      "
      :snapContainer="`.${snapContainerClass}`"
      :boundContainer="`.${boundContainerClass}`"
      :element="element"
      @on-active="(e: MouseEvent, isActive: boolean) => movableComponentActive(e, isActive ? element : null)"
      @on-clone-add-to-group="onCloneAndAddToGroup"
      :isActive="store.activeElementId === element.id"
      :data-page-id="props?.parentId"
      :data-page-index="props?.pageIndex"
      @on-click-group="onClickGroup"
    />
  </div>
</template>
