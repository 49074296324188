<script setup lang="ts">
import { Copy01Icon, Trash01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UIInput, UIInputGroup, UITooltip } from '@gohighlevel/ghl-ui'
import { useClipboard } from '../../composition'
import { convertCustomValueToFormatForValidDate } from '../../utils'
import { PropType, ref } from 'vue'

type DocumentCustomVariableInputEvents = {
  updated: [fieldKey: string, value: string]
  deleted: [fieldKey: string]
  copy: [copyText: string]
}

const props = defineProps({
  variable: {
    type: Object as PropType<{
      fieldKey: string
      type?: string
      value: string
    }>,
    required: true,
  },
  isNotEditable: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<DocumentCustomVariableInputEvents>()
const { clipboardText, onCopy } = useClipboard()
const modelValue = ref(props.variable.value)
const isEditing = ref(false)
const onUpdate = (val: string) => {
  modelValue.value = val
}
const onFocus = () => {
  isEditing.value = true
}
const onBlur = () => {
  isEditing.value = false
  if (props.variable.type === 'linked') {
    return
  }
  emits('updated', props.variable.fieldKey, modelValue.value)
}
</script>

<template>
  <UIInputGroup>
    <div class="custom-variable--input-wrapper relative">
      <UIInput
        type="text"
        :id="props.variable.fieldKey"
        :modelValue="convertCustomValueToFormatForValidDate(modelValue)"
        @update:modelValue="onUpdate"
        :readonly="props.readonly"
        @focus="onFocus"
        @blur="onBlur"
        :disabled="props.disabled"
      />
    </div>
    <UITooltip>
      <template #trigger>
        <UIButton
          :id="`btn_${variable.fieldKey}`"
          @click="
            () => {
              onCopy(variable.fieldKey)
              emits('copy', variable.fieldKey)
            }
          "
        >
          <Copy01Icon class="w-4 h-4" />
        </UIButton>
      </template>
      {{ clipboardText }}
    </UITooltip>
    <UIButton
      :id="`btn_custom_${variable.fieldKey}`"
      v-if="variable.type === 'custom'"
      @click="emits('deleted', variable.fieldKey)"
    >
      <Trash01Icon class="w-4 h-4" />
    </UIButton>
  </UIInputGroup>
</template>
