<script setup lang="ts">
import { ref, withDefaults, defineProps, onMounted, watch, computed } from 'vue'
import Selecto from 'vue3-selecto'
import {
  GroupFields,
  GroupFieldsOptions,
  SelectoComponentEvents,
} from '../../types'
import Moveable, { BoundType } from 'vue3-moveable'
import { useMovable } from '../../composition/useMovable'
import { CommonGroupTooltip } from '../Tooltip'
import {
  fetchExistingGroup,
  getGroupInfoWithElements,
  getRequiredValidation,
} from '../../utils/group'
import { PlusSquareIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UITooltip } from '@gohighlevel/ghl-ui'

const emit = defineEmits([
  SelectoComponentEvents.ON_SELECTO_SELECT_END,
  SelectoComponentEvents.ON_SELECTO_GROUP,
  SelectoComponentEvents.ON_SELECTO_UNGROUP,
  SelectoComponentEvents.ON_SELECTO_CLONE,
  SelectoComponentEvents.ON_SELECTO_DELETE,
  SelectoComponentEvents.ON_SELECTO_CLICK_GROUP,
  SelectoComponentEvents.ON_CLONE_AND_ADD_TO_GROUP,
])

export interface ElementRef<T> {
  current?: T | undefined | null
  value?: T | undefined | null
}
const moveableSelectionRef = ref(null)
const groupTooltipAbleRef = ref(null)
const selectoRef = ref(null)
const targets = ref([])
const displayToolTip = ref(false)
const displayAddToGroupToolTip = ref(false)
const cloneAndAddElementToGroup = ref(null)
const groupRequiredAbleRef = ref(null)
const activeGroupInfo = ref()
const isDragging = ref(false)

const props = withDefaults(
  defineProps<{
    pageWrapperRef?: any
    scrollOptions?: any
    snapThreshold?: number
    storeMap?: any
    container?: HTMLElement | null
    rootContainer?: HTMLElement | null
    dragContainer?: Element | Window | Element[] | string | null | undefined
    className?: string
    selectableTargets?: Array<
      Element | string | (() => Element | Element[]) | ElementRef<Element>
    >
    selectFromInside?: boolean
    selectByClick?: boolean
    preventDragFromInside?: boolean
    clickBySelectEnd?: boolean
    continueSelect?: boolean
    continueSelectWithoutDeselect?: boolean
    toggleContinueSelect?: string[][] | string[] | string | null
    toggleContinueSelectWithoutDeselect?: string[][] | string[] | string | null
    keyContainer?: Document | HTMLElement | Window | null
    hitRate?: number | string | ((element: Element) => number | string)
    ratio?: number
    dragStart?: any
    select?: any
    selectEnd?: any
    colorOverride?: string
    inactiveColorOverride?: string
    compositionMap: {
      type: object
      required: true
    }
    activeGroup: any
  }>(),
  {
    snapThreshold: 5,
    colorOverride: '#4af',
    inactiveColorOverride: '#4af',
  }
)

const { updateStyles, updateGroupStyle } =
  props.compositionMap.useElementOption()
const { movableRefMap, setSelectionMovableRef, tooltipRef } = useMovable()
const builderStore = props.storeMap.useBuilderStore()
const isEditable = computed(() => builderStore.isDocumentEditable)

const selectedElements = computed(() => {
  const selectedElementsMap = targets.value?.map(ev => {
    const elementId = ev?.getAttribute('data-element-id')
    const pageId = ev?.getAttribute('data-page-id')
    const pageIndex = ev?.getAttribute('data-page-index')
    return { elementId, pageId, pageIndex }
  })
  return selectedElementsMap
})

const groupSelectedExist = computed(() => {
  const currentGroups = builderStore.groups
  const groupExist = fetchExistingGroup({
    currentGroups,
    group: selectedElements.value,
  })
  return groupExist
})

const groupExist = computed(() => {
  return groupSelectedExist.value && groupSelectedExist.value.id ? true : false
})

const bounds = computed(
  () =>
    ({
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      position: 'css',
    } as BoundType)
)

const directions = computed(() => ({
  top: true,
  left: true,
  bottom: true,
  right: true,
}))

// eslint-disable-next-line vue/no-dupe-keys
const elementSnapDirections = computed(() => {
  return {
    top: true,
    left: true,
    bottom: true,
    right: true,
    center: true,
    middle: true,
  }
})

const snapContainer = computed(() => {
  const selected = targets.value.reduce((acc, element: any) => {
    // Check if the id already exists in the new array
    const pageIndex: string = element?.dataset?.pageIndex || ''
    if (pageIndex && !acc.includes(pageIndex)) {
      // If it doesn't exist, add it to the new array
      acc.push(pageIndex)
    }
    return acc
  }, [])

  if (selected && selected.length === 1) {
    return `.snapContainer-${selected[0]}`
  }
  return `.pages-wrapper`
})

const isDragEnabled = computed(() => {
  // get all the page id of the elements.
  const selectedCheckBox = targets.value.reduce((acc, element: any) => {
    // Check if the id already exists in the new array
    const pageId: string = element?.dataset?.pageId || ''
    if (pageId && !acc.includes(pageId)) {
      // If it doesn't exist, add it to the new array
      acc.push(pageId)
    }
    return acc
  }, [])

  // check all the elements Id is belongs to one page.
  if (selectedCheckBox && selectedCheckBox.length === 1) {
    return true
  }
  return false
})

/*
 * This is to set the tool tip position for the selected group. [Top Tooltip]
 * It will set the absolute position based on the group movable box.
 */
const setGroupTooltipPosition = ({
  top,
  left,
  width,
}: {
  top: number
  left: number
  width: number
}) => {
  if (groupTooltipAbleRef.value) {
    groupTooltipAbleRef.value.style.top = `${top - 15}px`
    groupTooltipAbleRef.value.style.right = `calc(100% - ${left + width}px)`
  }
}

/*
 * This tooltip show in the bottom of the group movable box.[Bottom Tooltip]
 * The movement of + icon in the group movable box changing based on this function.
 */
const setAddToGroupTooltipPosition = ({
  top,
  left,
  height,
  width,
}: {
  top: number
  height: number
  left: number
  width: number
}) => {
  if (cloneAndAddElementToGroup.value) {
    cloneAndAddElementToGroup.value.style.top = `${top + height + 2}px`
    cloneAndAddElementToGroup.value.style.right = `calc(100% - ${
      left + width / 2 + 15
    }px)`
  }
}

const setRequiredPosition = ({
  top,
  left,
  width,
}: {
  top: number
  left: number
  width: number
}) => {
  if (groupRequiredAbleRef.value) {
    groupRequiredAbleRef.value.style.top = `${top - 20}px`
    groupRequiredAbleRef.value.style.right = `calc(100% - ${
      left + width + 6
    }px)`
  }
}

const onDragGroup = e => {
  isDragging.value = true
  e.events.forEach(ev => {
    const elementId = ev.target.getAttribute('data-element-id')
    movableRefMap.value[elementId]?.updateRect()
    updateStyles(
      'position',
      {
        top: ev.top,
        left: ev.left,
      },
      elementId
    )
  })
  if (groupSelectedExist.value && groupSelectedExist.value.id) {
    updateGroupStyle(
      'position',
      {
        top: e.top,
        left: e.left,
      },
      groupSelectedExist.value.id
    )
  }

  syncGroupTooltip()
}

const syncGroupTooltip = () => {
  if (targets.value.length > 1 && moveableSelectionRef.value) {
    moveableSelectionRef.value.updateRect()
    displayToolTip.value = true
    displayAddToGroupToolTip.value = true
    const { top, left, height, width } = moveableSelectionRef.value.getRect()
    if (top && left && height && width) {
      setGroupTooltipPosition({ top, left, width })
      setAddToGroupTooltipPosition({ top, left, height, width })
      setRequiredPosition({ top, left, width })
    }
  }
  if (targets.value.length <= 1) {
    displayToolTip.value = false
    displayAddToGroupToolTip.value = false
  }
}

const onDragStart = e => {
  if (e.isDouble) {
    targets.value = []
    e.stop()
  }
  moveableSelectionRef?.value?.updateRect()
  const moveable = moveableSelectionRef.value
  const target = e.inputEvent.target
  const movableRefMapList = Object.values(movableRefMap?.value)
  const flatted = targets.value.flat(3)
  const isMovable = movableRefMapList.some(moveRef => {
    return moveRef?.isMoveableElement(target)
  })

  if (targets.value && targets.value.length > 0) {
    const isMovableSelected =
      moveableSelectionRef?.value?.isMoveableElement(target)
    if (isMovableSelected) {
      // targets.value?.forEach(ev => {
      //   const elementId = ev.getAttribute('data-element-id')
      //   const elementClicked = movableRefMap.value[elementId]?.isInside(
      //     e.clientX,
      //     e.clientY
      //   )
      //   // the movable selection is there. and we need to click the checkbox now.
      //   if (elementClicked) {
      //     console.log('elementId clicked', elementId)
      //   }
      // })
      e.stop()
    }
  }

  const isTextField =
    target.classList.contains('n-input__textarea-el') &&
    target?.parentElement?.parentElement?.parentElement?.parentElement?.classList?.contains(
      'el-wrapper'
    )
  const isFillableElements =
    target?.offsetParent?.classList?.contains('el-wrapper')

  const groupToolTipClick =
    groupTooltipAbleRef.value && groupTooltipAbleRef.value?.contains(target)

  const groupElementCloneClick =
    cloneAndAddElementToGroup.value &&
    cloneAndAddElementToGroup.value?.contains(target)

  const isStaticElementDragging =
    target?.classList?.contains('remove-selecto-selection') ||
    target?.parentElement?.classList?.contains('remove-selecto-selection')

  if (
    isTextField ||
    isFillableElements ||
    isMovable ||
    target?.classList?.contains('el-wrapper') ||
    isStaticElementDragging ||
    target?.classList?.contains('n-checkbox-box__border') ||
    groupToolTipClick ||
    groupElementCloneClick ||
    moveable?.isMoveableElement(target) ||
    flatted.some(t => t === target || t?.contains(target))
  ) {
    e.preventDrag()
    if (groupToolTipClick || groupElementCloneClick) {
      e.stop()
    }
  }
  syncGroupTooltip()
}

const onDragGroupEnd = e => {
  isDragging.value = false
  e.events.forEach(ev => {
    const elementId = ev.target.getAttribute('data-element-id')
    movableRefMap.value[elementId]?.updateRect()
    setTimeout(() => syncElementRecipientTooltip(elementId), 15)
  })
  moveableSelectionRef?.value?.updateRect()
  setTimeout(() => syncGroupTooltip(), 15)
}

const onSelectEnd = e => {
  const moveable = moveableSelectionRef.value
  const selected = e.selected
  const groupInfo = getGroupInfoWithElements(builderStore.groups, selected)
  const {
    closestGroup,
    isPartialGroup,
    group,
    isConflict,
    selectedElementsMap,
  } = groupInfo

  if (isConflict) {
    targets.value = []
    e.stop()
  }
  if (closestGroup && isPartialGroup && !group && !isConflict) {
    makeActiveGroupWithSelectedElements(closestGroup, selectedElementsMap)
  }

  if (group && isPartialGroup && !isConflict) {
    activeGroupInfo.value = groupInfo?.group
    makeActiveGroup(group)
  }

  if (!group && !isPartialGroup && !isConflict) {
    targets.value = e.selected
  }

  if (selected && selected.length === 0) {
    targets.value = []
  }

  moveable?.waitToChangeTarget().then(() => {
    moveableSelectionRef?.value?.updateRect()
    syncGroupTooltip()
    const selectedElementMap = targets.value.map(ev => {
      const elementId = ev?.getAttribute('data-element-id')
      const pageId = ev?.getAttribute('data-page-id')
      const pageIndex = ev?.getAttribute('data-page-index')
      return { elementId, pageId, pageIndex }
    })
    emit(SelectoComponentEvents.ON_SELECTO_SELECT_END, {
      targets: targets.value,
      event: e,
      selectedElements: selectedElementMap,
    })
  })
}

onMounted(() => {
  syncGroupTooltip()
  setSelectionMovableRef(moveableSelectionRef.value)
})

const syncElementRecipientTooltip = (elementId: string) => {
  const rect = movableRefMap.value[elementId].getRect()
  const { height, top, left } = rect
  if (tooltipRef.value[elementId]) {
    tooltipRef.value[elementId].style.top = `${top + height + 15}px`
    tooltipRef.value[elementId].style.left = `${left + 15}px`
  }
}

const onChangeTargets = e => {
  e.targets.forEach(ev => {
    const elementId = ev.getAttribute('data-element-id')
    movableRefMap.value[elementId]?.updateRect()
  })
  setTimeout(() => syncGroupTooltip(), 15)
}

const onGroupElements = () => {
  emit(SelectoComponentEvents.ON_SELECTO_GROUP, {
    targets: targets.value,
    selectedElements: selectedElements.value,
    groupData: groupSelectedExist.value,
  })
}

const onUnGroupElements = () => {
  targets.value = []
  emit(SelectoComponentEvents.ON_SELECTO_UNGROUP, {
    targets: targets.value,
    selectedElements: selectedElements.value,
    groupData: groupSelectedExist.value,
  })
}

const onGroupClone = () => {
  emit(SelectoComponentEvents.ON_SELECTO_CLONE, {
    targets: targets.value,
    selectedElements: selectedElements.value,
    groupData: groupSelectedExist.value,
  })
}

const onGroupDelete = () => {
  emit(SelectoComponentEvents.ON_SELECTO_DELETE, {
    targets: targets.value,
    selectedElements: selectedElements.value,
    groupData: groupSelectedExist.value,
  })
  targets.value = []
}

const makeActiveGroupWithSelectedElements = (
  group: GroupFields<GroupFieldsOptions>,
  selectedElementIds: string[] = []
) => {
  if (group && group.id) {
    if (group && group.children && group.children.length > 0) {
      const cgChild = group.children.map(({ elementId }) => elementId)
      const elementIds = [...new Set([...selectedElementIds, ...cgChild])]
      const selectedElements = elementIds.map(elementId => {
        const selector = '.select-o-field[data-element-id="' + elementId + '"]'
        return document.querySelector(selector)
      })
      targets.value = selectedElements
    }
  }
}

const makeActiveGroup = (group: GroupFields<GroupFieldsOptions>) => {
  if (group && group.id) {
    targets.value = []
    if (group && group.children && group.children.length > 0) {
      const selectedElements = group.children.map(
        (value: { pageId: string; elementId: string }) => {
          const selector =
            '.select-o-field[data-element-id="' + value.elementId + '"]'

          return document.querySelector(selector)
        }
      )
      targets.value = selectedElements
    }
    activeGroupInfo.value = group
  }
}

watch(
  () => props.activeGroup,
  updatedGroup => {
    if (updatedGroup) {
      setTimeout(() => makeActiveGroup(updatedGroup), 50)
    }
  },
  { immediate: true, deep: true }
)

const cloneAndAddToGroup = (event: any) => {
  event.stopPropagation()
  emit(
    SelectoComponentEvents.ON_CLONE_AND_ADD_TO_GROUP,
    activeGroupInfo.value?.id
  )
}

const requiredGroup = computed(() => {
  return (
    groupExist.value &&
    groupSelectedExist.value &&
    getRequiredValidation(groupSelectedExist.value)
  )
})

const elementGuidelines = computed(() => ['.el-wrapper', 'section'])
</script>

<template>
  <div
    ref="groupTooltipAbleRef"
    :style="{
      display: isEditable && displayToolTip && !isDragging ? 'flex' : 'none',
      zIndex: 101,
      position: 'absolute',
      willChange: 'position',
    }"
    class="moveable-wrapper--ables actions absolute -mt-8 flex"
  >
    <CommonGroupTooltip
      :groupExist="groupExist"
      @on-group-elements="onGroupElements"
      @on-ungroup-elements="onUnGroupElements"
      @on-group-delete="onGroupDelete"
      @on-group-clone="onGroupClone"
    />
  </div>
  <div
    ref="cloneAndAddElementToGroup"
    :style="{
      display:
        isEditable && displayAddToGroupToolTip && !isDragging ? 'flex' : 'none',
      zIndex: 101,
      position: 'absolute',
      willChange: 'position',
    }"
    class="moveable-wrapper--ables flex"
  >
    <UITooltip :placement="'bottom'">
      <template #trigger>
        <div
          class="text-primary-700 cursor-pointer flex"
          @click="cloneAndAddToGroup"
        >
          <PlusSquareIcon class="w-6 h-6 p-1" />
        </div>
      </template>
      {{ $t('common.addCheckboxToGroup') }}
    </UITooltip>
  </div>
  <span
    ref="groupRequiredAbleRef"
    :style="{
      display: requiredGroup ? 'flex' : 'none',
      zIndex: 101,
      position: 'absolute',
      willChange: 'position',
    }"
    class="option--required"
    >*</span
  >
  <Moveable
    :key="`moveable-component-with-selecto`"
    :throttleDrag="1"
    :useResizeObserve="true"
    :useMutationObserver="true"
    :useAccuratePosition="true"
    ref="moveableSelectionRef"
    :draggable="isDragEnabled"
    :target="targets"
    :origin="false"
    @dragGroup="onDragGroup"
    @dragGroupEnd="onDragGroupEnd"
    :snappable="true"
    :snapContainer="snapContainer"
    :elementGuidelines="elementGuidelines"
    :bounds="bounds"
    :hideChildMoveableDefaultLines="false"
    :isDisplaySnapDigit="false"
    :isDisplayInnerSnapDigit="false"
    :edgeDraggable="false"
    :snapGap="true"
    :snapDirections="directions"
    :snapThreshold="props.snapThreshold"
    :elementSnapDirections="elementSnapDirections"
    @changeTargets="onChangeTargets"
  ></Moveable>

  <Selecto
    ref="selectoRef"
    :dragContainer="'.pages-wrapper'"
    :selectableTargets="['.select-o-field']"
    :hitRate="0"
    :selectByClick="true"
    :selectFromInside="true"
    :toggleContinueSelect="['shift']"
    :ratio="0"
    @dragStart="onDragStart"
    @selectEnd="onSelectEnd"
  ></Selecto>
</template>

<style lang="scss">
.moveable-control-box {
  z-index: 100 !important;
}
</style>
