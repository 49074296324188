import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const useClipboard = () => {
  const { t } = useI18n()

  const INIT_VALUE_OF_CLIPBOARD = t('common.copyToClipboard')
  const clipboardText = ref(INIT_VALUE_OF_CLIPBOARD)

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(`{{${value}}}`)
    clipboardText.value = t('common.copied')
    // TODO: improve experience
    setTimeout(() => {
      clipboardText.value = INIT_VALUE_OF_CLIPBOARD
    }, 500)
  }

  return {
    clipboardText,
    onCopy: copyToClipboard,
  }
}
