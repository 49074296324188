<script setup lang="ts">
import { UIFormItem, UIInput, UIButton, UIDropdown } from '@gohighlevel/ghl-ui'
import { DotsVerticalIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useI18n } from 'vue-i18n'
import { defineProps, PropType } from 'vue'
import { ObjectForLinkedField } from '../../const'
import {
  IElement,
  ICustomFieldsLinkItem,
  CustomValueLinkageOperationsEvents,
} from '../../types'

const emit = defineEmits([
  CustomValueLinkageOperationsEvents.CUSTOM_VALUE_LINKAGE_DELETE,
  CustomValueLinkageOperationsEvents.CUSTOM_VALUE_LINKAGE_EDIT,
])

const { t } = useI18n()
const props = defineProps({
  element: Object as PropType<IElement>,
  customValueLinkage: {
    type: Array as PropType<ICustomFieldsLinkItem[]>,
    required: true,
  },
})

const onSelectCVLActions = (value: string, cvl: ICustomFieldsLinkItem) => {
  if (value === 'edit') {
    emit(CustomValueLinkageOperationsEvents.CUSTOM_VALUE_LINKAGE_EDIT, {
      type: value,
      data: cvl,
      element: props.element,
    })
  } else if (value === 'delete') {
    emit(CustomValueLinkageOperationsEvents.CUSTOM_VALUE_LINKAGE_DELETE, {
      type: value,
      data: cvl,
      element: props.element,
    })
  }
}

const customValueLinkOptions = [
  {
    label: t('common.edit'),
    key: 'edit',
  },
  {
    label: t('common.delete'),
    key: 'delete',
  },
]
</script>

<template>
  <UIFormItem
    v-for="(cvl, index) in customValueLinkage"
    :key="'custom-value-linkage-' + index"
    :label="
      cvl?.objectType === ObjectForLinkedField.DOCUMENT_VARIABLE
        ? $t('common.documentVariable')
        : $t('common.customValueVariable')
    "
  >
    <UIInput
      :id="`${element?.id}-linked-fields-placeholder-${index}`"
      :value="cvl?.fieldKey || ''"
    />
    <UIDropdown
      :id="`document-link-actions-${index}`"
      :options="customValueLinkOptions"
      @select="val => onSelectCVLActions(val, cvl)"
    >
      <UIButton
        class="hover:text-primary-600"
        :id="`document-link-actions-btn-${index}`"
        :circle="true"
        :quaternary="true"
        ><DotsVerticalIcon class="w-5 h-5"
      /></UIButton>
    </UIDropdown>
  </UIFormItem>
</template>
