<script lang="ts" setup>
import {
  AddColumnsLeftIcon,
  AddColumnsRightIcon,
  AddRowsBottomIcon,
  AddRowsTopIcon,
  ColumnRemoveIcon,
  GridDotsOuterIcon,
  LayoutGrid01Icon,
  LayoutRightIcon,
  LayoutTopIcon,
  RowRemoveIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIDivider, UITooltip, UIColorPicker } from '@gohighlevel/ghl-ui'
import { h, ref } from 'vue'
import { debounce } from 'lodash'

const props = defineProps({
  editor: {
    type: Object,
    default: undefined,
    required: true,
  },
  bgColor: {
    type: String,
    default: '#FFF',
  },
  borderColor: {
    type: String,
    default: '#FFF',
  },
})

const bgColor = ref(props.bgColor)
const borderColor = ref(props.borderColor)

const customTrigger = () => {
  return h(
    'div',
    {
      class: 'ghl-color-preview',
      style: {
        background:
          'conic-gradient(from 180deg at 50% 50%, #EAAA08 0deg, #12B76A 50.74deg, #2970FF 108.47deg, #875BF7 168.91deg, #F04438 228.48deg, #EF6820 297.33deg, #EAAA08 360deg)',
        width: '20px',
        height: '20px',
        left: '0px',
        top: '0px',
        'border-radius': '4px',
      },
    },
    [
      h('div', {
        style: {
          width: '10px',
          height: '10px',
          left: '5px',
          top: '5px',
          background: '#FFFFFF',
          position: 'absolute',
          'box-shadow': '0px 0.833333px 1.66667px rgba(16, 24, 40, 0.05)',
          'border-radius': '2px',
        },
      }),
    ]
  )
}

const setBgColor = () => {
  props?.editor
    ?.chain()
    .focus()
    .setCellAttribute('backgroundColor', bgColor.value)
    .run()
}

const setBorderColor = () => {
  props?.editor
    ?.chain()
    .focus()
    .setCellAttribute('borderColor', borderColor.value)
    .run()
}

const handleColorChange = debounce((property: string, value: string) => {
  if (property === 'backgroundColor') {
    bgColor.value = value
    debounce(setBgColor, 200)()
  } else if (property === 'borderColor') {
    borderColor.value = value
    debounce(setBorderColor, 200)()
  }
}, 500)
</script>

<template>
  <div
    v-if="editor"
    class="animate-slideUp absolute bottom-[calc(100%+6px)] left-[64px] -mt-10 flex z-20"
  >
    <div
      class="flex justify-start bg-gray-charcoal border border-gray-700 shadow-float p-2 rounded-sm flex-wrap pt-0"
    >
      <UITooltip class="bg-gray-charcoal">
        <template #trigger>
          <div
            class="flex mr-3 mt-[8px]"
            id="addRowBefore"
            @click="editor.chain().focus().addRowBefore().run()"
          >
            <AddRowsTopIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.addRowAbove') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-3 mt-[8px]"
            id="addRowAfter"
            @click="editor.chain().focus().addRowAfter().run()"
          >
            <AddRowsBottomIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.addRowBelow') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-3 mt-[8px]"
            id="addColumnBefore"
            @click="editor.chain().focus().addColumnBefore().run()"
          >
            <AddColumnsLeftIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.addColumnBefore') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-0 mt-[8px]"
            id="addColumnAfter"
            @click="editor.chain().focus().addColumnAfter().run()"
          >
            <AddColumnsRightIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.addColumnAfter') }}
      </UITooltip>

      <div class="pt-[3px]"><UIDivider vertical /></div>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-3 mt-[8px]"
            :disabled="!editor.can().toggleHeaderRow()"
            @click="editor.chain().focus().toggleHeaderRow().run()"
          >
            <LayoutTopIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.toggleHeaderRow') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-0 mt-[8px]"
            :disabled="!editor.can().toggleHeaderCell()"
            @click="editor.chain().focus().toggleHeaderCell().run()"
          >
            <LayoutRightIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.toggleHeaderCell') }}
      </UITooltip>

      <div class="pt-[3px]"><UIDivider vertical /></div>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-3 mt-[8px]"
            id="deleteRow"
            @click="editor.chain().focus().deleteRow().run()"
          >
            <RowRemoveIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.deleteRow') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-3 mt-[8px]"
            id="deleteColumn"
            @click="editor.chain().focus().deleteColumn().run()"
          >
            <ColumnRemoveIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.deleteColumn') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-3 mt-[8px]"
            id="mergeCells"
            @click="editor.chain().focus().mergeCells().run()"
          >
            <GridDotsOuterIcon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.mergeCells') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            class="flex mr-0 mt-[8px]"
            id="splitCell"
            @click="editor.chain().focus().splitCell().run()"
          >
            <LayoutGrid01Icon
              class="w-4 h-4 text-white hover:text-gray-300 cursor-pointer"
            />
          </div>
        </template>
        {{ $t('common.splitCells') }}
      </UITooltip>

      <div class="pt-[3px]"><UIDivider vertical /></div>

      <UITooltip>
        <template #trigger>
          <div
            id="bgColor"
            class="flex table-menu-color-group w-4 h-4 text-white hover:text-gray-300 cursor-pointer ml-0 mt-2"
          >
            <UIColorPicker
              class="w-4 h-4"
              :modes="['hex']"
              @update:value="handleColorChange('backgroundColor', $event)"
              :customTrigger="customTrigger"
              :value="bgColor"
            />
          </div>
        </template>
        {{ $t('common.backgroundColor') }}
      </UITooltip>

      <UITooltip>
        <template #trigger>
          <div
            id="borderColor"
            class="flex table-menu-color-group w-4 h-4 text-white hover:text-gray-300 cursor-pointer ml-2 mt-2"
          >
            <UIColorPicker
              :modes="['hex']"
              @update:value="handleColorChange('borderColor', $event)"
              :customTrigger="customTrigger"
              :value="borderColor"
            />
          </div>
        </template>
        {{ $t('common.borderColor') }}
      </UITooltip>
    </div>
  </div>
</template>

<style lang="scss">
.table-menu-color-group {
  .n-color-picker-trigger {
    .n-color-picker-trigger__fill {
      border-radius: var(--n-border-radius);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 8px;
      top: 0px;
      right: 0px;
      bottom: 4px;
      .n-color-picker-trigger__value {
        .ghl-color-preview {
          width: 16px !important;
          height: 16px !important;
          div {
            width: 6px !important;
            height: 6px !important;
          }
        }
      }
    }
  }
}
</style>
