<script lang="ts" setup>
import { UIBuilderSpace } from '@gohighlevel/ghl-ui'
import { PropType, computed } from 'vue'
import { IElement } from '../..'

const props = defineProps({
  element: Object as PropType<IElement>,
})

defineEmits(['onUpdate'])

const margin = computed(() => {
  return {
    top: props.element?.responsiveStyles?.large?.marginTop || '0px',
    bottom: props.element?.responsiveStyles?.large?.marginBottom || '0px',
    right: props.element?.responsiveStyles?.large?.marginRight || '0px',
    left: props.element?.responsiveStyles?.large?.marginLeft || '0px',
  }
})

const padding = computed(() => {
  return {
    top: props.element?.responsiveStyles?.large?.paddingTop || '0px',
    bottom: props.element?.responsiveStyles?.large?.paddingBottom || '0px',
    right: props.element?.responsiveStyles?.large?.paddingRight || '0px',
    left: props.element?.responsiveStyles?.large?.paddingLeft || '0px',
  }
})
</script>

<template>
  <UIBuilderSpace
    :margin="margin"
    :padding="padding"
    @update:value="value => $emit('onUpdate', value)"
  />
</template>

<style lang="scss">
.ghl-spacing__menu {
  .n-input__input {
    display: flex !important;
    align-items: center !important;
  }
}

.ghl-spacing__input {
  .margin--top {
    width: unset;
  }
  .margin--bottom {
    width: unset;
  }
}

.ghl-spacing {
  .margin--top {
    position: absolute;
    top: 0;
    height: 25px;
    width: calc(100% - 108px);
    margin: 4px auto;
  }
  .margin--bottom {
    position: absolute;
    bottom: 0;
    height: 25px;
    width: calc(100% - 108px);
    margin: 0px auto;
    border-radius: 8px;
  }
}

.ghl-spacing__trigger {
  .ghl-spacing__trigger-value,
  .ghl-spacing__trigger-unit {
    word-break: normal;
  }

  .hl-text-sm-normal {
    font-size: 12px !important;
  }
}
</style>
