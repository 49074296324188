import { Frequency, RRule } from 'rrule'
import {
  DAY_NAMES,
  EndType,
  IntervalType,
  MONTH_NAMES,
  ScheduleRRuleOptions,
} from '../const'
import i18n from '../locales'
import {
  dayjsTZ,
  formatDayAbbreviation,
  formatMonthNumber,
  getHourMinSec,
  getYearMonthDate,
  parseDateTimeInUTC,
} from './date'

export const prepareIntervalLabel = (
  intervalType: IntervalType,
  plural: boolean
) => {
  let label = ''
  switch (intervalType) {
    case IntervalType.MINUTELY:
      label = i18n.global.t('schedulingOptions.intervalType.minute')
      break
    case IntervalType.HOURLY:
      label = i18n.global.t('schedulingOptions.intervalType.hour')
      break
    case IntervalType.DAILY:
      label = i18n.global.t('schedulingOptions.intervalType.day')
      break
    case IntervalType.WEEKLY:
      label = i18n.global.t('schedulingOptions.intervalType.week')
      break
    case IntervalType.MONTHLY:
      label = i18n.global.t('schedulingOptions.intervalType.month')
      break
    case IntervalType.YEARLY:
      label = i18n.global.t('schedulingOptions.intervalType.year')
      break
  }
  return plural ? `${label}s` : label
}

export const prepareScheduleDetails = (rrule: ScheduleRRuleOptions) => {
  const formattedRRule: ScheduleRRuleOptions = {
    intervalType: rrule.intervalType,
    interval: Object.prototype.hasOwnProperty.call(rrule, 'interval')
      ? Number(rrule.interval)
      : rrule.interval,
    startDate: rrule?.startDate,
    endType: rrule.endType,
    useStartAsPrimaryUserSigned: rrule.useStartAsPrimaryUserSigned,
  }
  if (
    ![IntervalType.MINUTELY, IntervalType.HOURLY, IntervalType.DAILY].includes(
      rrule.intervalType as IntervalType
    )
  )
    formattedRRule.daysBefore = Number(rrule.daysBefore || 0)
  if (rrule.intervalType === IntervalType.WEEKLY) {
    formattedRRule.dayOfWeek = rrule.dayOfWeek
  }
  if (rrule.intervalType === IntervalType.MONTHLY) {
    if (rrule.numOfWeek) {
      formattedRRule.dayOfWeek = rrule.dayOfWeek
      formattedRRule.numOfWeek = rrule.numOfWeek
    } else {
      formattedRRule.dayOfMonth = rrule.dayOfMonth
    }
  }
  if (rrule.intervalType === IntervalType.YEARLY) {
    formattedRRule.monthOfYear = rrule.monthOfYear
    formattedRRule.dayOfMonth = rrule.dayOfMonth
  }
  if (rrule?.endType && rrule.endType === EndType.BY) {
    formattedRRule.endDate = rrule.endDate
    formattedRRule.endTime = rrule.endTime || '23:59:59'
  }
  if (rrule?.endType && rrule.endType === EndType.AFTER) {
    formattedRRule.count = Object.prototype.hasOwnProperty.call(rrule, 'count')
      ? Number(rrule.count)
      : rrule.count
  }
  if (
    [IntervalType.MINUTELY, IntervalType.HOURLY].includes(
      rrule.intervalType as IntervalType
    )
  ) {
    if (rrule.startTime) {
      formattedRRule.startTime = dayjsTZ(rrule.startTime, 'HH:mm').format(
        'HH:mm:ss'
      )
    }
    if (rrule?.endType && rrule.endType === EndType.BY && rrule.endTime) {
      formattedRRule.endTime = dayjsTZ(rrule.endTime, 'HH:mm').format(
        'HH:mm:ss'
      )
    }
  }
  return {
    rrule: formattedRRule,
  }
}

export const createRRule = (data: ScheduleRRuleOptions, timezone: string) => {
  const options = prepareRRuleOptions(data, timezone)
  return new RRule(options)
}

export const calculateAllOccurrences = (rrule: RRule) => {
  return rrule
    .all((_, i) => i < 1)
    .map(dt => {
      return parseDateTimeInUTC(dt)
    })
}

export const prepareRRuleOptions = (
  data: ScheduleRRuleOptions,
  timezone: string
) => {
  const {
    intervalType,
    interval,
    startDate,
    startTime,
    endDate,
    endTime,
    dayOfMonth,
    dayOfWeek,
    numOfWeek,
    monthOfYear,
    count,
  } = data

  const options: any = {
    freq: Frequency[intervalType?.toUpperCase() as any],
    interval,
    count,
    tzid: timezone,
  }

  if (startDate && startDate != 'Invalid Date') {
    const [year, month, date] = getYearMonthDate(startDate)
    const [hour = 0, minute = 0, second = 0] =
      startTime && startTime != 'Invalid Date' ? getHourMinSec(startTime) : []
    options.dtstart = new Date(
      Date.UTC(year, month, date, hour, minute, second)
    )
  }

  const hasDayOfMonth = Object.prototype.hasOwnProperty.call(data, 'dayOfMonth')
  const hasDayOfWeek = Object.prototype.hasOwnProperty.call(data, 'dayOfWeek')
  const hasNumOfWeek = Object.prototype.hasOwnProperty.call(data, 'numOfWeek')
  const hasMonthOfYear = Object.prototype.hasOwnProperty.call(
    data,
    'monthOfYear'
  )

  if (options.freq === Frequency.WEEKLY) {
    if (!hasDayOfWeek)
      throw new Error(i18n.global.t('validations.invalid.weeklyOptions'))
    if (dayOfWeek) {
      const dayIndex = DAY_NAMES[dayOfWeek?.toLowerCase()]
      options.byweekday = RRule[formatDayAbbreviation(dayIndex)]
    }
  }

  if (options.freq === Frequency.MONTHLY) {
    if (
      (!hasDayOfMonth && (!hasDayOfWeek || !hasNumOfWeek)) ||
      (hasDayOfMonth && (hasDayOfWeek || hasNumOfWeek))
    )
      throw new Error(i18n.global.t('validations.invalid.monthlyOptions'))
    if (hasDayOfMonth) options.bymonthday = dayOfMonth as number
    if (hasDayOfWeek && hasNumOfWeek && dayOfWeek) {
      const dayIndex = DAY_NAMES[dayOfWeek?.toLowerCase()]
      options.byweekday = RRule[formatDayAbbreviation(dayIndex)].nth(numOfWeek)
    }
  }

  if (options.freq === Frequency.YEARLY) {
    if (!hasDayOfMonth || !hasMonthOfYear)
      throw new Error(i18n.global.t('validations.invalid.yearlyOptions'))

    if (monthOfYear && hasDayOfMonth) {
      const monthIndex = MONTH_NAMES[monthOfYear?.toLowerCase()]
      options.bymonth = Number(formatMonthNumber(monthIndex))
      options.bymonthday = dayOfMonth
    }
  }

  if (endDate && endDate != 'Invalid Date') {
    const [year, month, date] = getYearMonthDate(endDate)
    const [hour = 23, minute = 59, second = 59] =
      endTime && endTime != 'Invalid Date' ? getHourMinSec(endTime) : []
    options.until = new Date(Date.UTC(year, month, date, hour, minute, second))
  }
  return options
}
