<script setup lang="ts">
import {
  UIFormItem,
  UIInputNumber,
  UIInput,
  UISwitch,
  UITextSmMedium,
  UIPopover,
  UITextXsMedium,
} from '@gohighlevel/ghl-ui'
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useCurrency } from '@/composition'
import { PropType, computed, ref } from 'vue'
import { useProductListStore } from '@/store/productListStore'
import { useProductListActions } from '@/composable/useProductListActions'
import {
  IElement,
  LineItem,
  PriceTypeEnum,
  ProductListElementOptions,
} from '@gohighlevel/ghl-proposals-common'

const props = defineProps({
  element: Object as PropType<IElement<ProductListElementOptions>>,
})

const productListStore = useProductListStore()
const activeRow = computed(() => productListStore.activeRow)
const isRecurring = computed(
  () => productListStore.invoiceType === PriceTypeEnum.RECURRING
)
const priceText = ref(activeRow.value?.priceText || '')
const { updateSelectedLineItem } = useProductListActions()
const { getCurrencySymbol } = useCurrency()

const validationRules = {
  price: {
    trigger: ['input', 'blur', 'focus', 'change'],
    validator() {
      if (activeRow.value && activeRow.value.price < 0) {
        return new Error()
      }
      return true
    },
  },
  priceText: {
    trigger: ['input', 'blur', 'focus', 'change'],
    validator() {
      // if (activeRow.value && !activeRow.value.priceText) {
      //   return new Error()
      // }
      return true
    },
  },
}
const handlePriceUpdate = (value: number) => {
  if (activeRow.value) {
    activeRow.value.price = value
    updateSelectedLineItem('price', activeRow.value.price)
    updateSelectedLineItem(
      'subtotal',
      activeRow.value.price * activeRow.value.qty
    )
    if (!activeRow?.value?.priceText) {
      handlePriceTextUpdate(activeRow.value.priceText)
    }
  }
}

const handlePriceTextUpdate = (value: string) => {
  if (activeRow.value) {
    const price = activeRow?.value?.price?.toString()
      ? getCurrencySymbol() +
        ` ${parseFloat(activeRow?.value?.price.toFixed(2)).toLocaleString(
          'en',
          {
            useGrouping: false,
            minimumFractionDigits: 2,
          }
        )}`
      : ''
    activeRow.value.priceText = value || price || ''
    priceText.value = activeRow.value.priceText
    updateSelectedLineItem('priceText', priceText.value)
  }
}
const onToggleSwitch = (key: keyof LineItem, val: boolean) => {
  if (activeRow.value) {
    activeRow.value[key] = val
    updateSelectedLineItem(key, activeRow.value[key])
  }
}
const onQtyChange = (key: keyof LineItem, val: number) => {
  if (activeRow.value) {
    activeRow.value[key] = val
    updateSelectedLineItem(key, activeRow.value[key])
  }
}
const onPriceTextChange = (value: string) => {
  priceText.value = value
}
</script>

<template>
  <div class="flex flex-col py-4">
    <div class="w-full flex mb-4" :key="activeRow?.key">
      {{
        isRecurring && !activeRow?.isSetupFeeItem
          ? $t('schedulingOptions.recurringSchedulePropertiesText')
          : $t('schedulingOptions.onTimePropertyText')
      }}
    </div>

    <div class="w-full flex gap-4">
      <div class="w-4/12">
        <UIFormItem :rule="validationRules.price" :label="$t('common.price')">
          <UIInputNumber
            id="product-price-amount-input"
            :onUpdate:value="handlePriceUpdate"
            :showButton="false"
            :value="activeRow?.price"
            class="items-center"
            step="0.1"
            :min="0"
            :precision="2"
            size="large"
          >
            <template #prefix>{{
              getCurrencySymbol(activeRow?.currency)
            }}</template>
          </UIInputNumber>
        </UIFormItem>
      </div>
      <div class="w-8/12">
        <UIFormItem
          :rule="validationRules.priceText"
          :label="$t('common.displayText')"
        >
          <UIInput
            id="product-price-display-text-input"
            @update:model-value="onPriceTextChange"
            @blur="
              () => {
                handlePriceTextUpdate(priceText)
              }
            "
            :value="priceText"
            class="items-center"
            size="large"
          >
          </UIInput>
        </UIFormItem>
      </div>
    </div>
    <div
      v-if="props?.element?.version > 1"
      class="w-full flex items-center mb-4"
    >
      <UISwitch
        @update:value="val => onToggleSwitch('optional', val)"
        id="activeRow.optional"
        :value="activeRow?.optional || false"
      />
      <UITextSmMedium class="ml-2">Optional Item</UITextSmMedium>
      <UIPopover placement="left" to="body" :width="150" :animated="true">
        <template #trigger>
          <InfoCircleIcon class="ml-2 h-4 w-4 text-gray-500" />
        </template>
        <UITextXsMedium
          >Enables primary recipient to select/deselect an item. Amount due will
          be basis the selected items.</UITextXsMedium
        >
      </UIPopover>
    </div>
    <div
      v-if="props?.element?.version > 1"
      class="w-full flex items-center mb-4"
    >
      <UISwitch
        @update:value="val => onToggleSwitch('qtyEditable', val)"
        id="activeRow.qtyEditable"
        :value="activeRow?.qtyEditable || false"
      />
      <UITextSmMedium class="ml-2">Edit Quantity</UITextSmMedium>
      <UIPopover placement="left" to="body" :width="150" :animated="true">
        <template #trigger>
          <InfoCircleIcon class="ml-2 h-4 w-4 text-gray-500" />
        </template>
        <UITextXsMedium
          >Enables primary recipient to change the quantity.</UITextXsMedium
        >
      </UIPopover>
    </div>
    <div
      class="w-full flex items-center gap-4"
      v-if="activeRow?.qtyEditable && props?.element?.version > 1"
      id="min-max-qty"
    >
      <div>
        <UIFormItem label="Min Qty">
          <UIInputNumber
            id="min-qty"
            :onUpdate:value="val => onQtyChange('minQty', val)"
            :showButton="false"
            :value="activeRow?.minQty"
            class="items-center"
            step="1"
            :precision="0"
            size="medium"
            :default-value="1"
            :min="0"
          >
          </UIInputNumber>
        </UIFormItem>
      </div>
      <div>
        <UIFormItem label="Max Qty">
          <UIInputNumber
            id="max-qty"
            :default-value="5"
            :onUpdate:value="val => onQtyChange('maxQty', val)"
            :showButton="false"
            :value="activeRow?.maxQty"
            class="items-center"
            step="1"
            :precision="0"
            size="medium"
            :min="activeRow?.minQty || 5"
            :max="999999999"
          >
          </UIInputNumber>
        </UIFormItem>
      </div>
    </div>
  </div>
</template>
