<script lang="ts" setup>
import { useAppStore } from '@/store/app'
import Postmate from 'postmate'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import config from '../../config'

const props = defineProps({
  disableSelect: {
    type: Boolean,
    required: false,
  },
  mediaType: {
    type: String,
    default: () => {
      return 'image'
    },
  },
  selfHosted: {
    type: Boolean,
    default: false,
  },
  maxUploadSize: {
    // currently we are passing as empty object, it will automatically take 4GB for video and 100MB for other file types as maxSizeLimit
    type: Object,
    default: () => {
      return {}
    },
    /* we can override default value by passing value as below for this variable
      Example: maxUploadSize.value = {
        video: 1000000000, <-- In bytes
        audio: 100000000,
        document: 100000000,
        image: 100000000
      }
      */
  },
})
onMounted(() => {
  console.info('mounted')
  loadMicroApp()
})

const emit = defineEmits(['onSelect', 'onClose'])

const appStore = useAppStore()
const route = useRoute()
const mediaCenterRef = ref(null)

async function loadMicroApp() {
  const locationId = route.params.locationId as string
  const tokenId = appStore.tokenId
  const allowedExtensions = [props.mediaType]
  const application = 'funnel-builder'
  const handshake = new Postmate({
    container: mediaCenterRef.value,
    url: config.MEDIA_CENTER_URL,
    name: 'media-center',
    classListArray: ['h-screen', 'border-0', 'w-full'],
    model: {
      altId: locationId,
      altType: 'location',
      allowedExtensions: allowedExtensions,
      application: application,
      maxUploadSize: props.maxUploadSize,
      tokenId: tokenId,
      selfHosted: props.selfHosted,
    },
  })

  handshake.then((child: any) => {
    child.on('attachment', (file: any) => {
      if (file) {
        emit('onSelect', file)
      }
    })
    child.on('close', (data: boolean) => {
      emit('onClose', data)
    })
    child.on('refresh-token', async () => {
      await appStore.refreshToken()
      child.call('updated-token', appStore.tokenId)
    })
  })
}
</script>

<template>
  <div class="media-container w-full h-screen">
    <div id="media-center" ref="mediaCenterRef" class="w-full h-screen"></div>
  </div>
</template>

<style lang="css">
.h-screen {
  height: 100vh;
}
</style>
