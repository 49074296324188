<script lang="ts">
import { DefineComponent } from 'vue'
import { defineComponent, Component, inject, h } from 'vue'

const WithCommonProvider = <
  T extends Component,
  StoreMapType = any,
  CompositionMapType = any
>(
  WrappedComponent: T
): DefineComponent<{
  storeMap: StoreMapType
  compositionMap: CompositionMapType
}> => {
  return defineComponent({
    setup() {
      const storeMap = inject<StoreMapType>('storeMap')
      const compositionMap = inject<CompositionMapType>('compositionMap')
      return {
        storeMap,
        compositionMap,
      }
    },
    render() {
      return h(WrappedComponent, {
        ...this.$props,
        storeMap: this.storeMap,
        compositionMap: this.compositionMap,
      })
    },
  })
}

export default WithCommonProvider
</script>
