import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import BuilderPage from '@/pages/BuilderPage.vue'
import PreviewPage from '@/pages/PreviewPage.vue'
import { useAppStore } from '@/store/app'

export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/v2/location/:locationId/payments/proposals-estimates/edit/:documentId',
    component: BuilderPage,
    name: 'builder',
  },
  {
    path: '/v2/location/:locationId/payments/proposals-estimates/edit/:documentId/preview',
    component: PreviewPage,
    name: 'preview',
  },
]

export const parentRoutes = [
  {
    path: '/v2/location/:locationId/contacts/detail/:contactId',
    name: 'contact_detail-v2',
  },
  {
    path: '/v2/location/:locationId/payments/proposals-estimates',
    name: 'proposals-estimates',
  },
  {
    path: '/:version/location/:location_id/payments/invoices/:invoiceId',
    name: 'invoice_edit',
  },
  {
    path: '/v2/location/:locationId/payments/proposals-estimates/templates/edit/:id',
    name: 'proposals-estimates-templates',
  },
  {
    path: '/v2/location/:locationId/payments/settings/taxes',
    name: 'payment-settings-taxes',
  },
  {
    path: '/:version/location/:location_id/payments/recurring-template/edit/:invoiceScheduleId',
    name: 'invoice_schedule_edit',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export function emitRouteChangeToParent(path: string, options?: Options) {
  if (!path) return
  const store = useAppStore()
  try {
    if (store.handshake) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      store.handshake.then((parent: any) => {
        parent.emit('route-change', {
          router: path,
          ...options,
        })
      })
    }
  } catch (error) {
    console.error(error)
  }
}

export { router }
