<script lang="ts" setup>
import { useElementOption } from '@/composable/elementOptions.ts'
import { useCurrency, useLineItems } from '@/composition'
import { Trash01Icon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIFormItem,
  UIInputNumber,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UIRadio,
  UISlider,
  UITextSmRegular,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { computed, ref, toRef, watch } from 'vue'
import {
  CommonModalPropsWithElementId,
  GenericEvent,
  ProductLineEvents,
  DiscountType,
  calculator,
  withTwoDecimal,
} from '@gohighlevel/ghl-proposals-common'
import { useProductListStore } from '@/store/productListStore'
import { useI18n } from 'vue-i18n'

const productListStore = useProductListStore()
const { t } = useI18n()
const emit = defineEmits([ProductLineEvents.CLOSE_DISCOUNT_MODAL])
const { updateOption } = useElementOption()
const { formatCurrency, getCurrencySymbol } = useCurrency()
const props = withDefaults(defineProps<CommonModalPropsWithElementId>(), {
  show: false,
})

const { subTotal, totalDiscount, productLineOptions, productListVersion } =
  useLineItems(props.elementId)
const productCurrencySymbol = computed(() =>
  getCurrencySymbol(productListStore.currency)
)
const productListOptionsValue = computed(() => productLineOptions.value)
const subTotalAmount = computed(() => subTotal.value)
const totalDiscountAmount = computed(() => totalDiscount.value)
const getDiscountedAmount = () =>
  subTotalAmount.value - totalDiscountAmount.value
const discountAmt = computed(() => getDiscountedAmount())
const discountedAmount = toRef(discountAmt.value)
const formValue = ref({
  discountType: productListOptionsValue.value.discountType,
  discountValue: productListOptionsValue.value.discountValue,
})
watch(props, () => {
  if (props.show) {
    discountedAmount.value = discountAmt.value
    formValue.value = {
      discountType: productListOptionsValue.value.discountType,
      discountValue: productListOptionsValue.value.discountValue,
    }
  }
})
const cancelCallback = () => {
  emit(ProductLineEvents.CLOSE_DISCOUNT_MODAL)
}
const handleDiscountTypeChange = (e: GenericEvent<HTMLInputElement>) => {
  formValue.value.discountType = e.target.value as DiscountType
  discountedAmount.value = subTotalAmount.value
  formValue.value.discountValue = 0
}

const handleDiscountPercentageChange = (percentage: number) => {
  const { percentageOf } = calculator(subTotal.value, productListVersion.value)
  formValue.value.discountValue = percentage
  discountedAmount.value = withTwoDecimal(
    Number(subTotal.value - percentageOf(percentage)),
    productListVersion.value
  )
}

const handleDiscountCustomAmount = (amount: number) => {
  discountedAmount.value = subTotal.value - amount
  formValue.value.discountValue = amount
}
const onSaveDiscount = () => {
  const { discountValue, discountType } = formValue.value
  updateOption('discountValue', discountValue, props.elementId)
  updateOption('discountType', discountType, props.elementId)
  cancelCallback()
}

const removeDiscount = () => {
  formValue.value.discountValue = 0
  formValue.value.discountType = DiscountType.PERCENTAGE
  onSaveDiscount()
}

const validationRules = {
  percentage: {
    trigger: ['change', 'blur', 'focus'],
    validator() {
      if (
        formValue.value.discountValue > 99.99 ||
        formValue.value.discountValue < 0
      ) {
        return new Error(t('validations.invalid.discountPercent'))
      }

      const strNumber = formValue?.value?.discountValue?.toString() || ''
      if (strNumber && strNumber.length > 0) {
        const regex = /^\d+\.\d{3,}$/
        if (regex.test(strNumber)) {
          return new Error(
            t('validations.invalid.discountPercentTwoDecimalPoint')
          )
        }
      }
      return true
    },
  },
  customAmount: {
    trigger: ['change', 'blur', 'focus'],
    validator() {
      if (
        formValue.value.discountValue > subTotal.value ||
        formValue.value.discountValue < 0
      ) {
        return new Error(
          `${t('validations.invalid.amountLessThan')} ${formatCurrency(0)} ${t(
            'validations.invalid.amountMoreThan'
          )} ${formatCurrency(subTotal.value)}.`
        )
      }
      return true
    },
  },
}
</script>

<template>
  <UIModal :show="props.show">
    <template #header>
      <UIModalHeader
        id="discount-modal-header"
        class="border-b"
        :title="$t('common.addDiscount')"
        @close="cancelCallback"
      />
    </template>
    <UIModalContent>
      <div class="flex justify-end">
        <UITooltip>
          <template #trigger>
            <UIButton
              id="remove-discount-btn"
              :circle="true"
              :quaternary="true"
              @click="removeDiscount"
            >
              <Trash01Icon class="w-5 h-5" />
            </UIButton>
          </template>
          {{ $t('common.removeDiscount') }}
        </UITooltip>
      </div>
      <div class="flex">
        <UIRadio
          id="discount-type-radio-percentage"
          :checked="formValue.discountType === DiscountType.PERCENTAGE"
          :value="DiscountType.PERCENTAGE"
          class="mr-6"
          name="discount-type"
          @change="handleDiscountTypeChange"
        >
          {{ $t('common.percentage') }}
        </UIRadio>

        <UIRadio
          id="discount-type-radio-custom"
          :checked="formValue.discountType === DiscountType.CUSTOM_AMOUNT"
          :value="DiscountType.CUSTOM_AMOUNT"
          name="discount-type"
          @change="handleDiscountTypeChange"
        >
          {{ $t('common.customAmount') }}
        </UIRadio>
      </div>
      <div v-if="formValue.discountType === DiscountType.PERCENTAGE">
        <div class="flex pt-6 items-center">
          <div class="w-1/6">
            <UIFormItem :rule="validationRules.percentage" :showLabel="false">
              <UIInputNumber
                id="discount-percentage-input"
                :onUpdate:value="handleDiscountPercentageChange"
                :showButton="false"
                :value="formValue.discountValue"
                class="items-center"
                step="0.1"
                :precision="2"
              >
                <template #suffix>%</template>
              </UIInputNumber>
            </UIFormItem>
          </div>
          <div class="w-5/6 ml-4">
            <UIFormItem :showLabel="false">
              <UISlider
                id="discount-percentage-slider"
                :onUpdate:value="handleDiscountPercentageChange"
                :value="formValue.discountValue"
              />
            </UIFormItem>
          </div>
        </div>
      </div>
      <div v-if="formValue.discountType === DiscountType.CUSTOM_AMOUNT">
        <div class="pt-6">
          <UIFormItem :rule="validationRules.customAmount" :showLabel="false">
            <UIInputNumber
              id="discount-custom-amount-input"
              :onUpdate:value="handleDiscountCustomAmount"
              :showButton="false"
              :value="formValue.discountValue"
              class="items-center"
              step="0.1"
              :precision="2"
            >
              <template #prefix>{{
                getCurrencySymbol(productListStore.currency)
              }}</template>
            </UIInputNumber>
          </UIFormItem>
        </div>
      </div>
      <div class="flex pt-6">
        <UITextSmRegular class="text-gray-700">
          {{ $t('common.subtotalAfterDiscount') }}
          <span class="text-success-600">{{
            formatCurrency(discountedAmount, productCurrencySymbol)
          }}</span>
        </UITextSmRegular>
      </div>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="ui-modal-footer"
        :negativeText="$t('common.cancel')"
        :positiveText="$t('common.save')"
        @negativeClick="cancelCallback"
        @positiveClick="onSaveDiscount"
      />
    </template>
  </UIModal>
</template>
