<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue'
import { useEmitter } from '../../composable/useEmitter'
import MediaLibrary from './MediaLibrary.vue'
const emitter = useEmitter()
const active = ref(false)

emitter.on('open-media-picker', openMediaLibrary)
onBeforeUnmount(() => {
  emitter.off('open-media-picker', openMediaLibrary)
})

function openMediaLibrary(open) {
  console.info({ open })
  active.value = open
}

function mediaSelected(media) {
  console.info({ media })
  emitter.emit('media-library-selected', media)
  openMediaLibrary(false)
}
function cancelSelection() {
  openMediaLibrary(false)
}
</script>

<template>
  <div v-if="active" class="media-picker-container w-full">
    <media-library @onClose="cancelSelection" @onSelect="mediaSelected" />
  </div>
</template>

<style scoped>
.media-picker-container {
  position: absolute;
  top: 0;
  transition: top 0.5s ease-in;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  overflow: auto;
}
</style>
