<script lang="ts" setup>
import {
  UIModal,
  UIModalHeader,
  UIModalContent,
  UIModalFooter,
} from '@gohighlevel/ghl-ui'
import { CheckDone01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { ModalEvents, ModalProps } from '@gohighlevel/ghl-proposals-common'

const emits = defineEmits([ModalEvents.ON_SUCCESS, ModalEvents.ON_CANCEL])
const props = withDefaults(
  defineProps<ModalProps & { headerIcon: boolean }>(),
  {
    show: false,
    modalType: 'primary',
    closeOnSuccess: true,
    loading: false,
    disabled: false,
    headerIcon: true,
  }
)

const handleClose = () => {
  emits(ModalEvents.ON_CANCEL)
}
const handleSuccess = () => {
  emits(ModalEvents.ON_SUCCESS)
  if (props.closeOnSuccess) {
    handleClose()
  }
}
</script>

<template>
  <UIModal :show="props.show">
    <template #header>
      <UIModalHeader
        v-if="props.headerIcon"
        :type="props.modalType"
        :title="props.title"
        :description="props.description"
        :icon="CheckDone01Icon"
        @close="handleClose"
        id="modal-header"
      >
      </UIModalHeader>

      <UIModalHeader
        v-if="!props.headerIcon"
        :title="props.title"
        :type="props.modalType"
        @close="handleClose"
        id="modal-header"
      >
      </UIModalHeader>
    </template>

    <UIModalContent>
      <slot></slot>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        :loading="props.loading"
        :disabled="props.disabled"
        :type="props.modalType"
        :positiveText="props.successActionText"
        :negativeText="props.cancelActionText"
        @positiveClick="handleSuccess"
        @negativeClick="handleClose"
        id="modal-footer"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>
