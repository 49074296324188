import axios, { AxiosRequestConfig } from 'axios'

import { useAppStore } from '@/store/app'
import config from '../config'
import { setupAutoUpdateToken } from './setupToken'

export const getHeader = () => {
  return {
    channel: 'APP',
    source: 'WEB_USER',
    version: '2021-07-28',
  }
}

export const requests = (host?: string) => {
  const baseUrl = host || config.restApiUrl
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  })

  axiosInstance.interceptors.request.use(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    async (requestConfig: AxiosRequestConfig) => {
      try {
        const store = useAppStore()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        requestConfig.headers['token-id'] = store.tokenId
      } catch (e) {
        console.error(e)
      }
      return requestConfig
    }
  )
  setupAutoUpdateToken(axiosInstance)
  return axiosInstance
}
