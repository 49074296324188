<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'

import { UIDrawer, UIDrawerContent, UITextXsMedium } from '@gohighlevel/ghl-ui'
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import RecipientSelector from './RecipientSelector.vue'
import { computed, onMounted, watch } from 'vue'
import { useAppStore } from '@/store/app'
import ContactSearchPopOver from './ContactSearchPopOver.vue'
import RecipientsList from './RecipientsList.vue'
import { debounce } from '@/util'
import AddNewContactModel from './AddNewContactModel.vue'
import { useBuilderStore } from '@/store/builder'
import { Switch } from '@gohighlevel/ghl-proposals-common'

const appStore = useAppStore()
const store = useBuilderStore()
const storeAction = useActionBarStore()
const recipientDrawActive = computed(() => {
  return appStore.document?.recipients
})

const onClose = () => {
  storeAction.toggleRecipients()
}

const openRecipient = () => {
  storeAction.recipientsActive =
    recipientDrawActive?.value && recipientDrawActive?.value.length === 0
}

onMounted(() => {
  debounce(openRecipient, 1500)()
})

watch(recipientDrawActive, (newValue, oldVal) => {
  if (newValue) {
    if (JSON.stringify(oldVal) !== JSON.stringify(newValue)) {
      storeAction.recipientsActive = true
    }
  }
})
const onEnableSigningOrder = (value: boolean) => {
  appStore.toggleSigningOrder(value)
}
</script>

<template>
  <UIDrawer
    :trap-focus="false"
    :width="340"
    id="elements-drawer"
    @update:show="onClose"
    :show="storeAction.recipientsActive"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-[calc(100vh-99px)]"
  >
    <UIDrawerContent
      id="recipients-drawer-content"
      :title="$t('common.recipients')"
      :block-scroll="true"
    >
      <Switch
        :value="appStore.document.enableSigningOrder as boolean"
        class="!appStore.document.enableSigningOrder ? '!mb-6' : ''"
        :label="$t('common.setSigningOrder')"
        :infoLabel="$t('common.setSigningOrderDescription')"
        @update:value="onEnableSigningOrder"
        :disabled="!store.isDocumentEditable"
      />
      <div
        v-if="appStore.document.enableSigningOrder"
        class="p-2 mb-6 border border-primary-400 rounded-sm bg-primary-50"
      >
        <UITextXsMedium class="items-center flex text-primary-400"
          ><InfoCircleIcon class="h-4 w-4 mr-5" />Drag to change
          order</UITextXsMedium
        >
      </div>
      <RecipientSelector />

      <AddNewContactModel />
      <div class="-mx-6 max-h-[46vh]">
        <div
          class="overflow-y-auto max-h-[inherit]"
          :class="{
            'border-t border-b': appStore.document?.recipients?.length > 1,
          }"
        >
          <RecipientsList />
        </div>

        <ContactSearchPopOver />
      </div>
    </UIDrawerContent>
  </UIDrawer>
</template>

<style scoped>
/* .proposal-pages-scroll {
  height: calc(100vh - 160px);
  overflow-y: auto;
} */

.n-drawer-body-content-wrapper {
  position: relative;
}
</style>
