<script setup lang="ts">
import { UIButton } from '@gohighlevel/ghl-ui'
import { PropType } from 'vue'
import {
  IRecipient,
  APP_STORE_META,
  upgradeRecipientToPrimarySigner,
} from '@gohighlevel/ghl-proposals-common'
import { Trash01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { useAppStore } from '@/store/app'
import { useElementOption } from '@/composable/elementOptions'
import { useBuilderStore } from '@/store/builder'
import UserAvatar from '@/components/common/UserAvatar.vue'
import { useEmitter } from '@/composable/useEmitter'

const { updateDocumentOption } = useElementOption()
const store = useBuilderStore()
const appStore = useAppStore()
const emitter = useEmitter()

const props = defineProps({
  recipient: { type: Object as PropType<IRecipient> },
  delete: { type: Boolean, default: false },
})

const deleteRecipient = (recipientId: string) => {
  if (recipientId) {
    let recipients = appStore.document?.recipients?.filter((el: any) => {
      return el.id !== recipientId
    })

    const primaryRecipient = recipients?.find((el: any) => {
      return el.isPrimary === true
    })

    // Next in-line signer becomes the primary client if the deleted recipient is the primary client
    if (recipients.length > 0 && !primaryRecipient) {
      recipients = updateNewPrimaryRecipient(recipients)
    }
    updateDocumentOption(APP_STORE_META.RECIPIENTS, recipients)
  }
}

/**
 * Calculates the next primary client if the current primary client is not present in recipient list
 * @param recipients
 */
const updateNewPrimaryRecipient = (recipients: IRecipient[]): IRecipient[] => {
  const candidateRecipients = recipients.filter((el: any) => {
    return el.entityName === 'contacts'
  })

  if (candidateRecipients.length > 0) {
    // Upgrade the first candidate recipient to primary
    const upgradedRecipient = upgradeRecipientToPrimarySigner(
      candidateRecipients[0]
    )

    const index = recipients.indexOf(candidateRecipients[0])
    if (index > -1) {
      // Update the recipient at the found index
      recipients[index] = upgradedRecipient

      // Add the new primary client to the recipients list being shown under primary client dropdown as well
      emitter.emit('contact-added', upgradedRecipient)
    }
  }

  return recipients
}
</script>

<template>
  <div class="flex flex-col relative" v-if="recipient?.id">
    <UserAvatar :recipient="recipient" :show-recipient-tag="true" />
    <div
      class="flex-none absolute right-0 top-3"
      v-if="props.delete && store.isDocumentEditable"
    >
      <UIButton
        :id="`btn_custom_${recipient?.id}`"
        @click="deleteRecipient(recipient?.id)"
        :circle="true"
        :quaternary="true"
      >
        <Trash01Icon class="w-4 h-4 cursor-pointer"
      /></UIButton>
    </div>
  </div>
</template>
