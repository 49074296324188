<script setup lang="ts">
import { UIButton, UIDivider, UISelect, UIFormItem } from '@gohighlevel/ghl-ui'
import {
  groupRuleOptions,
  groupRuleValueOptions,
  GROUP_META,
  renderLabelAvatarOnSelect,
} from '@gohighlevel/ghl-proposals-common'
import {
  LayersThree02Icon,
  LayerSingleIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import RecipientCard from '@/components/drawer/DrawerRecipients/RecipientCard.vue'

import { useGroups, useRecipients } from '@/composition'
import { onMounted, computed } from 'vue'
import { useBuilderStore } from '@/store/builder'

const { syncRecipient } = useBuilderStore()

const { groupTargets, unGroupTargets, groupExist, updateGroupOptions } =
  useGroups()
const {
  signedByOptions,
  assignRecipientToGroup,
  fetchRecipientForElementOrGroup,
  getSelectValue,
} = useRecipients()

const selectValue = computed(() => {
  if (groupExist.value) return getSelectValue(groupExist.value)
  return null
})

const recipient = computed(() => {
  if (groupExist.value) return fetchRecipientForElementOrGroup(groupExist.value)
  return null
})

const assignRecipientToCheckBoxGroup = (val: string, groupId: string) => {
  if (val && groupId) {
    assignRecipientToGroup(val, groupId)
    syncRecipientList()
  }
}

const syncRecipientList = () => {
  syncRecipient()
}

onMounted(() => {
  syncRecipientList()
})
</script>

<template>
  <div class="hl-proposal-sidebar-content hl-proposal-checkbox-properties">
    <div class="my-6">
      <div v-if="groupExist">
        <UIButton
          @click="unGroupTargets"
          :class="'group-field-button'"
          :id="`grouping`"
          :type="'default'"
          :size="`large`"
          :disabled="false"
          :loading="false"
          :ghost="false"
          :quaternary="false"
          :circle="false"
          :text="false"
        >
          <LayerSingleIcon class="w-4 h-4 mr-2" />
          Ungroup checkbox
        </UIButton>
      </div>

      <div v-else>
        <UIButton
          @click="groupTargets"
          :class="'group-field-button'"
          :id="`grouping`"
          :type="'default'"
          :size="`large`"
          :disabled="false"
          :loading="false"
          :ghost="false"
          :quaternary="false"
          :circle="false"
          :text="false"
        >
          <LayersThree02Icon class="w-4 h-4 mr-2" />
          Group checkbox
        </UIButton>
      </div>
    </div>
    <div class="my-6" v-if="groupExist">
      <UIDivider />
      <div class="mb-4">
        <UIFormItem :label="$t('common.toBeFilledBy')">
          <UISelect
            :id="`checkbox-group-text`"
            :placeholder="$t('common.assignRecipientToCheckbox')"
            :options="signedByOptions"
            :value="selectValue"
            :render-label="renderLabelAvatarOnSelect"
            @update:value="(val: any) => assignRecipientToCheckBoxGroup(val, groupExist.id)"
          />
        </UIFormItem>
        <div>
          <RecipientCard :recipient="recipient" />
        </div>
      </div>
      <UIDivider />
      <label class="mt-5">Required checkboxes</label>
      <div class="flex space-x-4">
        <div class="w-2/3">
          <UISelect
            :id="`${groupExist?.id}-checkbox-group-rule-name`"
            :options="groupRuleOptions"
            :type="'default'"
            :resetMenuOnOptionsChange="true"
            :size="'large'"
            :maxTagCount="undefined"
            :value="groupExist.component.options.rule"
            @update:value="
              val => updateGroupOptions(GROUP_META.RULE, val, groupExist?.id)
            "
          />
        </div>
        <div class="w-1/3">
          <UISelect
            :id="`${groupExist?.id}-checkbox-group-rule-value`"
            :options="groupRuleValueOptions(groupExist)"
            :type="'default'"
            :resetMenuOnOptionsChange="true"
            :size="'large'"
            :value="groupExist.component.options.ruleValue"
            @update:value="
              val =>
                updateGroupOptions(GROUP_META.RULE_VALUE, val, groupExist?.id)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.group-field-button {
  width: 100%;
  border: 1px solid #eff4ff;
}
.hl-proposal-checkbox-properties {
  .n-divider.n-divider--no-title {
    margin: 20px 0;
  }
}
</style>
