<script setup lang="ts">
import { useVideo, SupportedVideoProviders } from '@/composable/video'
import { UIFormItem, UIInput, UITextXsMedium } from '@gohighlevel/ghl-ui'
import { PropType, ref, watch } from 'vue'
import {
  ELEMENTS_META,
  ELEMENTS_STYLES,
  IElement,
  UIBuilderSpacing,
  getMarginProperties,
  getPaddingProperties,
  onChangeMarginPadding,
} from '@gohighlevel/ghl-proposals-common'
import { AlertHexagonIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useElementOption } from '../../../composable/elementOptions.js'
import ColorPickerComponent from '../../common/ColorPickerComponent.vue'

const props = defineProps({
  element: Object as PropType<IElement>,
})
const { updateOption, updateStyles } = useElementOption()

const bgColor = ref(props.element?.responsiveStyles?.large?.backgroundColor)
const margin = ref(getMarginProperties(props.element))
const padding = ref(getPaddingProperties(props.element))

watch(props, _newValue => {
  if (props.element) {
    bgColor.value = props.element?.responsiveStyles?.large?.backgroundColor
    margin.value = getMarginProperties(props.element)
    padding.value = getPaddingProperties(props.element)
  }
})

const { generateVideoIframe } = useVideo()
const errorMessage = ref<string | null>(null)
async function onChangeURL(val: string) {
  errorMessage.value = null
  const url = val
  let embedHtml = ''
  if (val) {
    const { error, html, message } = await generateVideoIframe(val)
    if (error) {
      embedHtml = ''
      errorMessage.value = message as string
    } else {
      embedHtml = html as string
    }
  }
  updateOption(ELEMENTS_META.SRC, url)
  updateOption(ELEMENTS_META.HTML, embedHtml)
}
const onBoxStyleChange = onChangeMarginPadding(updateStyles)
</script>

<template>
  <div class="hl-proposal-sidebar-content">
    <div class="p-2 mb-6 border border-primary-400 rounded-sm bg-primary-50">
      <UITextXsMedium class="items-center flex text-primary-400"
        >Supported Providers :
      </UITextXsMedium>
      <UITextXsMedium class="items-center flex text-primary-400 font-bold mt-2"
        >{{ SupportedVideoProviders.join(', ') }}
      </UITextXsMedium>
    </div>
    <div
      v-if="errorMessage"
      class="p-2 mb-6 border border-red-400 rounded-sm bg-red-50"
    >
      <UITextXsMedium class="items-center flex text-red-400"
        ><AlertHexagonIcon class="h-4 w-4 mr-5" />{{
          errorMessage
        }}</UITextXsMedium
      >
    </div>

    <UIFormItem
      :label="$t('common.videoUrl')"
      :feedback="$t('common.videoPropertiesDescription')"
    >
      <UIInput
        :placeholder="$t('common.pasteVideoUrl')"
        id="video-src"
        :modelValue="element?.component.options?.src"
        @update:model-value="onChangeURL"
      />
    </UIFormItem>
    <br />
    <ColorPickerComponent
      v-model="bgColor"
      :label="$t('common.backgroundColor')"
      @update:modelValue="
        updateStyles(
          ELEMENTS_STYLES.BACKGROUND_COLOR,
          bgColor,
          props.element?.id,
          props.element?.type
        )
      "
    />
    <!-- <UIFormItem label="Align">
      <UIRadioGroup
        :value="element?.responsiveStyles.large.align"
        @update:value="val => updateStyles(ELEMENTS_STYLES.ALIGN, val)"
      >
        <UISpace>
          <UIRadioGroupItem value="!mr-auto">
            <template #content> Left</template>
          </UIRadioGroupItem>
          <UIRadioGroupItem value="!mx-auto">
            <template #content> Center</template>
          </UIRadioGroupItem>
          <UIRadioGroupItem value="!ml-auto">
            <template #content>Right</template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
    </UIFormItem> -->
    <UIFormItem :label="$t('common.height')">
      <UIInput
        id="video-height"
        :modelValue="element?.responsiveStyles.large.height"
        placeholder=""
        @update:modelValue="val => updateStyles(ELEMENTS_STYLES.HEIGHT, val)"
      >
        <template #suffix>px</template>
      </UIInput>
    </UIFormItem>
    <UIFormItem :label="$t('common.width')">
      <UIInput
        id="video-width"
        :model-value="element?.responsiveStyles.large.width"
        placeholder=""
        @update:model-value="val => updateStyles(ELEMENTS_STYLES.WIDTH, val)"
      >
        <template #suffix>px</template>
      </UIInput>
    </UIFormItem>
    <UIBuilderSpacing :element="props.element" @onUpdate="onBoxStyleChange" />
  </div>
</template>

<style lang="scss"></style>
