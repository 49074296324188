import { StarterKit } from '@tiptap/starter-kit'
import { Editor } from '@tiptap/vue-3'

export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  AGENCY: 'agency',
  ACCOUNT: 'account',
}

export const DOC_VAR_PREFIX = `document`

const editor = new Editor({
  content: '',
  extensions: [StarterKit],
  onUpdate({ editor }) {
    console.info('ed: ', editor.getJSON())
  },
})

export { editor }
