import { TaxService } from '@/service/TaxService'
import { ITax } from '@gohighlevel/ghl-proposals-common'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export const useTaxes = () => {
  const loading = ref(false)
  const route = useRoute()

  const fetchTaxes = async (): Promise<ITax[]> => {
    try {
      loading.value = true
      const locationId = route.params.locationId as string
      const { data } = await TaxService.fetchTaxes({
        altId: locationId,
        altType: 'location',
      })
      return data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      loading.value = false
    }
  }
  return {
    loading,
    fetchTaxes,
  }
}
