import { ContactService } from '@/service/ContactService'
import { CreateContactParams } from '@gohighlevel/ghl-proposals-common'
import { AxiosError } from 'axios'
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useNotify } from './useNotify'

export const useContact = () => {
  const loading = ref(false)
  const route = useRoute()
  const { handleError } = useNotify()

  const searchContacts = async (searchStr: string, limit = 20, pageNum = 1) => {
    try {
      loading.value = true
      const { data } = await ContactService.search({
        locationId: route.params.locationId as string,
        page: pageNum,
        filters: [
          {
            id: uuid(),
            filterName: 'generic',
            filterName_lc: 'generic',
            selectedOption: {
              filterName: 'generic',
              filterName_lc: 'generic',
              condition: 'is',
              firstValue: searchStr,
              secondValue: 'IN',
            },
          },
        ],
        sort: [],
        pageLimit: limit,
        justIds: false,
      })
      return data
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const createContact = async (contactParams: CreateContactParams) => {
    try {
      loading.value = true
      const { data } = await ContactService.createContact(contactParams)
      loading.value = false
      return data
    } catch (error: any) {
      console.error(error)
      handleError(error as AxiosError)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    searchContacts,
    createContact,
  }
}
