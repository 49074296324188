<script setup lang="ts">
import {
  IElement,
  ELEMENTS_STYLES,
  UIBuilderSpacing,
  getMarginProperties,
  getPaddingProperties,
  isValidHandleBars,
  onChangeMarginPadding,
  validateHandlebarVariableExistInCustomValues,
  useCustomValues,
} from '@gohighlevel/ghl-proposals-common'
import { UIAlert } from '@gohighlevel/ghl-ui'
import { PropType, onMounted, ref, watch, computed } from 'vue'
import { useElementOption } from '../../../composable/elementOptions'
import ColorPickerComponent from '../../common/ColorPickerComponent.vue'
import { useAppStore } from '@/store/app'
import { useI18n } from 'vue-i18n'
import { useBuilderStore } from '@/store/builder'

const { t } = useI18n()

const appStore = useAppStore()
const builderStore = useBuilderStore()
const props = defineProps({
  element: Object as PropType<IElement>,
})
const { updateStyles } = useElementOption()
const {
  locationStaticCustomValues,
  locationCustomValues,
  locationCustomFields,
} = useCustomValues()
const bgColor = ref(props.element?.responsiveStyles?.large?.backgroundColor)
const margin = ref(getMarginProperties(props.element))
const padding = ref(getPaddingProperties(props.element))
const errorMessage = ref()

watch(props, _newValue => {
  if (props.element) {
    bgColor.value = props.element?.responsiveStyles?.large?.backgroundColor
    margin.value = getMarginProperties(props.element)
    padding.value = getPaddingProperties(props.element)
  }
  validateCustomVariables()
})

onMounted(() => {
  validateCustomVariables()
})

const allDocumentVariables = computed(() => [
  ...(appStore.document?.variables || []),
  ...builderStore.getAllLinkedDocumentVariables(),
])

const validateCustomVariables = () => {
  if (props?.element?.component?.options?.text) {
    // regex check for handlebar braces
    const isValid = isValidHandleBars(props?.element?.component?.options?.text)

    // check the custom values are actually there in the constants.
    const errorCustomValues = validateHandlebarVariableExistInCustomValues(
      props?.element?.component?.options?.text,
      locationStaticCustomValues.value,
      locationCustomValues.value,
      locationCustomFields.value,
      allDocumentVariables.value
    )
    const isCustomValid: boolean =
      errorCustomValues && errorCustomValues.length > 0 ? true : false
    if (isCustomValid || !isValid) {
      if (isCustomValid) {
        errorMessage.value = errorMessage.value = `${t(
          'validations.customVariables.validPart1'
        )}  ${errorCustomValues.toString()} ${t(
          'validations.customVariables.validPart2'
        )}`
      }
      if (!isValid) {
        errorMessage.value = t('validations.customVariables.issueMsg')
      }
    } else {
      errorMessage.value = ''
    }
  }
}
const onBoxStyleChange = onChangeMarginPadding(updateStyles)
</script>

<template>
  <div class="my-2">
    <UIAlert
      id="estimate-text-style-warning"
      title="Warning"
      type="warning"
      v-if="errorMessage"
    >
      <template #content>{{ errorMessage }}</template></UIAlert
    >
  </div>
  <div class="hl-proposal-sidebar-content">
    <ColorPickerComponent
      class="color-picker-container-1"
      v-model="bgColor"
      :label="$t('common.backgroundColor')"
      @update:modelValue="
        updateStyles(
          ELEMENTS_STYLES.BACKGROUND_COLOR,
          bgColor,
          props.element?.id,
          props.element?.type
        )
      "
    />
    <UIBuilderSpacing :element="props.element" @onUpdate="onBoxStyleChange" />
  </div>
</template>

<style lang="scss"></style>
