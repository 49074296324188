<script lang="ts" setup>
import {
  UICheckbox,
  UIForm,
  UIFormItem,
  UIInput,
  UIInputNumber,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UISelect,
} from '@gohighlevel/ghl-ui'
import { PlusIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  CommonModalProps,
  ProductLineEvents,
  ProductTypeOptions,
  RichTextInputField,
} from '@gohighlevel/ghl-proposals-common'
import { useCurrency, useProducts } from '@/composition'
import { onMounted, ref } from 'vue'
import { useProductListStore } from '@/store/productListStore'
import { usePayment } from '@/composition/usePayment'
import { useEmitter } from '@/composable/useEmitter'
import { useBuilderStore } from '@/store/builder'

const builderStore = useBuilderStore()
const productListStore = useProductListStore()
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { createNewProduct, loading } = useProducts()
const { getCurrencyDetails } = usePayment()
const { getCurrency, getCurrencySymbol } = useCurrency()
const emit = defineEmits([
  ProductLineEvents.CLOSE_ADD_PRODUCT_MODAL,
  ProductLineEvents.OPEN_ADD_PRODUCT_MODAL,
  ProductLineEvents.ADD_NEW_PRODUCT_TO_PRODUCTS_LIST,
])

const eventBus = useEmitter()
const currencyOptions = ref<any>([])
const disableCurrencyDropdown = ref<boolean>(false)
const saveForLater = ref(false)
const formRef = ref()
const props = withDefaults(defineProps<CommonModalProps>(), { show: false })
const formValue = ref({
  name: '',
  productType: '',
  priceName: '',
  amount: '' as unknown as number,
  currency: getCurrency(),
  description: '',
})

const resetFormValue = () => {
  formValue.value.name = ''
  formValue.value.productType = ''
  formValue.value.priceName = ''
  formValue.value.amount = '' as unknown as number
  formValue.value.currency = getCurrency()
  formValue.value.description = ''
}

const cancelCallback = () => {
  emit(ProductLineEvents.CLOSE_ADD_PRODUCT_MODAL)
  resetFormValue()
}

const createPayload = () => {
  const { name, priceName, amount, productType, currency, description } =
    formValue.value
  return {
    name,
    productType,
    description,
    taxCode: null,
    prices: [
      {
        name: priceName,
        type: 'one_time',
        amount: amount === ('' as unknown as number) ? 0 : amount,
        currency,
      },
    ],
  }
}

const handleFormSubmit = async () => {
  try {
    await formRef.value.getForm().validate()
    const payload = createPayload()
    if (saveForLater.value) {
      const response = await createNewProduct(payload)
      emit(ProductLineEvents.ADD_NEW_PRODUCT_TO_PRODUCTS_LIST, response)
    } else {
      emit(ProductLineEvents.ADD_NEW_PRODUCT_TO_PRODUCTS_LIST, payload)
    }
    emit(ProductLineEvents.CLOSE_ADD_PRODUCT_MODAL)
    resetFormValue()
  } catch (error) {
    console.error(error)
  }
}

const validationRules = {
  name: {
    required: true,
    trigger: 'blur',
    message: t('validations.required.productName'),
  },
  productType: {
    required: true,
    trigger: 'blur',
    message: t('validations.required.productType'),
  },
  priceName: {
    required: true,
    trigger: 'blur',
    message: t('validations.required.priceName'),
  },
  amount: {
    required: true,
    trigger: ['blur', 'input'],
    validator() {
      if (formValue.value.amount < 0) {
        return new Error(t('validations.invalid.validAmount'))
      }
    },
  },
  currency: {
    required: true,
    trigger: 'blur',
    message: 'Please select a currency.',
  },
}

const setAllowChoosingCurrency = () => {
  if (productListStore.currency) {
    formValue.value.currency = productListStore.currency
    disableCurrencyDropdown.value = true
  } else {
    disableCurrencyDropdown.value = false
  }
}

const setCurrencyOptions = async () => {
  const options = productListStore?.currencyOptions || []
  if (options && options.length > 0) {
    currencyOptions.value = productListStore?.currencyOptions
  } else {
    await getCurrencyDetails()
    currencyOptions.value = productListStore?.currencyOptions
  }
}

onMounted(() => {
  eventBus.on(ProductLineEvents.OPEN_ADD_PRODUCT_MODAL, () => {
    setCurrencyOptions()
    setAllowChoosingCurrency()
  })
})
const onDescriptionChange = (value: string) => {
  formValue.value.description = value
}
const amountChange = (value: number) => {
  if (value === 0) {
    saveForLater.value = false
  }
  formValue.value.amount = value
}
</script>

<template>
  <UIModal :show="props.show" :width="920" className="add-new-product-modal">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :icon="PlusIcon"
        :title="$t('common.newItem')"
        @close="cancelCallback"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIForm
        id="add-new-product-form"
        ref="formRef"
        :model="formValue"
        :rules="validationRules"
      >
        <UIFormItem :label="$t('common.productName')" path="name" required>
          <UIInput
            id="product-name"
            v-model="formValue.name"
            :placeholder="$t('common.inputName')"
          />
        </UIFormItem>
        <UIFormItem label="Description" path="description">
          <RichTextInputField
            minHeight="300px"
            :editable="builderStore.isDocumentEditable"
            :value="formValue.description"
            @on-change="onDescriptionChange"
            to=".add-new-product-modal"
          />
        </UIFormItem>
        <UIFormItem
          :label="$t('common.productType')"
          path="productType"
          required
        >
          <UISelect
            id="product-drop-down"
            v-model:value="formValue.productType"
            :options="ProductTypeOptions"
            :placeholder="$t('common.selectProductType')"
          />
        </UIFormItem>
        <div class="flex">
          <div class="w-2/5 pr-4">
            <UIFormItem
              :label="$t('common.priceName')"
              path="priceName"
              required
            >
              <UIInput
                id="price-name"
                v-model="formValue.priceName"
                :placeholder="$t('common.priceName')"
              />
            </UIFormItem>
          </div>
          <div class="w-2/5 pr-4">
            <UIFormItem :label="$t('common.amount')" path="amount" required>
              <UIInputNumber
                id="new-product-amount"
                :model-value="formValue.amount"
                @update:model-value="amountChange"
                :showButton="false"
                size="large"
                :min="0"
              >
                <template #prefix>{{
                  getCurrencySymbol(formValue.currency)
                }}</template>
              </UIInputNumber>
            </UIFormItem>
          </div>
          <div class="w-1/5">
            <UIFormItem :label="$t('common.currency')" path="currency" required>
              <UISelect
                id="change-product-currency"
                v-model:value="formValue.currency"
                :options="currencyOptions"
                :disabled="disableCurrencyDropdown"
                :placeholder="$t('common.selectCurrency')"
              />
            </UIFormItem>
          </div>
        </div>
      </UIForm>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :disabled="loading"
        :loading="loading"
        :negativeText="$t('common.cancel')"
        :positiveText="$t('common.addNewItem')"
        @negativeClick="cancelCallback"
        @positiveClick="handleFormSubmit"
      >
        <template #actionLeft>
          <UICheckbox
            id="modal-form-checkout"
            v-model:checked="saveForLater"
            :disabled="formValue.amount <= 0"
          >
            {{ $t('common.saveForLaterUse') }}
          </UICheckbox>
        </template>
      </UIModalFooter>
    </template>
  </UIModal>
</template>
