import {
  ELEMENTS_META,
  IComponent,
  LineItem,
  ProductListElementOptions,
} from '@gohighlevel/ghl-proposals-common'

import { useBuilderStore } from '@/store/builder'
import { useElementOption } from './elementOptions'
import { useProductListStore } from '@/store/productListStore'

export const useProductListActions = () => {
  const productListStore = useProductListStore()
  const builderStore = useBuilderStore()
  const { updateOption } = useElementOption()

  const getSelectableOptions = (key: keyof LineItem, value: boolean) => {
    if (key === 'optional') {
      if (value) {
        return { disabled: false }
      } else {
        return { disabled: true, selected: true }
      }
    }
    return {}
  }
  const updateSelectedLineItem = (
    key: keyof LineItem,
    value: LineItem[keyof LineItem]
  ) => {
    const elementOptions = builderStore.activeElement
    const updatedLineItems = (
      elementOptions?.component as IComponent<ProductListElementOptions>
    )?.options?.lineItems?.map((item: LineItem) => {
      if (item.key === productListStore.activeRow?.key) {
        return {
          ...item,
          [key]: value,
          ...getSelectableOptions(key, value as boolean),
        }
      }
      return { ...item }
    })
    updateOption(
      ELEMENTS_META.LINE_ITEMS,
      updatedLineItems,
      builderStore?.activeElementId as string
    )
  }
  return { updateSelectedLineItem }
}
