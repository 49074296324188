<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { UIButton, UIDropdown, renderIcon } from '@gohighlevel/ghl-ui'
import { Link02Icon, Mail01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { useDocument } from '@/composition'
import { ref } from 'vue'
import {
  DefaultValidationObjectForDocument,
  DocumentStatus,
  SentMedium,
} from '@gohighlevel/ghl-proposals-common'
import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'
import LinkGenerationModal from './LinkGenerationModal.vue'
import { useEmitter } from '@/composable/useEmitter'
import DocumentValidationModal from '@/components/modals/DocumentValidationModal.vue'

const { t } = useI18n()
const sendingRef = ref(false)
const appStore = useAppStore()
const builderStore = useBuilderStore()
const emitter = useEmitter()
const showErrorModal = ref(false)
const validations = ref(DefaultValidationObjectForDocument)
const isAllValid = ref(false)
const sendMediumDropDown = ref(false)
const { loading, saveDocument, validateDocument, sendDocument } = useDocument()

const toggleModal = () => {
  showErrorModal.value = !showErrorModal.value
}

const onClickSendButton = () => {
  validations.value = validateDocument()
  isAllValid.value = Object.values(validations.value).every(val => val === true)
  if (!isAllValid.value) {
    toggleModal()
  }

  onClickOutsideSendDropDown()
}

const onClickOutsideSendDropDown = () => {
  if (isAllValid.value) {
    sendMediumDropDown.value = !sendMediumDropDown.value
  }
}

const sendDocumentViaEmail = async () => {
  try {
    sendingRef.value = true
    await saveDocument()
    await sendDocument(SentMedium.EMAIL)
    showErrorModal.value = false
  } catch (error) {
    console.error(error)
  } finally {
    sendingRef.value = false
  }
}

const options = [
  {
    label: t('common.sendViaEmail'),
    key: 'send_via_email',
    icon: renderIcon(Mail01Icon),
    disabled: sendingRef.value,
  },
  {
    label: t('common.shareViaLink'),
    key: 'share_via_link',
    icon: renderIcon(Link02Icon),
    disabled: sendingRef.value,
  },
]

const onSelect = (value: string) => {
  if (value === 'send_via_email') {
    sendDocumentViaEmail()
  }

  if (value === 'share_via_link') {
    emitter.emit('open-link-generation-model')
  }

  onClickOutsideSendDropDown()
}
</script>

<template>
  <DocumentValidationModal :show="showErrorModal" @on-cancel="toggleModal" />
  <LinkGenerationModal />
  <div class="flex gap-4">
    <div class="relative" v-if="builderStore.isDocumentEditable">
      <UIButton
        :loading="loading"
        :disabled="loading"
        ghost
        type="default"
        @click="saveDocument"
        id="save-document-btn"
      >
        {{ $t('common.save') }}</UIButton
      >
      <span
        class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1"
        v-if="appStore.unsavedChanges"
      >
        <span
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-warning-400 opacity-75"
        ></span>
        <span
          class="relative inline-flex rounded-full h-3 w-3 bg-warning-500"
        ></span>
      </span>
    </div>

    <UIDropdown
      :show="sendMediumDropDown"
      :options="options"
      @select="onSelect"
      id="send-document-dropdown"
      :size="'medium'"
      @clickoutside="onClickOutsideSendDropDown"
    >
      <UIButton
        :loading="sendingRef"
        :disabled="sendingRef"
        ghost
        id="send-document-btn"
        class="px-5"
        type="primary"
        :onClick="onClickSendButton"
        >{{
          appStore.document.status !== DocumentStatus.DRAFT
            ? $t('common.resend')
            : $t('common.send')
        }}</UIButton
      >
    </UIDropdown>
  </div>
</template>

<style lang="scss">
.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.recipient-link {
  .n-divider.n-divider--no-title {
    margin-bottom: 0;
  }
}
</style>
