<script setup lang="ts">
import {
  IElement,
  IRecipient,
  RecipientEntityTypeEnum,
  APP_STORE_META,
  ELEMENTS_META,
  ELEMENTS_STYLES,
  useTypography,
  renderLabelAvatarOnSelect,
  UIBuilderSpacing,
  getMarginProperties,
  getPaddingProperties,
  getRecipientParamsFromContact,
  onChangeMarginPadding,
  SignatureElementOptions,
} from '@gohighlevel/ghl-proposals-common'
import {
  UIFormItem,
  UIInput,
  UIRadioGroup,
  UIRadioGroupItem,
  UISpace,
  UISelect,
  UICheckbox,
} from '@gohighlevel/ghl-ui'
import { PropType, computed, onMounted, ref, toRaw, watch } from 'vue'
import { useElementOption } from '../../../composable/elementOptions.js'
import { useAppStore } from '@/store/app'
import RecipientCard from '@/components/drawer/DrawerRecipients/RecipientCard.vue'
import { useBuilderStore } from '@/store/builder'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  element: {
    type: Object as PropType<IElement<SignatureElementOptions>>,
    required: true,
  },
})

const { updateOption, updateStyles } = useElementOption()
const { updateDocumentOption } = useElementOption()
const { syncRecipient } = useBuilderStore()
const { toCapitalize } = useTypography()

const recipients = computed(() => appStore.document?.recipients)
const appStore = useAppStore()
const recipient = ref()
const selectValue = ref()
const margin = ref(getMarginProperties(props.element))
const padding = ref(getPaddingProperties(props.element))

onMounted(() => {
  syncRecipientList()
})

const syncRecipientList = () => {
  syncRecipient()
  fetchRecipientData()
  getSelectValue()
}

const signedByOptions = computed(() => {
  let allRecipients = recipients.value
  const currentUser = getCurrantLoggedInUserDetails()
  const currentUserExist = allRecipients.find(elem => {
    return (
      elem.id === currentUser.id &&
      elem.entityName === RecipientEntityTypeEnum.USERS
    )
  })
  if (!currentUserExist) {
    allRecipients = [...allRecipients, currentUser]
  }
  const recipientList =
    allRecipients.map((contact: IRecipient) => ({
      ...contact,
      label: toCapitalize(
        appStore.userId === contact.id
          ? `${contact?.contactName || ''} ${t('common.you')}`
          : contact?.contactName || `${contact.firstName} ${contact.lastName}`
      ).trim(),
      value: `${contact.id}_${contact.entityName}`,
    })) || []
  return recipientList
})

const getSelectValue = () => {
  const recipientsList = toRaw(recipients.value)
  const lastSelectedId =
    props.element?.component.options[
      ELEMENTS_META.RECIPIENT as keyof SignatureElementOptions
    ]

  const userStillExistsInRecipients = recipientsList.find((el: any) => {
    return el.id === lastSelectedId
  })

  if (lastSelectedId && !userStillExistsInRecipients) {
    selectValue.value = null
    return
  }

  if (
    props.element?.component.options[
      ELEMENTS_META.RECIPIENT as keyof SignatureElementOptions
    ] &&
    props.element?.component.options[
      ELEMENTS_META.ENTITY_NAME as keyof SignatureElementOptions
    ]
  ) {
    selectValue.value = `${
      props.element?.component.options[
        ELEMENTS_META.RECIPIENT as keyof SignatureElementOptions
      ]
    }_${
      props.element?.component.options[
        ELEMENTS_META.ENTITY_NAME as keyof SignatureElementOptions
      ]
    }`
  }
}

const getCurrantLoggedInUserDetails = () => {
  const contactDetails = getRecipientParamsFromContact(
    appStore.userData,
    RecipientEntityTypeEnum.USERS,
    false
  )

  return {
    ...contactDetails,
    contactName: `${contactDetails.firstName} ${contactDetails.lastName}`,
  }
}

const fetchRecipientData = (recipientId?: string, entityName?: string) => {
  const assignedRecipient = recipients.value.find((elem: IRecipient) => {
    if (recipientId && entityName) {
      return elem.id === recipientId && elem.entityName === entityName
    } else {
      return (
        elem.id ===
          props.element?.component.options[
            ELEMENTS_META.RECIPIENT as keyof SignatureElementOptions
          ] &&
        elem.entityName ===
          props.element?.component.options[
            ELEMENTS_META.ENTITY_NAME as keyof SignatureElementOptions
          ]
      )
    }
  })
  if (assignedRecipient) {
    recipient.value = assignedRecipient
  }
}

const assignRecipientToSignature = (val: string) => {
  const recipientData = val.split('_')
  if (recipientData.length === 2) {
    const recipientId = recipientData[0]
    const entityName = recipientData[1]
    if (
      entityName === RecipientEntityTypeEnum.USERS &&
      appStore.userId === recipientId
    ) {
      const duplicateUsers = recipients.value.find((el: any) => {
        return el.id === appStore.userId && el.entityName === entityName
      })
      if (!duplicateUsers) {
        const contactDetails = getCurrantLoggedInUserDetails()
        const allRecipients = [...recipients.value, contactDetails]
        updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
      }
    }
    fetchRecipientData(recipientId, entityName)
    updateOption(ELEMENTS_META.RECIPIENT, recipientId)
    updateOption(ELEMENTS_META.ENTITY_NAME, entityName)
  }

  syncRecipientList()
}

watch(props, newValue => {
  if (newValue) {
    syncRecipientList()
  }
  if (props.element) {
    margin.value = getMarginProperties(props.element)
    padding.value = getPaddingProperties(props.element)
  }
})
const onBoxStyleChange = onChangeMarginPadding(updateStyles)
</script>

<template>
  <div class="hl-proposal-sidebar-content">
    <div class="mb-4">
      <UIFormItem :label="$t('common.toBeSignedBy')">
        <UISelect
          :id="`signature-text`"
          :placeholder="$t('common.assignRecipientToSignature')"
          :options="signedByOptions"
          :value="selectValue"
          @update:value="(val: any) => assignRecipientToSignature(val)"
          :render-label="renderLabelAvatarOnSelect"
        />
      </UIFormItem>
      <div class="border-b">
        <RecipientCard :recipient="recipient" />
      </div>
    </div>
    <UIFormItem :label="$t('common.placeholder')">
      <UIInput
        id="signature-text"
        :model-value="element?.component.options.text"
        @update:model-value="val => updateOption(ELEMENTS_META.TEXT, val)"
      />
    </UIFormItem>

    <UIFormItem
      :label="$t('common.align')"
      v-if="!props.element?.component.isDraggable"
    >
      <UIRadioGroup
        :value="element?.responsiveStyles.large.align"
        @update:value="val => updateStyles(ELEMENTS_STYLES.ALIGN, val)"
      >
        <UISpace>
          <UIRadioGroupItem value="signature-left">
            <template #content> {{ $t('common.left') }} </template>
          </UIRadioGroupItem>
          <UIRadioGroupItem value="signature-center">
            <template #content> {{ $t('common.center') }} </template>
          </UIRadioGroupItem>
          <UIRadioGroupItem value="signature-right">
            <template #content>{{ $t('common.right') }}</template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
    </UIFormItem>
    <UICheckbox
      v-if="props.element?.version > 1"
      :id="`${element?.id}-show_name`"
      :disabled="false"
      :checked="element?.component.options.showName"
      @update:checked="(val: boolean) => {
      updateOption(ELEMENTS_META.SHOW_NAME, val, element?.id)
    }"
    >
      {{ $t('common.showSignerName') }}
    </UICheckbox>
    <UIBuilderSpacing
      v-if="!props.element?.component.isDraggable"
      :element="props.element"
      @onUpdate="onBoxStyleChange"
    />
  </div>
</template>

<style lang="scss">
.n-avatar__text {
  transform: translateX(-50%) translateY(-50%) scale(1);
}
</style>
