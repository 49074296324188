<script lang="ts" setup>
import { useDocument } from '@/composition'
import { useBuilderStore } from '@/store/builder'
import { IElement } from '@gohighlevel/ghl-proposals-common'
import { PropType, ref, computed, watch } from 'vue'
import RichTextEditor from '../RichTextEditor.vue'
import { useAppStore } from '@/store/app'

const store = useBuilderStore()
const { loadingCustomValues } = useDocument()
const appStore = useAppStore()

const props = defineProps({
  genKey: { type: String },
  element: {
    type: Object as PropType<IElement>,
  },
})

const refreshCount = ref(0)

const flatCustomValues = computed(() => ({
  ...store.flatCustomValues,
  ...appStore.flatDocumentVariables,
}))

watch(
  () => [flatCustomValues.value, loadingCustomValues.value],
  () => {
    refreshCount.value = 1 + refreshCount.value
  }
)

const customStyleForTextParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    backgroundColor: styles?.backgroundColor,
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
  }
})
</script>

<template>
  <div class="el-wrapper text-el">
    <div
      :style="customStyleForTextParent"
      class="rounded-smooth"
      :key="refreshCount"
    >
      <RichTextEditor
        :custom-values="flatCustomValues"
        :gen-key="props.genKey"
        :element="props.element"
      />
    </div>
  </div>
</template>

<style lang="scss">
.editor-container {
  padding: 0px !important;
  min-width: unset !important;
  border-radius: unset !important;
  border: unset !important;
}
.editor-wrapper {
  p {
    font-size: 16px;
    font-family: sans-serif;
  }
}
</style>
