<script lang="ts" setup>
import { useBuilderStore } from '@/store/builder'
import { Copy01Icon, Trash02Icon } from '@gohighlevel/ghl-icons/24/outline'
import { computed, PropType, reactive } from 'vue'
import {
  IElement,
  ELEMENTS_LOOKUP,
  DragHandleSVG,
} from '@gohighlevel/ghl-proposals-common'
import { UITooltip } from '@gohighlevel/ghl-ui'
import { useProductListStore } from '@/store/productListStore'
import { useActionBarStore } from '@/store/actionBar'

const emit = defineEmits(['dragstart', 'dragend'])
const props = defineProps({
  elementId: {
    type: String,
    required: true,
  },
  element: {
    type: Object as PropType<IElement>,
  },
})

const store = useBuilderStore()
const productListStore = useProductListStore()
const actionBarStore = useActionBarStore()

const activeElementId = computed(() => store.activeElementId)
const state = reactive({
  isActiveElement: computed(() => {
    if (!props.elementId) return false
    return activeElementId.value === props.elementId
  }),
  activeElementStyle: {},
})

state.activeElementStyle = computed(() => {
  const style: any = {}
  if (state.isActiveElement) {
    // style.boxShadow = 'rgb(130, 145, 148) 0px 0px 4px'
  } else {
    // style.backgroundColor = 'rgba(0, 145, 174, 0.1)'
  }
  return style
})

function del(e: any) {
  e.stopPropagation()
  store.deleteElement(props.elementId)

  if (props.element?.type === ELEMENTS_LOOKUP.SIGNATURE) {
    store.syncRecipient()
  }

  if (props.element?.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
    const allLineItemsLength = store.totalLineItemsLength
    if (allLineItemsLength <= 0) {
      productListStore.setProductListCurrency('')
      productListStore.resetSchedule()
    }
  }
}

function clone(e: any) {
  e.stopPropagation()
  store.cloneElement(props.elementId)
}

const isHovered = computed(
  () => store.focusedElement?.id === props.elementId && store.hover
)
const isTableElement = computed(
  () => store.activeElement?.type === ELEMENTS_LOOKUP.TABLE
)

const onDragStart = (ev: MouseEvent) => {
  emit('dragstart', ev, props.element)
  actionBarStore.setLastActiveDragElement(props.element as IElement)
}

const onDragEnd = (ev: MouseEvent) => {
  emit('dragend', ev, props.element)
  actionBarStore.setLastActiveDragElement(null)
}
</script>

<template>
  <div
    v-if="store.isDocumentEditable && state.isActiveElement"
    class="actions absolute top-[-8px] left-[45%] -mt-8 flex z-20 overlay-actions animate-slideUp"
    :class="{
      '!left-[0%]': isTableElement,
    }"
  >
    <div
      class="flex justify-between bg-gray-charcoal border border-gray-700 shadow-float p-[4px] rounded-sm"
    >
      <UITooltip>
        <template #trigger>
          <div
            class="hover:bg-gray-600 w-full cursor-pointer flex"
            @click="clone"
          >
            <Copy01Icon class="text-white p-1 text-sm h-4 w-4" />
          </div>
        </template>
        {{ $t('common.copy') }}
      </UITooltip>
      <UITooltip>
        <template #trigger>
          <div
            class="hover:bg-gray-600 w-full cursor-pointer flex"
            @click="del"
          >
            <Trash02Icon class="text-white p-1 text-sm h-4 w-4" />
          </div>
        </template>
        {{ $t('common.delete') }}
      </UITooltip>
    </div>
  </div>
  <div
    v-if="isHovered || state.isActiveElement"
    :style="state.activeElementStyle"
    class="absolute pointer-events-none overlay-container rounded-smooth"
    style="inset: 0px; z-index: 2"
    :class="{
      'border border-[#4af]': state.isActiveElement,
      border: isHovered,
    }"
  ></div>
  <div
    @mousedown="onDragStart"
    @mouseup="onDragEnd"
    v-if="state.isActiveElement || isHovered"
    class="handle remove-selecto-selection absolute h-[40px] w-2 px-[8px] left-[-28px] top-0 rounded-smooth cursor-move flex justify-center items-center pointer-events-auto bg-[#FFF] shadow-page border ease-linear"
  >
    <div
      class="remove-selecto-selection transform rotate-90 pointer-events-auto text-white font-sans"
      style="font-size: 12px; line-height: 0.2; z-index: 3"
    >
      <DragHandleSVG class="remove-selecto-selection rotate-90" />
    </div>
  </div>
</template>

<style>
/* override parent hover state on child active/ focus state state */
.el-active > div > .overlay-container {
  /* border: 1px solid #98a2b3 !important; */
}

/* handle parent move handle  */
.el-active > div > .overlay-container .handle {
  display: none;
}

.el-active > div > .overlay-actions {
  display: none;
}

.el-active > div > .overlay-container {
  background-color: transparent !important;
}
</style>
