<script lang="ts" setup>
import { UIInput, UITextSmRegular, UITooltip } from '@gohighlevel/ghl-ui'
import { computed, onMounted, PropType, ref } from 'vue'
import {
  ICustomFieldsLinkageForm,
  IElement,
  IRecipient,
  MovableComponentEvents,
  RecipientEntityTypeEnum,
} from '../../types'
import { MovableComponentWrapper } from '../MovableComponentWrapper'
import { CommonTooltip } from '../Tooltip'
import {
  generateCustomLinkItemPayload,
  getSignatureElementColorStyles,
} from '../../utils'
import { ELEMENTS_META } from '../../const'
import {
  FloatingCustomSelect,
  CustomFieldTriggerLink,
} from '../CustomSelectTrigger'
import { useCustomValues } from '../../composition'
import { TextInputIcon } from '@gohighlevel/ghl-icons/24/outline'

const props = defineProps({
  element: Object as PropType<IElement>,
  isActive: {
    type: Boolean,
    default: false,
  },
  snapContainer: {
    type: String,
  },
  boundContainer: {
    type: String,
  },
  storeMap: {
    type: Object,
    required: true,
  },
  compositionMap: {
    type: Object,
    required: true,
  },
})
const popoverId = computed(() => `element-popover-${props.element?.id}`)
const enableTooltip = ref(true)
const isActiveElement = computed(() => props.isActive)
const builderStore = props.storeMap.useBuilderStore()
const emits = defineEmits([MovableComponentEvents.ON_ACTIVE])
const { updateStyles, updateOption } = props.compositionMap.useElementOption()
const store = props.storeMap.useAppStore()
const { customMenuOptions } = useCustomValues(store, popoverId.value)
const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id }) => id === props.element?.component.options.recipient
    ) || ({} as IRecipient)
  )
})
const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return firstName ? `${firstName} ${lastName || ''}` : ''
})
const docVariableList = computed(() => store.document?.variables || [])

const onDrag = (styles: any) => {
  updateStyles('position', styles, props.element?.id)
  updateOption('isGhost', true, props.element?.id)
}
const onDragUpdate = (value: boolean) => {
  enableTooltip.value = !value
}
const onScale = ({ scaleX, scaleY }: { scaleX: number; scaleY: number }) => {
  updateStyles('scale', { scaleX, scaleY }, props.element?.id)
}

const onResize = ({ height, width }: { height: number; width: number }) => {
  updateStyles('dimensions', { height, width }, props.element?.id)
}

const onActive = (e: any, isActive: boolean) => {
  emits(MovableComponentEvents.ON_ACTIVE, e, isActive)
  if (isActive) {
    hideCustomValues()
  }
}

function del(e: any) {
  e.stopPropagation()
  builderStore.deleteElement(props?.element?.id as string)
  builderStore.syncRecipient()
  builderStore.syncCustomFieldsForLinkage()
}

onMounted(() => {
  builderStore.syncRecipient()
})
function clone(e: any) {
  e.stopPropagation()
  builderStore.cloneElement(props?.element?.id as string)
}

const textValue = computed(() => props.element?.component.options.text)
const onChange = (value: string) => {
  updateOption(ELEMENTS_META.TEXT, value, props.element?.id)
}

const onSelect = (val: string) => {
  const value = (textValue.value || '') + val
  onChange(value)
}
const styles = computed(() => getSignatureElementColorStyles(recipient.value))
const colorStyles = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  if (props.element?.component.options.text) {
    return {
      //    border: `1px solid ${styles.value.borderColor} !important`,
      minHeight: `${dimensionsObj?.height}px`,
    }
  }
  return {
    ...styles.value,
    //  border: `1px solid ${styles.value.borderColor} !important`,
    'box-shadow': 'none !important',
    ...(dimensionsObj
      ? {
          height: `${dimensionsObj.height}px`,
        }
      : {}),
  }
})
const position = computed(() => {
  const pos = props.element?.responsiveStyles.large.position
  return pos || { top: 0, left: 0, bottom: 0 }
})
const scale = computed(() => {
  const scaleObj = props.element?.responsiveStyles.large.scale
  return scaleObj || { scaleX: 1, scaleY: 1 }
})
const dimensions = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  return dimensionsObj || { height: 'auto', width: 'auto' }
})
const align = computed(() => props.element?.responsiveStyles.large.align)

const onSelectCustomValueLinkage = (formValue: ICustomFieldsLinkageForm) => {
  const payload = generateCustomLinkItemPayload(formValue)
  builderStore.updateCustomValueLinkage(
    formValue.elementId,
    formValue.elementType,
    payload
  )
}

const availableDocumentVariablesList = computed(() => {
  const docDefaultVariable = docVariableList.value.map(vb => vb.fieldKey)
  builderStore.getAllLinkedDocumentVariables().forEach(vb => {
    if (!docDefaultVariable.includes(vb.fieldKey)) {
      docDefaultVariable.push(vb.fieldKey)
    }
  })
  return docDefaultVariable || []
})

const isContactRecipient = computed(
  () =>
    props.element?.component.options.recipient &&
    props.element?.component.options.entityName ===
      RecipientEntityTypeEnum.CONTACTS
)

const contactCustomValueMapping = computed(() => {
  return builderStore.getContactCustomValueLinkMapping()
})
const onDragEnd = (options: any) => {
  builderStore.moveFloatingElement(props.element?.id as string, options)
}

const show = ref(false)
const showCustomValues = (e: MouseEvent) => {
  e.stopPropagation()
  show.value = !show.value
}
const hideCustomValues = () => {
  show.value = false
}
</script>

<template>
  <MovableComponentWrapper
    :key="`moveable-element-${props.element?.id}`"
    :id="(props.element?.id as string)"
    @on-drag="onDrag"
    @on-scale="onScale"
    @on-active="onActive"
    @on-resize="onResize"
    @on-drag-update="onDragUpdate"
    @on-drag-end="onDragEnd"
    :isActiveElement="isActiveElement"
    :isEditable="builderStore.isDocumentEditable"
    :position="position"
    :scale="scale"
    :align="align"
    :dimensions="dimensions"
    :isGhost="props.element?.component.options.isGhost"
    :snap-container="props.snapContainer"
    :bound-container="props.boundContainer"
    :isDragElement="props.element?.component.isDraggable"
    :resizable="true"
    :keep-ratio="false"
    :render-directions="['se']"
    :min-height="22"
    :min-width="15"
    :colorOverride="styles.placeholderColor"
    :inactiveColorOverride="styles.borderColor"
  >
    <template #component>
      <span v-if="element?.component.options.required" class="option--required"
        >*</span
      >
      <div
        class="el-wrapper textfield-el overflow-hidden"
        style="overflow: hidden; height: inherit; width: inherit"
      >
        <UIInput
          class="text-field rounded-sm !text-sm overflow: hidden;"
          :style="colorStyles"
          :id="`floating_text_field-${props.element?.id}`"
          :placeholder="props.element?.component.options.placeholder"
          :modelValue="props.element?.component.options.text"
          type="textarea"
          :loading="false"
          :autosize="{
            minRows: 1,
            maxRows: 10,
          }"
          @update:model-value="onChange"
          size="medium"
          :scroll-to="{ top: 0 }"
        >
        </UIInput>
      </div>
    </template>
    <template #popup-options>
      <CommonTooltip
        @on-clone="clone"
        @on-delete="del"
        :key="`floating-options-${props.element?.id}`"
      >
        <template #extra>
          <FloatingCustomSelect
            v-if="builderStore.isDocumentEditable"
            popover-id="popoverId"
            :customMenus="customMenuOptions"
            @on-select="onSelect"
            :to="`#moveable-container-${props.element?.id}`"
            :show="show"
            @onClickOutSide="hideCustomValues"
          >
            <template #trigger>
              <div class="hover:bg-gray-600 w-full cursor-pointer flex">
                <UITooltip placement="right">
                  <template #trigger>
                    <TextInputIcon
                      class="text-white p-1 text-sm h-4 w-4"
                      @click="showCustomValues"
                    />
                  </template>
                  {{ $t('common.customValues') }}
                </UITooltip>
              </div>
            </template>
          </FloatingCustomSelect>
          <CustomFieldTriggerLink
            v-if="
              isActiveElement &&
              builderStore.isDocumentEditable &&
              isContactRecipient
            "
            :element="props.element"
            :popover-id="'popover-filed-link-' + props.element?.id"
            :label="$t('common.linkResponseToCustomFields')"
            :available-document-variables="availableDocumentVariablesList"
            @custom-variable-added="onSelectCustomValueLinkage"
            :contactCustomValueMapping="contactCustomValueMapping"
          />
        </template>
      </CommonTooltip>
    </template>

    <template #tooltip v-if="recipientName">
      <UITextSmRegular
        class="capitalize font-semibold py-1 px-4 text-xs"
        :style="{ color: styles.placeholderColor }"
        >{{ recipientName }}</UITextSmRegular
      >
    </template>
  </MovableComponentWrapper>
</template>

<style lang="scss">
.text-field.n-input {
  --n-border-hover: 0px solid transparent !important;
  --n-border-pressed: 0px solid transparent !important;
  --n-border-focus: 0px solid transparent !important;
  --n-caret-color: 0px solid transparent !important;
  --n-box-shadow-focus: 0px solid transparent !important;
  --n-loading-color: 0px solid transparent !important;

  --n-border: 0px solid transparent !important;
  --n-color: transparent !important;
  textarea {
    font-weight: 600 !important;
  }
  &.n-input {
    box-shadow: none !important;
  }
  .n-input-wrapper {
    min-height: unset !important;
    padding: 0 !important;
    .n-input__suffix {
      align-items: flex-start !important;
      .n-base-loading.n-base-suffix {
        display: none !important;
      }
    }
    .n-input__textarea {
      .n-input__textarea-el {
        padding: 0px 2px !important;
      }
      .n-input__placeholder {
        padding: 0px 2px !important;
        color: v-bind('styles.placeholderColor') !important;
      }
    }
    .n-input__textarea {
      .n-input__textarea-mirror,
      .n-input__textarea-el {
        // padding: v-bind('padding') !important;
        padding: 0px 2px !important;
        height: 100%;
        overflow: hidden;
        vertical-align: top;
      }
      .n-input__textarea-mirror {
        min-height: 37px !important;
      }
    }
  }
}
.option--required {
  position: absolute;
  right: -8px;
  top: -19px;
  color: red;
  font-size: 22px;
  font-weight: 700;
}
.text-field {
  .n-input__input {
    font-size: 14px !important;
  }
}
</style>
