<script lang="ts" setup>
import { createElement } from '@/helper/element-factory'
import { useBuilderStore } from '@/store/builder'
import { cloneDeep } from 'lodash'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue'

import {
  ELEMENTS_LOOKUP,
  IElement,
  PageElementOptions,
  Rotation,
  OLD_PAGE_HEIGHT,
  DEFAULT_PAGE_DIMENSION,
  OLD_PAGE_WIDTH,
  Direction,
  ElementDropContainer,
} from '@gohighlevel/ghl-proposals-common'

import CImage from './elements/CImage.vue'
import CPageBreak from './elements/CPageBreak.vue'
import CProductList from './elements/CProductList/CProductList.vue'
import CSignature from './elements/CSignature.vue'
import CTable from './elements/CTable.vue'
import CText from './elements/CText.vue'
import CVideo from './elements/CVideo.vue'
import PDFPageBreak from '../common/PDFPageBreak.vue'
import { useProductListStore } from '@/store/productListStore'
import BlockElementsRenderer from './BlockElementsRenderer.vue'
import { useActionBarStore } from '@/store/actionBar'
import RenderFloatingComponents from '../common/RenderFloatingComponents.vue'

defineComponent({
  CText,
  CVideo,
  CTable,
  CImage,
  CPageBreak,
  CProductList,
  CSignature,
})

const props = defineProps({
  elements: {
    type: Array as PropType<Array<IElement>>,
  },
  page: {
    type: Object as PropType<IElement<PageElementOptions>>,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
})
const actionBarStore = useActionBarStore()
const store = useBuilderStore()
const productListStore = useProductListStore()
const pageRef = ref<HTMLElement>()
const pageViewHeight: Ref<number> = ref(0)

/**
 * Page dimensions
 * @default properties from the page component
 */
const pageDimensions = computed(() => {
  return (
    props.page?.component?.options?.pageDimensions || {
      dimensions: {
        width: 816,
        height: 1056,
      },
      margins: {
        top: 48,
        right: 48,
        bottom: 48,
        left: 48,
      },
      rotation: Rotation.Portrait,
    }
  )
})

const isPDFPage = computed(() => props.page?.component.options.fromPDF)
const pageHeight = computed(() => {
  if (props.page?.version > 1) {
    return pageDimensions.value.dimensions.height + 'px'
  }
  return isPDFPage.value || props.page?.component.options.src
    ? `${OLD_PAGE_HEIGHT}px`
    : `${DEFAULT_PAGE_DIMENSION.dimensions.height}px`
})

function moveElement(
  newRowIndex: number,
  oldRowIndex: number,
  element: IElement
) {
  store.moveElement(
    cloneDeep(element),
    props?.page?.id as string,
    newRowIndex,
    oldRowIndex
  )
}

const childElementsWithDraggable = computed(() => {
  const element = store.pages.find(x => x.id === props.page?.id)
  const children = element?.children.filter(
    child =>
      child.component.isDraggable && child.type !== ELEMENTS_LOOKUP.PAGE_BREAK
  )
  return cloneDeep(children)
})

const childComponentsWithoutDraggable = computed(() => {
  const element = store.pages.find(x => x.id === props.page?.id)
  const children = element?.children.filter(
    child =>
      !child.component.isDraggable && child.type !== ELEMENTS_LOOKUP.PAGE_BREAK
  )
  return cloneDeep(children)
})

function onStart(_ev: MouseEvent, element: IElement) {
  store.setActiveElementId(element.id)
  store.moveElementDragState = true
}

function onEnd() {
  store.moveElementDragState = false
}

function mouseEnter(e: any, element: IElement) {
  e.preventDefault()
  store.changeHoverState(true)
  const section = e.target.closest('section')
  section.classList.add('el-active')
  store.updateFocusedElement(element)
}

function mouseLeave(e: any) {
  e.preventDefault()
  store.changeHoverState(false)
  const section = e.target.closest('section')
  section.classList.remove('el-active')
  store.updateFocusedElement()
}

const elContainerRef = ref()
onBeforeUnmount(() => {
  if (elContainerRef.value?.closest) {
    const section = elContainerRef.value?.closest('section')
    if (!section) return
    section.classList.remove('el-active')
    store.changeHoverState(false)
    store.updateFocusedElement()
  }
})

watch(elContainerRef, () => {
  if (elContainerRef.value?.length) {
    elContainerRef.value.forEach((section: HTMLDivElement) => {
      if (section?.firstElementChild?.className === 'page-break--el') {
        if (section.parentElement) {
          section.parentElement.style.display = 'none'
        }
      }
    })
  }
})
const backgroundSize = computed(() => {
  if (props.page.version > 1) {
    return isPDFPage.value
      ? `${pageDimensions.value.dimensions.width}px ${pageDimensions.value.dimensions.height}px`
      : props.page?.responsiveStyles?.large?.backgroundSize
  } else {
    return isPDFPage.value
      ? `${OLD_PAGE_WIDTH}px ${OLD_PAGE_HEIGHT}px`
      : props.page?.responsiveStyles?.large?.backgroundSize
  }
})
const pageStyles = computed(() => {
  const page = store.pages.find(x => x.id === props.page?.id)
  return {
    backgroundColor: page?.responsiveStyles?.large?.backgroundColor || null,
    backgroundImage: `url('${page?.component?.options?.src}')`,
    backgroundPosition: page?.responsiveStyles?.large?.backgroundPosition,
    backgroundRepeat: page?.responsiveStyles?.large?.backgroundRepeat,
    backgroundSize: backgroundSize.value,
    opacity: page?.responsiveStyles?.large?.opacity / 100 || 1,
  }
})

const observer = new MutationObserver(mutationsList => {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      const currentHeight = pageRef.value?.offsetHeight as number
      pageViewHeight.value = currentHeight
    }
  }
})

watch(pageRef, newValue => {
  if (newValue) {
    observer.observe(pageRef.value as HTMLElement, {
      attributes: true,
      childList: true,
      subtree: true,
    })
  }
})

onMounted(() => {
  pageViewHeight.value = pageRef.value?.offsetHeight as number
  if (pageRef.value) {
    observer.observe(pageRef.value, {
      attributes: true,
      childList: true,
      subtree: true,
    })
  }
})

const updateActiveElement = (event: MouseEvent, element: IElement | null) => {
  event.stopImmediatePropagation()
  store.updateActiveElement(element)
  productListStore.setActiveRow(null, null)
  store.moveElementDragState = false
  actionBarStore.dragStateIsActivated = false
  actionBarStore.setFloatingElementDragging(false)
}

const snapContainerClass = computed(() => `snapContainer-${props.pageIndex}`)
const boundContainerClass = computed(() => `boundContainer-${props.pageIndex}`)
const containerRef = ref()

const onDrop = (
  evt: any,
  idx: number,
  colIndex: number,
  rowColIndex: number
) => {
  const containerReact = containerRef?.value?.getBoundingClientRect()
  if (!containerReact) {
    console.error('no containerRect found.', containerReact)
    return
  }
  const left = evt.clientX - containerReact.left
  const top = evt.clientY - containerReact.top
  const bottom = containerReact.height - top

  const type = evt.dataTransfer.getData('name')

  if (!props.page) return
  if (
    type === ELEMENTS_LOOKUP.PAGE_BREAK &&
    (colIndex > 0 || rowColIndex > 0)
  ) {
    return
  }
  const el: IElement = createElement({ type }) as IElement
  if (el) {
    store.addElement(
      cloneDeep({
        ...el,
        responsiveStyles: {
          ...el.responsiveStyles,
          large: {
            ...el.responsiveStyles.large,
            scale: { scaleX: 1, scaleY: 1 },
            position: { top, left, bottom },
          },
        },
      }),
      props.page?.id,
      idx as number,
      colIndex as number,
      rowColIndex as number
    )
  }
}

const getAdjustedRowIndex = (
  oldIdx: number,
  newIdx: number,
  length: number
) => {
  if (oldIdx < 0) return newIdx
  if (oldIdx === 0) return newIdx - 1
  if (oldIdx < newIdx && length === 1) {
    return newIdx - 1 > -1 ? newIdx - 1 : 0
  }
  if (oldIdx < newIdx) {
    return newIdx - 1
  }
  return newIdx
}

const getAdjustedColIndex = (
  oldIdx: number,
  newIdx: number,
  currentRowId: string,
  newRowId: string,
  length: number
) => {
  if (currentRowId === newRowId && oldIdx < newIdx && length === 1) {
    return newIdx - 1 > -1 ? newIdx - 1 : 0
  }
  if (oldIdx < 0) return newIdx

  return newIdx
}
const getInfoForCurrentElement = (id: string) => {
  const foundPage = store.pages?.find(x => x.id === props.page?.id)
  if (!foundPage) {
    // Handle the case where no page is found
    console.error('Page not found')
    return
  }

  const rows = foundPage.children?.filter(x => x.type === ELEMENTS_LOOKUP.ROW)
  if (!rows) {
    console.error('No rows found')
    return
  }

  return rows.reduce(
    (acc, row) => {
      if (!row.children) return acc

      row.children.forEach((col, index) => {
        if (!col.children) return acc

        col.children.forEach(el => {
          if (el.id === id) {
            acc.colIdx = index
            acc.currentRowId = row.id
            acc.colChildLength = col.children.length
          }
        })
      })

      return acc
    },
    { currentRowId: '', colIdx: -1, colChildLength: 0 }
  )
}
const onDropElement = (
  ev: DragEvent,
  option: {
    rowIndex?: number
    dir: Direction
    colIndex?: number
    rowColIndex?: number
    currentPageId?: string
    rowId?: string
  }
) => {
  ev.preventDefault()
  actionBarStore.toggleDragState(false)
  store.moveElementDragState = false
  const action = ev?.dataTransfer?.getData('action')
  if (action === 'add') {
    onDrop(
      ev,
      option.rowIndex as number,
      option.colIndex as number,
      option.rowColIndex as number
    )
  } else {
    const existingElement = actionBarStore.lastActiveDragElement
    if (existingElement) {
      const pageId = store.getPageIdByElementId(existingElement?.id)
      if (pageId) {
        // create row if not created and push element here
        const el = cloneDeep(existingElement)
        if (el) {
          const currentIdx = store.pages
            .find(x => x.id === props.page?.id)
            ?.children.findIndex(x => x.id === existingElement.id)
          const info = getInfoForCurrentElement(existingElement.id)
          if (info) {
            const { currentRowId, colIdx, colChildLength } = info
            store.deleteElement(existingElement?.id)
            store.addElement(
              el,
              option.currentPageId as string,
              getAdjustedRowIndex(
                currentIdx as number,
                option.rowIndex as number,
                colChildLength
              ),
              getAdjustedColIndex(
                colIdx,
                option.colIndex as number,
                currentRowId,
                option.rowId as string,
                colChildLength
              ),
              option.rowColIndex as number
            )
          }
        }
      } else {
        const currentIdx = store.pages
          .find(x => x.id === props.page?.id)
          ?.children.findIndex(x => x.id === store.activeElementId)
        if (currentIdx !== option.rowIndex) {
          moveElement(
            option.rowIndex as number,
            currentIdx as number,
            existingElement
          )
        }
      }
    }
  }
}
</script>

<template>
  <div class="elements_page bg-white shadow-page" ref="pageRef">
    <div
      class="page-view clearfix"
      :style="{
        minHeight: pageHeight,
        width: pageDimensions.dimensions.width + 'px',
      }"
    >
      <PDFPageBreak
        v-if="pageViewHeight"
        :parentHeight="pageViewHeight"
        :page-dimensions="pageDimensions"
        :page-version="props.page.version"
      />
      <div class="page-header header-footer clearfix">
        <div
          class="page-header-content"
          :style="{
            minHeight: pageDimensions.margins.top + 'px',
          }"
        ></div>
      </div>
      <div
        ref="containerRef"
        class="page-content"
        :id="props.page?.id"
        :class="{
          [snapContainerClass]: true,
          [boundContainerClass]: true,
        }"
        :style="{
          marginLeft: pageDimensions.margins.left + 'px',
          marginRight: pageDimensions.margins.right + 'px',
        }"
        :data-id="props.page?.id"
        :data-type="ELEMENTS_LOOKUP.PAGE"
      >
        <RenderFloatingComponents
          v-if="props.page?.children"
          :parentId="(props.page?.id as string)"
          :page-index="props.pageIndex"
          :child-components-with-draggable="(childElementsWithDraggable as IElement[])"
          :snap-container-class="snapContainerClass"
          :bound-container-class="boundContainerClass"
          :key="`page-level-key-${props.page?.id}`"
        />

        <div
          class="relative draggable-container clearfix flex flex-col"
          style="width: 100%"
        >
          <div
            v-if="actionBarStore.draggingFloatingElement"
            class="absolute w-full h-full top-0 left-0 z-[100]"
            @dragover="
              event => {
                event.preventDefault()
              }
            "
            @drop="
              event =>
                onDropElement(event, {
                  dir: 'bottom',
                  rowIndex: page.children?.length,
                })
            "
          ></div>
          <ElementDropContainer
            v-if="childComponentsWithoutDraggable?.length === 0"
            :idx="0"
            :for-initial="true"
            @onDropElement="onDropElement"
            width="100%"
            :page-id="props.page?.id"
            :col-index="0"
            :row-index="0"
          />
          <BlockElementsRenderer
            v-else
            :key="`BlockElementsRenderer-page-level-key-${props.page?.id}`"
            :elements="(childComponentsWithoutDraggable as IElement[])"
            @on-move-start="onStart"
            @on-move-end="onEnd"
            @click="(...args) => updateActiveElement(...args)"
            @mouseOver="mouseEnter"
            @mouseLeave="mouseLeave"
            @on-drop-element="onDropElement"
            :pageId="props.page?.id"
            :dragEnabled="
              (store.moveElementDragState ||
                actionBarStore.dragStateIsActivated) &&
              !actionBarStore.draggingFloatingElement
            "
            :parentId="(props.page?.id as string)"
            :page-index="props.pageIndex"
            :snap-container-class="snapContainerClass"
            :bound-container-class="boundContainerClass"
          />
          <span
            v-if="
              childComponentsWithoutDraggable?.length > 0 &&
              (store.moveElementDragState ||
                actionBarStore.dragStateIsActivated) &&
              !actionBarStore.draggingFloatingElement
            "
            :ondragover="event => event.preventDefault()"
            :ondrop="(ev: DragEvent) => onDropElement(ev,  {dir: 'bottom', rowIndex:childComponentsWithoutDraggable?.length, currentPageId: props.page?.id})"
            class="relative block bg-transparent bottom-0 left-0 w-full z-0 flex-1"
          ></span>
        </div>
      </div>

      <div class="page-footer header-footer clearfix">
        <div
          class="page-footer-content"
          :style="{
            minHeight: pageDimensions.margins.top + 'px',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
.elements_page {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    z-index: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    transform: translateZ(0px);
    top: 0;
    background-color: v-bind('pageStyles.backgroundColor');
    background-image: v-bind('pageStyles.backgroundImage');
    background-position: v-bind('pageStyles.backgroundPosition');
    background-repeat: v-bind('pageStyles.backgroundRepeat');
    background-size: v-bind('pageStyles.backgroundSize');
    opacity: v-bind('pageStyles.opacity');
  }
}
</style>

<style lang="scss">
.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
.page-view {
  position: relative;
  display: flex;
  flex-direction: column;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
.page-header {
  .page-header-content {
    box-sizing: border-box;
  }
}

.header-footer {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.page-footer {
  .page-footer-content {
    box-sizing: border-box;
  }
}

.page-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  &--floating-active {
    z-index: 10;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
  }
}

.draggable-container {
  flex: 1 0 auto;
}
</style>
