import {
  CreateContactParams,
  IContactSearchParams,
  IContactSearchResponse,
} from '@gohighlevel/ghl-proposals-common'

import config from '@/config'
import { requests } from '.'

export const ContactService = {
  search: (params: IContactSearchParams) => {
    return requests(config.CONTACT_SERVICE_URL).post<IContactSearchResponse>(
      '/search',
      params
    )
  },
  getContact: (id: string) => {
    return requests().get(config.CONTACT_SERVICE_URL + '/' + id)
  },
  createContact: (params: CreateContactParams) => {
    return requests(config.CONTACT_SERVICE_URL).post<any>('/', params)
  },
}
