import { useAppStore } from '@/store/app.ts'
import { useBuilderStore } from '@/store/builder'
import { useGroupsFactory } from '@gohighlevel/ghl-proposals-common'

export const useGroups = () => {
  const builderStore = useBuilderStore()
  const appStore = useAppStore()
  const groupFactory = useGroupsFactory(builderStore, appStore)
  return groupFactory
}
