<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'

const props = defineProps({
  containerId: {
    type: String,
    required: true,
  },
  leftColumnId: {
    type: String,
    required: true,
  },
  rightColumnId: {
    type: String,
    required: true,
  },
  initialPosition: {
    type: String,
    required: true,
    default: '0%',
  },
  leftWidth: {
    type: String,
    required: true,
    default: '50%',
  },
  rightWidth: {
    type: String,
    required: true,
    default: '50%',
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
})
const emits = defineEmits(['onMouseDown', 'onChange', 'onMouseUp'])
const resizing = ref(false)
const change = reactive({
  left: {
    width: props.leftWidth,
    id: props.leftColumnId,
  },
  right: {
    width: props.rightWidth,
    id: props.rightColumnId,
  },
})

const onMouseDown = (e: MouseEvent) => {
  resizing.value = true
  emits('onMouseDown', e, props.containerId)
}

const leftColumn = computed(() => {
  return document.getElementById(props.leftColumnId)
})

const rightColumn = computed(() => {
  return document.getElementById(props.rightColumnId)
})

const onMouseMove = (e: MouseEvent) => {
  if (!resizing.value || !props.containerId) return
  const rightColumnRect = rightColumn.value?.getBoundingClientRect()
  const leftColumnRect = leftColumn.value?.getBoundingClientRect()
  const totalWidth =
    (rightColumnRect?.width as number) + (leftColumnRect?.width as number)
  if (leftColumnRect && rightColumnRect && totalWidth) {
    const newRightWidth =
      ((rightColumnRect.right - e.clientX) / totalWidth) * 100

    const newLeftWidth = ((e.clientX - leftColumnRect.left) / totalWidth) * 100

    // Prevent divs from collapsing too much
    if (newLeftWidth < 10 || newLeftWidth > 90) return

    change.left.width = `${newLeftWidth.toPrecision(2)}%`
    change.right.width = `${newRightWidth.toPrecision(2)}%`
    leftColumn.value?.style.setProperty('width', `${newLeftWidth}%`)
    rightColumn.value?.style.setProperty('width', `${newRightWidth}%`)
  }
}

const onMouseUp = () => {
  if (resizing.value) {
    resizing.value = false
    emits('onMouseUp')
    emits('onChange', change)
  }
}
onMounted(() => {
  window.addEventListener('mouseup', () => {
    onMouseUp()
  })
  window.addEventListener('mousemove', e => {
    onMouseMove(e)
  })
})
</script>

<template>
  <div
    class="splitter h-[inherit] z-[0] w-[4px] relative top-0 cursor-col-resize"
    :class="props.class"
    style="border-radius: 4px; backface-visibility: hidden; opacity: 0.3"
    @mousedown="onMouseDown"
    @mousemove="onMouseMove"
    :style="{
      opacity: resizing ? 0.8 : 0.3,
    }"
  ></div>
</template>

<style lang="scss">
.splitter {
  visibility: hidden;
  cursor: col-resize;
  min-width: 20px;
  background-color: transparent;
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background-color: var(--success-600);
  }
  &.visibility-hidden {
    &::after {
      opacity: 0;
    }
  }
}
</style>
