import config from '@/config'
import { getHeader } from '@/service'
import { useAppStore } from '@/store/app'
import { extract } from '@extractus/oembed-extractor'
import { convertObjToSnakeCase } from '@gohighlevel/ghl-proposals-common'

export const VIDEO_PROVIDERS = {
  YOUTUBE: 'youtube',
  VIMEO: 'video',
  WISTIA: 'WISTIA',
  vidyard: 'vidyard',
}

export const SupportedVideoProviders = [
  'YouTube',
  'Vimeo',
  'Dailymotion',
  'Wistia, Inc.',
  'Vidyard',
  'loom',
]
export function useVideo() {
  function validateProvider(provider: string) {
    return SupportedVideoProviders.includes(provider)
  }
  async function generateVideoIframe(
    url: string
  ): Promise<{ error: boolean; message?: string; html?: string }> {
    const store = useAppStore()
    if (!url) return { error: true, message: 'Invalid video url', html: '' }
    if (url.includes('loom.com')) {
      const videoUrl = url.replace('loom.com/share/', 'loom.com/embed/')
      return {
        error: false,
        html: `<iframe src="${videoUrl}" width="630" height="510" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`,
      }
    }
    try {
      const resp = await extract(
        url,
        {
          maxwidth: 630,
          maxheight: 510,
        },
        {
          proxy: {
            target: config.PROPOSAL_SERVICE_URL + '/proxy?url=',
            headers: {
              'token-id': store.tokenId,
              ...getHeader(),
            },
          },
        }
      )
      const result = convertObjToSnakeCase(resp)
      const tempElement = document.createElement('div')
      tempElement.innerHTML = result.html
      const iframe = tempElement.querySelector('iframe')
      // Remove the width and height attributes
      iframe?.removeAttribute('width')
      iframe?.removeAttribute('height')

      if (validateProvider(result.provider_name)) {
        return { error: false, html: tempElement.innerHTML, message: '' }
      } else {
        return { error: true, message: 'Invalid video provider', html: '' }
      }
    } catch (error: any) {
      const message = error?.message || 'Unable to fetch video'
      return {
        error: true,
        message: message,
        html: '',
      }
    }
  }

  return {
    generateVideoIframe,
    validateProvider,
  }
}
