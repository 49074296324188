<script setup lang="ts">
import {
  CheckBoxElementOptions,
  IElement,
  ELEMENTS_META,
  renderLabelAvatarOnSelect,
} from '@gohighlevel/ghl-proposals-common'
import {
  UICheckbox,
  UISelect,
  UIFormItem,
  UIDivider,
} from '@gohighlevel/ghl-ui'
import { PropType, onMounted, ref } from 'vue'
import { useElementOption } from '@/composable/elementOptions'
import RecipientCard from '@/components/drawer/DrawerRecipients/RecipientCard.vue'
import { useBuilderStore } from '@/store/builder'
import { useRecipients } from '@/composition'

const { updateOption } = useElementOption()
const selectValue = ref()
const recipient = ref()
const { syncRecipient } = useBuilderStore()
const {
  signedByOptions,
  getSelectValue,
  fetchRecipientForElementOrGroup,
  assignRecipientToElement,
} = useRecipients()

const props = defineProps({
  element: Object as PropType<IElement<CheckBoxElementOptions>>,
})

const assignRecipientToCheckbox = (val: string) => {
  if (val && props.element) {
    assignRecipientToElement(val, props.element)
    syncRecipientList()
  }
}

const syncRecipientList = () => {
  syncRecipient()
  if (props.element) {
    const assignedRecipient = fetchRecipientForElementOrGroup(props.element)
    if (assignedRecipient) {
      recipient.value = assignedRecipient
    }
    selectValue.value = getSelectValue(props.element)
  }
}

const updateCheckbox = (val: boolean) => {
  updateOption(ELEMENTS_META.PRE_CHECKED, val, props.element?.id)
  updateOption(ELEMENTS_META.TEXT, val.toString(), props.element?.id)
}

onMounted(() => {
  syncRecipientList()
})
</script>

<template>
  <div class="hl-proposal-sidebar-content hl-proposal-checkbox-properties">
    <div class="mb-4">
      <UIFormItem :label="$t('common.toBeFilledBy')">
        <UISelect
          :id="`checkbox-text`"
          :placeholder="$t('common.assignRecipientToCheckbox')"
          :options="signedByOptions"
          :value="selectValue"
          @update:value="(val: any) => assignRecipientToCheckbox(val)"
          :render-label="renderLabelAvatarOnSelect"
        />
      </UIFormItem>
      <div>
        <RecipientCard :recipient="recipient" />
      </div>
    </div>
    <UIDivider />

    <div class="my-6">
      <div class="flex flex-col">
        <div class="py-2">
          <UICheckbox
            :id="`${element?.id}-required-checkbox`"
            :checked="element?.component.options.required"
            :type="'default'"
            @update:checked="(val: boolean) => {
        updateOption(ELEMENTS_META.REQUIRED, val, element?.id)
      }"
          >
            Required
          </UICheckbox>
        </div>
        <div class="py-2">
          <UICheckbox
            :id="`${element?.id}-pre-checked-checkbox`"
            :checked="element?.component.options?.preChecked"
            :type="'default'"
            @update:checked="updateCheckbox"
          >
            Pre-checked
          </UICheckbox>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.hl-proposal-checkbox-properties {
  .n-divider.n-divider--no-title {
    margin: 10px 0px;
  }

  .select-field-button {
    width: 100%;
    border: 1px solid #eff4ff;
  }
}
</style>
