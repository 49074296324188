<script setup lang="ts">
import {
  CommonModalProps,
  ModalEvents,
} from '@gohighlevel/ghl-proposals-common'
import CustomModal from '@/components/common/CustomModal.vue'
const props = withDefaults(defineProps<CommonModalProps>(), {
  show: false,
})
const emit = defineEmits([ModalEvents.CLOSE_MODAL])

const handleClose = () => {
  emit(ModalEvents.CLOSE_MODAL)
}
</script>

<template>
  <CustomModal
    :show="props.show"
    :title="$t('validations.required.emailMissingField')"
    :description="$t('validations.required.emailAddressForRecipient')"
    @on-success="handleClose"
    @on-cancel="handleClose"
    modalType="error"
    success-action-text="OK"
  ></CustomModal>
</template>
