<script setup lang="ts">
import { UIInput, UISelect } from '@gohighlevel/ghl-ui'
import CustomModal from '@/components/common/CustomModal.vue'
import { countries } from '@/util/countries'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { onMounted, ref, computed, isProxy, toRaw } from 'vue'
import {
  CreateContactParams,
  APP_STORE_META,
  getRecipientParamsFromContact,
  upgradeRecipientToPrimarySigner,
} from '@gohighlevel/ghl-proposals-common'
import { useContact } from '@/composition'
import { useAppStore } from '@/store/app'
import { useElementOption } from '@/composable/elementOptions'
import { useActionBarStore } from '@/store/actionBar'
import { useEmitter } from '@/composable/useEmitter'
import { formatPhoneNumber } from '@gohighlevel/ghl-proposals-common'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { createContact } = useContact()
const emitter = useEmitter()
const appStore = useAppStore()
const { updateDocumentOption } = useElementOption()
const storeAction = useActionBarStore()
const recipients = computed(() => appStore.document?.recipients)
const showActionConfirmationModal = ref<boolean>(false)
const actionModalTitle = ref(t('common.addNewClient'))
const countryList = ref<any>([])
const errMsg = ref('')
const loading = ref(false)
const closeOnSuccess = ref(false)

onMounted(() => {
  countriesOptions()
  emitter.on('open-create-contact-model', () => {
    showActionConfirmationModal.value = true
    closeOnSuccess.value = false
    loading.value = false
    errMsg.value = ''
    resetForm()
  })
})

const cancelActionModal = () => {
  loading.value = false
  showActionConfirmationModal.value = false
  closeOnSuccess.value = true
}

const countriesOptions = () => {
  const list = []
  for (const key in countries) {
    list.push({
      value: key,
      label: countries[key],
    })
  }
  countryList.value = list
}

const formatPhoneNum = () => {
  errMsg.value = ''
  try {
    if (phone.value && phone.value.length > 0 && countryCode.value) {
      phone.value = formatPhoneNumber(countryCode.value, phone.value)
    }
  } catch (err) {
    errMsg.value = t('validations.invalid.phoneNumber')
  }
}

const getFormErrors = (validateContact: any) => {
  const error: string[] = []
  const errors = validateContact?.errors || {}
  Object.keys(errors).forEach(elem => {
    error.push(errors[elem])
  })
  return error.join(', ')
}

const contactSchema = yup.object({
  firstName: yup.string().required(t('validations.required.firstName')),
  lastName: yup.string().required(t('validations.required.lastName')),
  email: yup.string().email().required(t('validations.required.email')),
  phone: yup.string().optional(),
  address1: yup.string().optional(),
  city: yup.string().optional(),
  state: yup.string().optional(),
  countryCode: yup.string().optional(),
  postalCode: yup.string().optional(),
})

const { errors, validate, resetForm } = useForm({
  validationSchema: contactSchema,
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    city: '',
    state: '',
    countryCode: 'US',
    postalCode: '',
  },
})

const { value: firstName } = useField<string>('firstName')
const { value: lastName } = useField<string>('lastName')
const { value: email } = useField<string>('email')
const { value: phone } = useField<string>('phone')
const { value: address1 } = useField<string>('address1')
const { value: city } = useField<string>('city')
const { value: countryCode } = useField<string>('countryCode')
const { value: postalCode } = useField<string>('postalCode')

async function onSuccessFullAction() {
  loading.value = true
  errMsg.value = ''
  closeOnSuccess.value = false
  const validateContact = await validate()
  // do not change showActionConfirmationModal value from here
  // validate is closing the model
  showActionConfirmationModal.value = true

  if (!validateContact.valid) {
    const errorMessage = getFormErrors(validateContact)
    errMsg.value = t('validations.invalid.contactDetails') + errorMessage
    loading.value = false
    return
  }

  const contactParams: CreateContactParams = {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phone: phone.value,
    address1: address1.value,
    city: city.value,
    postalCode: postalCode.value,
    locationId: appStore.document?.locationId,
    country: countryCode.value,
  }
  const response = await createContact(contactParams)

  if (response && response.contact) {
    const contact = response.contact
    const contactDetails = getRecipientParamsFromContact(contact)
    contactDetails.contactName = `${
      contactDetails.firstName.toLowerCase().trim() || ''
    } ${contactDetails.lastName.toLowerCase().trim() || ''}`.trim()

    const duplicateContacts = recipients.value.find((el: any) => {
      return el.id === contactDetails.id
    })

    if (!duplicateContacts) {
      if (isProxy(recipients)) {
        const recipientRawList = toRaw(recipients.value)
        const primaryRecipient = recipientRawList.find(elem => {
          return elem.isPrimary === true
        })
        //If new client was added without any primary user selected previously then add the new client as the primary client
        if (!primaryRecipient) {
          const primaryContactDetails =
            upgradeRecipientToPrimarySigner(contactDetails)
          const allRecipients = [...recipientRawList, primaryContactDetails]
          updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
          storeAction.recipientsActive = true
        } else {
          const allRecipients = [...recipientRawList, contactDetails]
          updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
          storeAction.recipientsActive = true
        }
      }
    }

    // Emit the event to update new client in recipient dropdown
    emitter.emit('contact-added', contactDetails)

    cancelActionModal()
  } else {
    showActionConfirmationModal.value = true
    loading.value = false
    return
  }
}
</script>

<template>
  <CustomModal
    :closeOnSuccess="closeOnSuccess"
    :loading="loading"
    :show="showActionConfirmationModal"
    :success-action-text="$t('common.save')"
    :cancel-action-text="$t('common.cancel')"
    :title="actionModalTitle"
    @on-cancel="cancelActionModal"
    @on-success="onSuccessFullAction"
  >
    <div class="mt-4">
      <div class="space-y-2">
        <div class="options flex space-x-2">
          <div class="flex-grow">
            <div class="text-gray-500 text-sm">
              {{ $t('common.firstName') }}
            </div>
            <UIInput
              id="firstName"
              v-model="firstName"
              name="firstName"
              :placeholder="$t('common.firstName')"
              :error="!!errors.firstName"
            />
          </div>
          <div class="flex-grow">
            <div class="text-gray-500 text-sm">{{ $t('common.lastName') }}</div>
            <UIInput
              id="lastName"
              v-model="lastName"
              name="lastName"
              :placeholder="$t('common.lastName')"
              :error="!!errors.lastName"
            />
          </div>
        </div>
        <div class="flex space-x-2">
          <div class="flex-grow">
            <div class="text-gray-500 text-sm">
              {{ $t('common.emailAddress') }}
            </div>
            <UIInput
              id="email"
              v-model="email"
              name="email"
              :placeholder="$t('common.emailAddress')"
              :error="!!errors.email"
            />
          </div>
          <div class="flex-grow">
            <div class="text-gray-500 text-sm">
              {{ $t('common.phoneNumber') }}
            </div>
            <UIInput
              id="phone"
              v-model="phone"
              name="phoneNo"
              :placeholder="$t('common.phoneNumber')"
              :error="!!errors.phone"
              @keypress="formatPhoneNum()"
            />
          </div>
        </div>
        <div>
          <div class="text-gray-500 text-sm">{{ $t('common.address') }}</div>
          <UIInput
            id="address1"
            v-model="address1"
            name="address1"
            :placeholder="$t('common.address')"
            :error="!!errors.address1"
          />
        </div>

        <div class="flex space-x-2">
          <UIInput
            id="city"
            v-model="city"
            name="city"
            :placeholder="$t('common.city')"
            :error="!!errors.city"
          />

          <UISelect
            id="select-countryCode"
            v-model:value="countryCode"
            :options="countryList"
            :placeholder="$t('common.country')"
          />

          <UIInput
            id="zipCode"
            v-model="postalCode"
            name="zipcode"
            :placeholder="$t('common.zipCode')"
            :error="!!errors.postalCode"
          />
        </div>
        <div v-if="errMsg" class="text-red-500 text-sm pt-3">
          {{ errMsg }}
        </div>
      </div>
    </div>
  </CustomModal>
</template>
