export enum TimeZoneEnum {
  'Etc/GMT+12' = 'Etc/GMT+12',
  'Pacific/Midway' = 'Pacific/Midway',
  'Pacific/Honolulu' = 'Pacific/Honolulu',
  'America/Juneau' = 'America/Juneau',
  'US/Alaska' = 'US/Alaska',
  'America/Dawson' = 'America/Dawson',
  'America/Los_Angeles' = 'America/Los_Angeles',
  'America/Phoenix' = 'America/Phoenix',
  'America/Tijuana' = 'America/Tijuana',
  'US/Arizona' = 'US/Arizona',
  'America/Belize' = 'America/Belize',
  'America/Boise' = 'America/Boise',
  'America/Chihuahua' = 'America/Chihuahua',
  'America/Denver' = 'America/Denver',
  'America/Edmonton' = 'America/Edmonton',
  'America/Guatemala' = 'America/Guatemala',
  'America/Managua' = 'America/Managua',
  'America/Regina' = 'America/Regina',
  'Canada/Saskatchewan' = 'Canada/Saskatchewan',
  'US/Mountain' = 'US/Mountain',
  'America/Bahia_Banderas' = 'America/Bahia_Banderas',
  'America/Bogota' = 'America/Bogota',
  'America/Cancun' = 'America/Cancun',
  'America/Chicago' = 'America/Chicago',
  'America/Mexico_City' = 'America/Mexico_City',
  'US/Central' = 'US/Central',
  'America/Caracas' = 'America/Caracas',
  'America/Detroit' = 'America/Detroit',
  'America/Indiana/Indianapolis' = 'America/Indiana/Indianapolis',
  'America/Louisville' = 'America/Louisville',
  'America/Manaus' = 'America/Manaus',
  'America/New_York' = 'America/New_York',
  'America/Santiago' = 'America/Santiago',
  'America/Santo_Domingo' = 'America/Santo_Domingo',
  'America/Toronto' = 'America/Toronto',
  'US/East-Indiana' = 'US/East-Indiana',
  'US/Eastern' = 'US/Eastern',
  'America/Argentina/Buenos_Aires' = 'America/Argentina/Buenos_Aires',
  'America/Glace_Bay' = 'America/Glace_Bay',
  'America/Montevideo' = 'America/Montevideo',
  'America/Sao_Paulo' = 'America/Sao_Paulo',
  'Canada/Atlantic' = 'Canada/Atlantic',
  'America/St_Johns' = 'America/St_Johns',
  'Canada/Newfoundland' = 'Canada/Newfoundland',
  'America/Godthab' = 'America/Godthab',
  'America/Noronha' = 'America/Noronha',
  'Etc/GMT+2' = 'Etc/GMT+2',
  'Atlantic/Cape_Verde' = 'Atlantic/Cape_Verde',
  'Atlantic/Azores' = 'Atlantic/Azores',
  'UTC' = 'UTC',
  'Africa/Algiers' = 'Africa/Algiers',
  'Africa/Casablanca' = 'Africa/Casablanca',
  'Africa/Lagos' = 'Africa/Lagos',
  'Atlantic/Canary' = 'Atlantic/Canary',
  'Europe/London' = 'Europe/London',
  'Africa/Cairo' = 'Africa/Cairo',
  'Africa/Harare' = 'Africa/Harare',
  'Europe/Amsterdam' = 'Europe/Amsterdam',
  'Europe/Belgrade' = 'Europe/Belgrade',
  'Europe/Brussels' = 'Europe/Brussels',
  'Europe/Madrid' = 'Europe/Madrid',
  'Europe/Oslo' = 'Europe/Oslo',
  'Europe/Sarajevo' = 'Europe/Sarajevo',
  'Africa/Nairobi' = 'Africa/Nairobi',
  'Asia/Amman' = 'Asia/Amman',
  'Asia/Baghdad' = 'Asia/Baghdad',
  'Asia/Jerusalem' = 'Asia/Jerusalem',
  'Asia/Kuwait' = 'Asia/Kuwait',
  'Asia/Qatar' = 'Asia/Qatar',
  'Europe/Athens' = 'Europe/Athens',
  'Europe/Bucharest' = 'Europe/Bucharest',
  'Europe/Helsinki' = 'Europe/Helsinki',
  'Europe/Moscow' = 'Europe/Moscow',
  'Asia/Baku' = 'Asia/Baku',
  'Asia/Dubai' = 'Asia/Dubai',
  'Asia/Kabul' = 'Asia/Kabul',
  'Asia/Tehran' = 'Asia/Tehran',
  'Asia/Karachi' = 'Asia/Karachi',
  'Asia/Yekaterinburg' = 'Asia/Yekaterinburg',
  'Asia/Colombo' = 'Asia/Colombo',
  'Asia/Kolkata' = 'Asia/Kolkata',
  'Asia/Calcutta' = 'Asia/Calcutta',
  'Asia/Kathmandu' = 'Asia/Kathmandu',
  'Asia/Almaty' = 'Asia/Almaty',
  'Asia/Dhaka' = 'Asia/Dhaka',
  'Asia/Rangoon' = 'Asia/Rangoon',
  'Asia/Bangkok' = 'Asia/Bangkok',
  'Asia/Krasnoyarsk' = 'Asia/Krasnoyarsk',
  'Asia/Irkutsk' = 'Asia/Irkutsk',
  'Asia/Kuala_Lumpur' = 'Asia/Kuala_Lumpur',
  'Asia/Shanghai' = 'Asia/Shanghai',
  'Asia/Taipei' = 'Asia/Taipei',
  'Australia/Perth' = 'Australia/Perth',
  'Asia/Seoul' = 'Asia/Seoul',
  'Asia/Tokyo' = 'Asia/Tokyo',
  'Asia/Yakutsk' = 'Asia/Yakutsk',
  'Australia/Adelaide' = 'Australia/Adelaide',
  'Australia/Darwin' = 'Australia/Darwin',
  'Asia/Vladivostok' = 'Asia/Vladivostok',
  'Australia/Brisbane' = 'Australia/Brisbane',
  'Australia/Canberra' = 'Australia/Canberra',
  'Australia/Hobart' = 'Australia/Hobart',
  'Australia/Sydney' = 'Australia/Sydney',
  'Pacific/Guam' = 'Pacific/Guam',
  'Asia/Magadan' = 'Asia/Magadan',
  'Pacific/Auckland' = 'Pacific/Auckland',
  'Pacific/Fiji' = 'Pacific/Fiji',
  'Pacific/Tongatapu' = 'Pacific/Tongatapu',
}
