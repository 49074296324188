<script setup lang="ts" generic="T = any,U = any">
import { PropType } from 'vue'
import { provide } from 'vue'

const props = defineProps({
  storeMap: {
    type: Object as PropType<T>,
    required: true,
  },
  compositionMap: {
    type: Object as PropType<U>,
    required: true,
  },
})
provide<T, 'storeMap'>('storeMap', props.storeMap as T)
provide<U, 'compositionMap'>('compositionMap', props.compositionMap as U)
</script>

<template>
  <slot></slot>
</template>
