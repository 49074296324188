<script lang="ts" setup>
import { Ref, ref } from 'vue'
import {
  UIDatepicker,
  UIFormItem,
  UIInput,
  UIInputNumber,
} from '@gohighlevel/ghl-ui'
import { formatDate } from '../../utils'

interface EditFieldProps {
  value: string | number
  onUpdateValue: (value: string | number) => void
  onInputFocus?: (isFocused: boolean) => void
  name: string
  align?: 'left' | 'right' | 'center'
  isCurrency?: boolean
  isDate?: boolean
  isNumber?: boolean
  validateOn?: string[]
  validator: (value: string | number) => Error | null
  dateProps?: {
    disablePreviousDate?: (date: number) => boolean
  }
  editable?: boolean
  id: string
  row?: any
  formatCurrency?: (value: number) => string
}

const props = withDefaults(defineProps<EditFieldProps>(), {
  align: 'left',
  value: '',
  isCurrency: false,
  editable: false,
})
const isEdit = ref(false)
const inputValue: Ref<string | number> = ref(props.value)
const inputRef = ref<HTMLInputElement>({} as HTMLInputElement)
const hasError = ref(false)

const handleOnClick = (e: MouseEvent) => {
  e?.stopImmediatePropagation()
  props?.onInputFocus && props?.onInputFocus(true)
  isEdit.value = true
}

const handleChange = (value: string) => {
  inputValue.value = value
  props?.onUpdateValue(inputValue.value)
  props?.onInputFocus && props?.onInputFocus(true)
}
const handleNumberChange = (value: number) => {
  inputValue.value = value
  props?.onUpdateValue(inputValue.value)
}

const onBlur = () => {
  props?.onUpdateValue(inputValue.value)
  props?.onInputFocus && props?.onInputFocus(false)
  isEdit.value = false
}

const validator = {
  trigger: props.validateOn,
  validator() {
    const error = props.validator(inputValue.value)
    hasError.value = error instanceof Error
    return error
  },
}

const dateValidators = {
  disablePreviousDate: props?.dateProps?.disablePreviousDate,
}
const getValue = () => {
  if (props.isDate) {
    return formatDate(props.value, 'LL')
  }
  if (props.isCurrency) {
    return props.formatCurrency
      ? props.formatCurrency(props.value as number)
      : props.value
  }
  return props.value
}
</script>

<template>
  <div
    @click="handleOnClick"
    :style="{
      maxWidth: '90%',
      minHeight: '22px',
      textAlign: props.align,
    }"
  >
    <UIFormItem
      v-if="isEdit && editable"
      :rule="validator"
      :showLabel="false"
      :showFeedback="false"
      :class="{
        'editable-table-input-form-item': true,
      }"
    >
      <UIDatepicker
        v-if="props.isDate && isEdit"
        :id="props.id"
        ref="inputRef"
        :value="inputValue as number"
        :is-date-disabled="dateValidators.disablePreviousDate"
        @blur="onBlur"
        @update:value="handleChange"
        :clearable="false"
        size="medium"
      />
      <UIInputNumber
        v-if="props.isNumber"
        :id="props.id"
        ref="inputRef"
        :modelValue="inputValue as number"
        @update:modelValue="handleNumberChange"
        @blur="onBlur"
        class="rounded-md focused"
        size="medium"
        :show-button="false"
        :step="1"
        :min="0"
        :max="999999999"
      />
      <UIInput
        v-else
        :id="props.id"
        ref="inputRef"
        type="textarea"
        :modelValue="inputValue as string"
        @update:modelValue="handleChange"
        @blur="onBlur"
        class="rounded-md"
        size="medium"
        :clearable="false"
        :autosize="true"
      />
    </UIFormItem>
    <UIFormItem
      v-else
      :showLabel="false"
      :showFeedback="false"
      class="w-full block hover:outline-1 hover-outline-primary-600 editable-table-input-form-item editable-table-input-form-item--readOnly"
    >
      <UIInput
        ref="inputRef"
        :id="`disabled-${props.id}`"
        :value="getValue()"
        type="textarea"
        class="font-medium rounded-md"
        size="medium"
        :clearable="false"
        :autosize="true"
        :readonly="true"
      />
    </UIFormItem>
  </div>
</template>

<style lang="scss">
.editable-table-input-form-item {
  .n-form-item-blank {
    .hl-input-text {
      .n-input-wrapper {
        .n-input__suffix {
          display: none !important;
        }
      }
    }
  }
  &--readOnly {
    .n-form-item-blank {
      .hl-input-text {
        box-shadow: none !important;
        color: #333 !important;
        .n-input__border {
          border: 1px solid transparent !important;
        }
      }
    }
  }
}
</style>
