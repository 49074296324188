<script setup lang="ts">
import { useActionBarStore } from '@/store/actionBar'
import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'
import { UIDrawer, UIDrawerContent, UITooltip } from '@gohighlevel/ghl-ui'
import { Switch } from '@gohighlevel/ghl-proposals-common'
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import { computed } from 'vue'

const store = useBuilderStore()
const appStore = useAppStore()
const storeAction = useActionBarStore()
function onClose() {
  storeAction.togglePaymentSettings()
}
const isInternal = computed(() => appStore.isInternal)

const onDirectPaymentChange = (value: boolean) => {
  appStore.toggleDirectPayment(value)
}
const onAutoSendInvoiceChange = (value: boolean) => {
  appStore.toggleAutoSendInvoice(value)
}
const onPaymentLiveModeChange = (value: boolean) => {
  appStore.togglePaymentLiveMode(value)
}
</script>

<template>
  <UIDrawer
    :width="340"
    id="payment-settings-drawer"
    :show="storeAction.paymentSettings"
    @update:show="onClose"
    :placement="'left'"
    to="#drawer-target"
    class="!shadow-page h-screen"
  >
    <UIDrawerContent
      id="payment-settings-drawer-content"
      :title="$t('common.paymentSettings')"
    >
      <div class="relative">
        <Switch
          :value="appStore.document.enableDirectPayment as boolean"
          :label="$t('common.enableDirectPayment')"
          :infoLabel="$t('common.enableDirectPaymentDescription')"
          @update:value="onDirectPaymentChange"
          :disabled="!store.isDocumentEditable"
          class="mb-8"
        ></Switch>
        <div class="absolute top-0 left-[72%]">
          <UITooltip
            :content="$t('common.enableDirectPaymentDescription')"
            :placement="'top'"
          >
            <template #trigger>
              <InfoCircleIcon class="w-4 h-4 text-gray-500" />
            </template>
            {{ $t('common.enableDirectPaymentWarning') }}
          </UITooltip>
        </div>
      </div>
      <Switch
        :value="appStore.document.enableAutoSendInvoice as boolean"
        :label="$t('common.enableSendInvoice')"
        :infoLabel="$t('common.enableSendInvoiceDescription')"
        @update:value="onAutoSendInvoiceChange"
        :disabled="
          appStore.document.enableDirectPayment || !store.isDocumentEditable
        "
      />

      <Switch
        class="mt-8"
        v-if="isInternal"
        :value="appStore.document.paymentLiveMode as boolean"
        :label="$t('common.paymentLiveMode')"
        :infoLabel="$t('common.enablePaymentLiveModeDescription')"
        @update:value="onPaymentLiveModeChange"
        :disabled="!store.isDocumentEditable"
      />
    </UIDrawerContent>
  </UIDrawer>
</template>
