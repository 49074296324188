<script setup lang="ts">
import ImageElementIcon from '@/assets/icons/svg/image-element.svg'
import PageBreakElementIcon from '@/assets/icons/svg/pagebreak-element.svg'
import ProductElementIcon from '@/assets/icons/svg/product-element.svg'
import TableElementIcon from '@/assets/icons/svg/table-element.svg'
import TextElementIcon from '@/assets/icons/svg/text-element.svg'
import VideoElementIcon from '@/assets/icons/svg/video-element.svg'
import { ELEMENTS_LOOKUP } from '@gohighlevel/ghl-proposals-common'
import { UIDraggableElement } from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const elements = [
  {
    icon: TextElementIcon,
    title: t('common.text'),
    type: ELEMENTS_LOOKUP.TEXT,
  },
  {
    icon: ImageElementIcon,
    title: t('common.image'),
    type: ELEMENTS_LOOKUP.IMAGE,
  },
  {
    icon: VideoElementIcon,
    title: t('common.video'),
    type: ELEMENTS_LOOKUP.VIDEO,
  },
  {
    icon: TableElementIcon,
    title: t('common.table'),
    type: ELEMENTS_LOOKUP.TABLE,
  },
  {
    icon: ProductElementIcon,
    title: t('common.productList'),
    type: ELEMENTS_LOOKUP.PRODUCT_LIST,
  },
  {
    icon: PageBreakElementIcon,
    title: t('common.pageBreak'),
    type: ELEMENTS_LOOKUP.PAGE_BREAK,
  },
]
</script>

<template>
  <div style="position: fixed; left: 0px">
    <div style="margin-left: 0px" class="flex flex-row">
      <span
        v-for="(element, indx) in elements"
        :key="indx"
        draggable="true"
        style="width: 121px; height: 121px; border-radius: 4px"
        :id="`draggable-element-${element.type}`"
      >
        <UIDraggableElement :label="element.title" :id="`c-${indx}`">
          <template #icon>
            <component :is="element.icon" class="h-[48px]" />
          </template>
        </UIDraggableElement>
      </span>
    </div>
  </div>
</template>
