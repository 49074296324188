<script lang="ts" setup>
import ImageElementIcon from '@/assets/icons/svg/image-element.svg'
import PageBreakElementIcon from '@/assets/icons/svg/pagebreak-element.svg'
import ProductElementIcon from '@/assets/icons/svg/product-element.svg'
import TableElementIcon from '@/assets/icons/svg/table-element.svg'
import TextElementIcon from '@/assets/icons/svg/text-element.svg'
import VideoElementIcon from '@/assets/icons/svg/video-element.svg'
import { useActionBarStore } from '@/store/actionBar'
import {
  UIDraggableElement,
  UIDrawer,
  UIDrawerContent,
  UITextXsMedium,
} from '@gohighlevel/ghl-ui'
import {
  ELEMENTS_LOOKUP,
  SignatureFieldElementSVG,
  TextFieldElementSVG,
  InitialsElementSVG,
  CheckBoxElementSVG,
} from '@gohighlevel/ghl-proposals-common'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarDateIcon } from '@gohighlevel/ghl-icons/24/outline'

const { t } = useI18n()
const elements = [
  {
    icon: TextElementIcon,
    title: t('common.text'),
    type: ELEMENTS_LOOKUP.TEXT,
  },
  {
    icon: ImageElementIcon,
    title: t('common.image'),
    type: ELEMENTS_LOOKUP.IMAGE,
  },
  {
    icon: VideoElementIcon,
    title: t('common.video'),
    type: ELEMENTS_LOOKUP.VIDEO,
  },
  {
    icon: TableElementIcon,
    title: t('common.table'),
    type: ELEMENTS_LOOKUP.TABLE,
  },
  {
    icon: ProductElementIcon,
    title: t('common.productList'),
    type: ELEMENTS_LOOKUP.PRODUCT_LIST,
  },
  {
    icon: PageBreakElementIcon,
    title: t('common.pageBreak'),
    type: ELEMENTS_LOOKUP.PAGE_BREAK,
  },
  {
    icon: SignatureFieldElementSVG,
    title: t('common.signature'),
    type: ELEMENTS_LOOKUP.SIGNATURE,
    isDraggable: true,
  },
  {
    icon: TextFieldElementSVG,
    title: t('common.textField'),
    type: ELEMENTS_LOOKUP.TEXT_FIELD,
    isDraggable: true,
  },
  {
    icon: CalendarDateIcon,
    title: 'Date',
    type: ELEMENTS_LOOKUP.DATE_FIELD,
    isDraggable: true,
  },
  {
    icon: InitialsElementSVG,
    title: 'Initials',
    type: ELEMENTS_LOOKUP.INITIALS_FIELD,
    isDraggable: true,
  },
  {
    icon: CheckBoxElementSVG,
    title: 'Checkbox',
    type: ELEMENTS_LOOKUP.CHECKBOX,
    isDraggable: true,
  },
]
const elementsWithoutDrag = computed(() =>
  elements.filter(({ isDraggable }) => !isDraggable)
)
const elementsWithDrag = computed(() =>
  elements.filter(({ isDraggable }) => isDraggable)
)
const store = useActionBarStore()

function onStart() {
  store.toggleElements()
  store.toggleDragState(true)
}

function onEnd() {
  store.toggleDragState(false)
  store.setFloatingElementDragging(false)
}

const startDrag = (evt: any, item: any, isFloatingElement = false) => {
  store.toggleElements()
  store.toggleDragState(true)
  if (isFloatingElement) {
    store.setFloatingElementDragging(true)
    evt.dataTransfer.setData('floatingElement', true)
  }
  evt.dataTransfer.dropEffect = 'move'
  evt.dataTransfer.effectAllowed = 'move'
  evt.dataTransfer.setData('name', item.type)
  evt.dataTransfer.setData('action', 'add')
  evt.dataTransfer.setDragImage(evt.target, 0, 0)
}
</script>

<template>
  <div class="absolute">
    <UIDrawer
      :trap-focus="false"
      :width="440"
      id="elements-drawer"
      :show="store.elementsActive"
      :placement="'left'"
      to="#drawer-target"
      displayDirective="show"
      @update:show="onStart"
      class="!shadow-page h-screen"
    >
      <UIDrawerContent
        id="elements-drawer-content"
        :title="$t('common.addAnElement')"
      >
        <template #footer></template>
        <div>
          <UITextXsMedium class="text-grey mb-2">
            {{ $t('proposals.blocks') }}</UITextXsMedium
          >
          <div class="grid gap-2 grid-cols-3">
            <span
              @dragstart="startDrag($event, element)"
              @dragend="onEnd()"
              v-for="(element, indx) in elementsWithoutDrag"
              :key="indx"
              draggable="true"
              ref="items"
              style="width: 121px; height: 121px; border-radius: 4px"
            >
              <UIDraggableElement :label="element.title" :id="`c-${indx}`">
                <template #icon>
                  <component :is="element.icon" class="h-[48px]" />
                </template>
              </UIDraggableElement>
            </span>
          </div>
        </div>
        <div class="mt-8">
          <UITextXsMedium class="text-grey mb-2">
            {{ $t('proposals.fillableFields') }}</UITextXsMedium
          >
          <div class="grid gap-2 grid-cols-3">
            <span
              class="fillable-fields"
              v-for="(element, indx) in elementsWithDrag"
              :key="indx"
              :draggable="element.isDraggable"
              @dragstart="startDrag($event, element, true)"
              @dragend="onEnd()"
              style="width: 121px; height: 121px; border-radius: 4px"
            >
              <UIDraggableElement
                :label="element.title"
                :id="`c-${indx}-draggable`"
              >
                <template #icon>
                  <component
                    :is="element.icon"
                    class="h-[48px] text-primary-700"
                  />
                </template>
              </UIDraggableElement>
            </span>
          </div>
        </div>
      </UIDrawerContent>
    </UIDrawer>
  </div>
</template>

<style lang="scss">
.fillable-fields {
  .gui__builder-card--label {
    color: var(--primary-700);
  }
  .gui__builder-card--icon {
    svg {
      stroke-width: 1;
    }
  }
}
.n-drawer-mask {
  display: none !important;
}

.elements-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  margin-left: 24px;
}

.elements-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
