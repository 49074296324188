<script setup lang="ts"></script>

<template>
  <div class="page-break--el">{{ $t('common.pageBreak') }}</div>
</template>

<style>
.page-break-el {
  height: 0;
  display: none;
}
</style>
