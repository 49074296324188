/**
 * Throttles the execution of a function.
 * @param func The function to be throttled.
 * @param limit The time limit (in milliseconds) between function invocations.
 * @returns A throttled version of the original function.
 */
export function throttle<T extends (...args: any[]) => any>(
  func: T,
  limit: number
) {
  let lastFunc: ReturnType<typeof setTimeout> | undefined
  let lastRan: number | undefined

  return function (this: any, ...args: Parameters<T>) {
    if (!lastRan) {
      func.apply(this, args)
      lastRan = Date.now()
    } else {
      if (lastFunc) {
        clearTimeout(lastFunc)
      }
      lastFunc = setTimeout(() => {
        func.apply(this, args)
        lastRan = Date.now()
      }, limit - (Date.now() - lastRan))
    }
  }
}
