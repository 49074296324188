<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { PlusIcon, User01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { getInitials } from '@gohighlevel/ghl-ui/src/utils/get-initials'
import {
  UIAvatar,
  UIButton,
  UIInput,
  UITextXsRegular,
} from '@gohighlevel/ghl-ui'
import { computed, isProxy, onMounted, ref, toRaw, watch } from 'vue'
import { useContact } from '@/composition'
import {
  IRecipient,
  APP_STORE_META,
  useTypography,
  convertToRecipientOptions,
  getRecipientParamsFromContact,
} from '@gohighlevel/ghl-proposals-common'
import { useElementOption } from '@/composable/elementOptions'
import { useAppStore } from '@/store/app'
import { useActionBarStore } from '@/store/actionBar'
import { useEmitter } from '@/composable/useEmitter'
import { useBuilderStore } from '@/store/builder'
import { getColorFromString } from '@gohighlevel/ghl-ui/src/utils/get-color-from-string'
import { debounce } from '@/util/debounce'

const appStore = useAppStore()
const storeAction = useActionBarStore()
const { searchContacts } = useContact()
const { updateDocumentOption } = useElementOption()
const { toCapitalize } = useTypography()
const store = useBuilderStore()
const emitter = useEmitter()
const search = ref('')
const contactListRef = ref()
const pageLimit = ref(20)
const contacts = ref<any>([])
const recipients = computed(() => appStore.document?.recipients)
const pageNum = ref(1)

const isPrimaryRecipientExist = computed(() => {
  const primaryRecipient = appStore.document?.recipients.find(elem => {
    return elem.isPrimary === true
  })
  return primaryRecipient ? true : false
})

const fetchContacts = async (searchStr: string, isSearch = false) => {
  const response = await searchContacts(
    searchStr,
    pageLimit.value,
    pageNum.value
  )
  const contactResponse = convertToRecipientOptions(
    response?.contacts as unknown as IRecipient[]
  )

  if (contactResponse.length > 0) {
    if (isSearch) {
      contacts.value = contactResponse
    } else {
      contacts.value = contacts.value.concat(contactResponse)
    }
  }
}

watch(search, searchValue => {
  if (searchValue) {
    pageNum.value = 1
    contacts.value = []
    debounce(fetchContacts, 500)(search.value, true)
  }
})

const openPopover = () => {
  attachScrollEvent()
}

const attachScrollEvent = () => {
  setTimeout(function () {
    const element = document.getElementById('contactsList')
    if (element) {
      element.addEventListener('scroll', () => {
        if (
          element.scrollTop + element.clientHeight >=
          element.scrollHeight - 1
        ) {
          pageNum.value = pageNum.value + 1
          fetchContacts(search.value)
        }
      })
    }
  }, 1000)
}

const onClickContact = async (contact: any, close: any) => {
  if (!contact.email && !contact.phone) {
    return
  }
  close()

  const contactDetails = getRecipientParamsFromContact(contact)
  const duplicateContacts = recipients.value.find((el: any) => {
    return (
      el.id === contactDetails.id && el.entityName === contactDetails.entityName
    )
  })
  if (!duplicateContacts) {
    if (isProxy(recipients)) {
      const recipientRawObject = toRaw(recipients.value)
      const allRecipients = [...recipientRawObject, contactDetails]
      updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
      storeAction.recipientsActive = true
    }
  }
}

onMounted(() => {
  fetchContacts(search.value)
})

const onClickNewItem = () => {
  emitter.emit('open-create-contact-model')
}
</script>

<template>
  <div class="w-full">
    <Popover v-slot="{ open }">
      <div class="flex items-center justify-center">
        <PopoverButton
          :as="UIButton"
          v-if="isPrimaryRecipientExist && store.isDocumentEditable"
          @click="openPopover()"
          class="mt-2"
        >
          <PlusIcon
            :class="open ? '' : 'text-opacity-70'"
            class="w-5 h-5 mr-2"
            aria-hidden="true"
          />
          <span>{{ $t('common.addMoreRecipients') }}</span>
        </PopoverButton>
      </div>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 sm:px-0 lg:max-w-3xl flex justify-end top-[52px] left-[-42px] border-1"
          :focus="true"
          v-slot="{ close }"
        >
          <div
            class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 max-w-lg"
          >
            <div
              class="absolute bg-white p-4 pb-0 shadow-page rounded-tr-md rounded-br-md"
            >
              <div class="w-72">
                <UIInput
                  v-model="search"
                  placeholder="Search Client"
                  id="search-client-input"
                />
              </div>

              <div class="flex justify-between align-center py-4">
                <UITextXsRegular class="uppercase">{{
                  $t('common.listOfAllClients')
                }}</UITextXsRegular>

                <UIButton
                  id="addNewClient"
                  @click="onClickNewItem"
                  :text="true"
                >
                  {{ $t('common.addNewClient') }}
                </UIButton>
              </div>

              <div
                class="h-80 -ml-4 -mr-4 border-t overflow-auto"
                ref="contactListRef"
                id="contactsList"
              >
                <div class="h-80">
                  <div
                    v-for="(contact, index) in contacts"
                    :key="contact.id"
                    class="flex p-2 cursor-pointer"
                    @click="onClickContact(contact, close)"
                    :class="{
                      'pointer-events-none opacity-50 cursor-not-allowed':
                        !contact.email,
                      'border-b': index !== contact.length - 1,
                    }"
                  >
                    <div class="w-10 mr-4">
                      <UIAvatar
                        v-if="contact?.contactName"
                        :style="{
                          backgroundColor: getColorFromString(
                            toCapitalize(
                              contact?.contactName ||
                                `${contact.firstName} ${contact.lastName}`
                            ).trim()
                          ),
                        }"
                      >
                        {{ getInitials(contact?.contactName) }}
                      </UIAvatar>
                      <UIAvatar v-else>
                        <User01Icon class="w-5 h-5" />
                      </UIAvatar>
                    </div>
                    <div class="flex flex-col">
                      <div class="text-sm w-40">
                        <div
                          class="text-gray-700 font-semibold truncate capitalize"
                        >
                          {{ contact?.contactName }}
                        </div>
                      </div>
                      <div class="flex items-end text-gray-500 font-light">
                        <UITextXsRegular>
                          {{ contact?.email }}
                        </UITextXsRegular>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
