import { PaymentService } from '@/service/PaymentService'
import { useProductListStore } from '@/store/productListStore'
import { ref } from 'vue'

const loading = ref(false)
export const usePayment = () => {
  const getCurrencyDetails = async () => {
    const productListStore = useProductListStore()
    try {
      loading.value = true
      if (Object.keys(productListStore.currencyDetails).length === 0) {
        const { data } = await PaymentService.getCurrencyDetails()
        if (data && data.currency) {
          productListStore.setCurrencyDetails(data)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  return {
    getCurrencyDetails,
  }
}
