<script setup lang="ts">
import { Direction, throttle } from '@gohighlevel/ghl-proposals-common'
import { computed, ref } from 'vue'
const props = defineProps({
  rowIndex: {
    type: Number,
    required: true,
  },
  colIndex: {
    type: Number,
    required: true,
  },
  pageId: {
    type: String,
    required: true,
  },
  isLastRow: {
    type: Boolean,
    required: false,
    default: false,
  },
  isNested: {
    type: Boolean,
    required: false,
    default: false,
  },
  rowColIndex: {
    type: Number,
    required: false,
    default: -1,
  },
  top: {
    type: Boolean,
    required: false,
    default: true,
  },
  bottom: {
    type: Boolean,
    required: false,
    default: true,
  },
  left: {
    type: Boolean,
    required: false,
    default: true,
  },
  right: {
    type: Boolean,
    required: false,
    default: true,
  },
  topAbleClassName: {
    type: String,
    required: false,
    default: '',
  },
  bottomAbleClassName: {
    type: String,
    required: false,
    default: '',
  },
  leftAbleClassName: {
    type: String,
    required: false,
    default: '',
  },
  rightAbleClassName: {
    type: String,
    required: false,
    default: '',
  },
  dragEnabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  rowId: {
    type: String,
    required: false,
  },
})
const emits = defineEmits([
  'onDragOver',
  'onDragExit',
  'onDropElement',
  'onDragEnter',
])

const draggingOver = ref(false)
const direction = ref<Direction | null>()

const onDragOver = (ev: any, dir: Direction) => {
  ev.preventDefault()

  if (draggingOver.value === false) {
    draggingOver.value = true
  }
  if (direction.value !== dir) {
    direction.value = dir
  }
  emits('onDragOver', ev, { dir, rowIndex: props.rowIndex })
}

const onDragExit = (ev: any) => {
  draggingOver.value = false
  direction.value = null
  emits('onDragExit', ev)
}

const onDropElement = (
  ev: MouseEvent,
  payload: {
    dir: Direction
    rowIndex: number
    colIndex?: number
    currentPageId?: string
    rowColIndex?: number
    rowId?: string
  }
) => {
  ev.preventDefault()

  draggingOver.value = false
  direction.value = null
  emits('onDropElement', ev, {
    ...payload,
  })
}
const zIndex = computed(() => {
  return props.dragEnabled ? 10 : 0
})
</script>

<template>
  <span
    v-if="top"
    class="absolute w-full ghl-dropzone-anchor ghl-dropzone-anchor--top"
    :class="{
      [topAbleClassName]: topAbleClassName,
      'ghl-dropzone-anchor--activate': draggingOver,
      'ghl-dropzone-anchor--active': direction === 'top' && draggingOver,
    }"
    :data-row-index="rowIndex"
    :data-col-index="colIndex"
    :ondragover="(ev: any) => throttle(onDragOver(ev, 'top'),100)"
    :ondragleave="onDragExit"
    :ondrop="(ev: MouseEvent) => onDropElement(ev,  {
            dir: 'top',
            rowIndex: rowIndex,
            colIndex: isNested ? colIndex : -1,
            currentPageId: pageId,
            rowColIndex: isNested ? rowColIndex : -1,
            rowId: props.rowId,
          })"
  ></span>
  <span
    v-if="bottom"
    class="absolute w-full ghl-dropzone-anchor ghl-dropzone-anchor--bottom"
    :class="{
      [bottomAbleClassName]: bottomAbleClassName,
      'ghl-dropzone-anchor--activate': draggingOver,
      'ghl-dropzone-anchor--active': direction === 'bottom' && draggingOver,
      'ghl-dropzone-anchor--last-bottom': isLastRow,
    }"
    :data-row-index="isLastRow ? rowIndex : rowIndex + 1"
    :data-col-index="colIndex"
    :ondragover="(ev: any) => throttle(onDragOver(ev,'bottom'),100)"
    :ondragleave="onDragExit"
    :ondrop="(ev: MouseEvent) => onDropElement(ev,  {
            dir: 'bottom',
            rowIndex: isNested ? rowIndex : (rowIndex + 1),
            colIndex: isNested ? colIndex : -1,
            currentPageId: pageId,
            rowColIndex: isNested ? rowColIndex+1 : -1,
            rowId: props.rowId,
          })"
  ></span>
  <span
    v-if="left"
    class="absolute h-full ghl-dropzone-anchor ghl-dropzone-anchor--left"
    :class="{
      'ghl-dropzone-anchor--activate': draggingOver,
      'ghl-dropzone-anchor--active': direction === 'left' && draggingOver,
      [leftAbleClassName]: leftAbleClassName,
    }"
    :data-row-index="rowIndex"
    :data-col-index="colIndex"
    :ondragover="(ev: any) => throttle(onDragOver(ev, 'left'),100)"
    :ondragleave="onDragExit"
    :ondrop="(ev: MouseEvent) => onDropElement(ev,  {
            dir: 'left',
            rowIndex: rowIndex,
            colIndex: colIndex,
            currentPageId: pageId,
            rowId: props.rowId,
          })"
  ></span>
  <span
    v-if="right"
    class="absolute h-full ghl-dropzone-anchor ghl-dropzone-anchor--right"
    :class="{
      'ghl-dropzone-anchor--activate': draggingOver,
      'ghl-dropzone-anchor--active': direction === 'right' && draggingOver,
      [rightAbleClassName]: rightAbleClassName,
    }"
    :data-row-index="rowIndex"
    :data-col-index="colIndex + 1"
    :ondragover="(ev: any) => throttle(onDragOver(ev, 'right'),100)"
    :ondragleave="onDragExit"
    :ondrop="(ev: MouseEvent) => onDropElement(ev,  {
            dir: 'right',
            rowIndex: rowIndex,
            colIndex: colIndex+1,
            currentPageId: pageId,
            rowId: props.rowId,
          })"
  ></span>
</template>

<style lang="scss">
.ghl-dropzone-anchor {
  z-index: v-bind('zIndex');
  &::after {
    content: '';
    position: absolute;
    opacity: 0;
  }
  &--activate {
    z-index: 10;
    &::after {
      background-color: var(--primary-600);
      opacity: 0.4 !important;
    }
  }
  &--active {
    &::after {
      background-color: var(--primary-600) !important;
      opacity: 1 !important;
    }
  }
  &--right {
    left: 100%;
    width: 48px;
    &::after {
      left: -2px;
      height: 80%;
      width: 2px;
      transform: translateY(-50%);
      top: 50%;
    }
  }
  &--left {
    left: -48px;
    width: 48px;
    &::after {
      right: -2px;
      height: 80%;
      width: 2px;
      transform: translateY(-50%);
      top: 50%;
    }
    &.col-dropables {
      left: 0px;
      &::after {
        right: unset;
        left: 0px;
      }
    }
  }
  &--top {
    top: -10px;
    height: 60%;
    &::after {
      top: 10px;
      height: 2px;
      width: 95%;
      transform: translateX(-50%);
      left: 50%;
    }
    &.row-dropables {
      &::after {
        top: 2px;
      }
    }
  }
  &--bottom {
    height: 60%;
    bottom: -10px;
    &::after {
      bottom: 10px;
      height: 2px;
      width: 95%;
      transform: translateX(-50%);
      left: 50%;
    }
    &.row-dropables {
      &::after {
        bottom: -10px;
      }
    }
  }
}
</style>
