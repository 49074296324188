import {
  BuilderAppStore,
  CustomDocumentFields,
  IDocument,
  TimeZoneEnum,
} from '@gohighlevel/ghl-proposals-common'

import { defineStore } from 'pinia'

export const useAppStore = defineStore('appStore', {
  state: (): BuilderAppStore => ({
    email: '',
    userId: '',
    apiKey: '',
    tokenId: '',
    companyId: '',
    timeZone: '' as TimeZoneEnum,
    userName: '',
    parentBaseUrl: '',
    handshake: {} as Promise<any>,
    requestRetryCount: 0,
    document: {
      enableSigningOrder: false,
      enableDirectPayment: false,
      enableAutoSendInvoice: false,
      paymentLiveMode: true,
    } as IDocument,
    unsavedChanges: false,
    currency: 'USD',
    locationStaticCustomValues: [],
    locationCustomValues: [],
    locationCustomFields: [],
    userData: {} as Promise<any>,
    linkReference: [],
    whiteLabelViewerBaseUrl: '',
    locale: 'en-US',
    isInternal: false,
    invoiceWhiteLabelBaseUrl: '',
  }),
  getters: {
    primaryContact: state => {
      return state.document?.recipients?.find(element => {
        return element.isPrimary === true
      })
    },
    flatDocumentVariables: state => {
      return state.document.variables.reduce((acc, variable) => {
        return {
          ...acc,
          [variable.fieldKey]: variable.value,
        }
      })
    },
  },
  actions: {
    updateDocumentVariable({ fieldKey, value, type }: any) {
      const index = this.document.variables.findIndex(
        variable => variable.fieldKey === fieldKey
      ) as number
      if (index === -1) {
        this.document.variables.push({ fieldKey, value, type })
      } else {
        this.document.variables[index].value = value
      }
      this.setUnsavedChanges(true)
    },
    deleteDocumentCustomVariable(value: string) {
      this.document.variables = [
        ...this.document.variables.filter(({ fieldKey }) => fieldKey !== value),
      ]
      this.setUnsavedChanges(true)
    },
    setDocument(document: IDocument) {
      this.document = {
        ...(document ? document : {}),
        // for backward compatibility
        ...(document?.recipients &&
        document?.recipients?.every(({ signingOrder }) => signingOrder === 1)
          ? {
              recipients: document?.recipients?.map((recipient, index) => {
                return {
                  ...recipient,
                  signingOrder: index + 1,
                }
              }),
            }
          : {}),
      } as IDocument
    },
    setLinkReference(links: any) {
      this.linkReference = links
    },
    setWhiteLabelViewerBaseUrl(baseUrl: string) {
      this.whiteLabelViewerBaseUrl = baseUrl
    },
    setInvoiceWhiteLabelBaseUrl(baseUrl: string) {
      this.invoiceWhiteLabelBaseUrl = baseUrl
    },
    setUnsavedChanges(changes: boolean) {
      this.unsavedChanges = changes
    },
    setupApp(params: BuilderAppStore) {
      this.email = params.email
      this.userId = params.userId
      this.apiKey = params.apiKey
      this.tokenId = params.tokenId
      this.companyId = params.companyId
      this.timeZone = params.timeZone
      this.userName = params.userName
      this.parentBaseUrl = params.parentBaseUrl
      this.handshake = params.handshake
      this.currency = params.currency

      this.isInternal = [
        '5DP4iH6HLkQsiKESj6rh',
        'YuTUZlUtrwBtvmgByZDW',
        'ktapXmiRvMM4pJHyN3b3',
      ].includes(params.companyId) // 5DP4iH6HLkQsiKESj6rh (staging) & YuTUZlUtrwBtvmgByZDW (production) & ktapXmiRvMM4pJHyN3b3(production)
    },

    updateLoggedInUserData(userData: any) {
      this.userData = userData
    },
    updateRequestRetryCount(count: number) {
      this.requestRetryCount = count
    },
    updateDocumentName(name: string) {
      this.document.name = name
      this.setUnsavedChanges(true)
    },
    refreshToken(token: string) {
      this.tokenId = token
      console.info('refreshToken')
    },
    updateDocumentOption(
      key: keyof CustomDocumentFields,
      value: CustomDocumentFields[keyof CustomDocumentFields]
    ) {
      this.document[key] = value
      this.setUnsavedChanges(true)
    },
    updateLocale(locale: string) {
      this.locale = locale
    },
    toggleSigningOrder(value: boolean) {
      this.document.enableSigningOrder = value
      this.setUnsavedChanges(true)
    },
    toggleDirectPayment(value: boolean) {
      this.document.enableDirectPayment = value
      if (value === true) {
        this.document.enableAutoSendInvoice = true
      }
      this.setUnsavedChanges(true)
    },
    toggleAutoSendInvoice(value: boolean) {
      this.document.enableAutoSendInvoice = value
      this.setUnsavedChanges(true)
    },
    togglePaymentLiveMode(value: boolean) {
      this.document.paymentLiveMode = value
      this.setUnsavedChanges(true)
    },
  },
})
