import {
  CurrencyDetails,
  CurrencyOption,
  LineItem,
  PriceTypeEnum,
  ScheduleRRuleOptions,
} from '@gohighlevel/ghl-proposals-common'

import i18n from '@gohighlevel/ghl-proposals-common/src/locales'
import { defineStore } from 'pinia'
import { useAppStore } from './app'
import { useBuilderStore } from './builder'

interface ScheduleValidation {
  errors: Record<string, any>[]
  results: Record<string, any>[]
  valid: boolean
}
interface ProductListStore {
  selectedRows: string[]
  activeRow: LineItem | null
  currency: string
  currencyDetails: CurrencyDetails
  currencyOptions: CurrencyOption[]
  activeListId: string | null
  schedule: ScheduleRRuleOptions
  invoiceType: PriceTypeEnum
  scheduleValidation: ScheduleValidation
  invoiceId: string
  invoiceScheduleId: string
}
export const useProductListStore = defineStore('productListStore', {
  state: (): ProductListStore => ({
    activeListId: null,
    selectedRows: [],
    activeRow: null,
    currency: '',
    currencyDetails: {} as CurrencyDetails,
    currencyOptions: [],
    schedule: {} as ScheduleRRuleOptions,
    invoiceType: PriceTypeEnum.ONETIME,
    scheduleValidation: {} as ScheduleValidation,
    invoiceId: '',
    invoiceScheduleId: '',
  }),
  getters: {
    getActiveRow: state => state.activeRow,
  },
  actions: {
    setSelectedRows(rows: string[]) {
      this.selectedRows = rows
    },
    setActiveRow(
      lineItem: LineItem | null | undefined,
      tableId: string | null
    ) {
      if (lineItem) {
        this.activeRow = Object.assign({}, lineItem)
      } else {
        this.activeRow = Object.assign({}, null)
      }
      this.activeListId = tableId
    },
    updateActiveRow(options: {
      key: keyof LineItem
      value: string | number | undefined
    }) {
      if (this.activeRow) {
        this.activeRow = { ...this.activeRow, ...options }
      }
    },
    setProductListCurrency(currency: string) {
      this.currency = currency
    },
    setCurrencyDetails(currencyData: CurrencyDetails) {
      this.currencyDetails = currencyData
      this.currencyOptions = Object.entries(currencyData.currency).map(
        ([currencyCode, currencyData]: any) => {
          return { label: currencyData.code, value: currencyCode }
        }
      )
    },
    updateSchedule(values: ScheduleRRuleOptions) {
      const appStore = useAppStore()
      appStore.setUnsavedChanges(true)
      this.schedule = values
    },
    setSchedule(rrule: ScheduleRRuleOptions) {
      this.schedule = rrule
    },
    setInvoiceType(invoiceType: PriceTypeEnum) {
      const appStore = useAppStore()
      this.invoiceType = invoiceType
      appStore.setUnsavedChanges(true)
    },
    updateScheduleErrors(validation: ScheduleValidation) {
      this.scheduleValidation = validation
    },
    setInvoiceId(invoiceId: string) {
      this.invoiceId = invoiceId
    },
    setScheduleId(scheduleId: string) {
      this.invoiceScheduleId = scheduleId
    },
    getProductListErrors() {
      const errors: string[] = []
      const builderStore = useBuilderStore()
      const productLists = builderStore.getAllProductList()
      if (productLists && productLists.length > 0) {
        if (this.invoiceType === PriceTypeEnum.RECURRING) {
          const scheduleKeys = Object.keys(this.schedule)
          if (scheduleKeys.length === 0) {
            errors.push(i18n.global.t('validations.required.schedule'))
          }
          if (
            scheduleKeys.length > 0 &&
            this.scheduleValidation &&
            !this.scheduleValidation?.valid &&
            this.scheduleValidation?.errors
          ) {
            const result = Object.values(this.scheduleValidation?.errors)
            return [...errors, ...result]
          }
        }
      }
      return errors
    },
    updateInvoiceTypeIfRecurringProductExist() {
      const builderStore = useBuilderStore()
      const isRecurringProduct = builderStore.isRecurringProduct()
      if (isRecurringProduct) {
        this.setInvoiceType(PriceTypeEnum.RECURRING)
      } else {
        this.setInvoiceType(PriceTypeEnum.ONETIME)
      }
    },
    resetSchedule() {
      this.schedule = {} as ScheduleRRuleOptions
      this.invoiceType = PriceTypeEnum.ONETIME
    },
  },
})
