<script lang="ts" setup>
import { PageConfig } from '@gohighlevel/ghl-proposals-common'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  parentHeight: {
    type: Number,
    default: 0,
    required: true,
  },
  pageDimensions: {
    type: Object as PropType<PageConfig>,
    required: true,
  },
  pageVersion: {
    type: Number,
    default: 1,
    required: true,
  },
})

const childElements = ref<number[]>([])

const initialPageProc = () => {
  const parentHeight = props.parentHeight
  const quotient = parentHeight / 1056
  let i = 1
  const pageBreak = [] as number[]
  while (i <= quotient) {
    const value = i === 1 ? i * 1056 + 48 : i * 1056
    if (parentHeight >= value) {
      pageBreak.push(value)
    }
    i++
  }
  childElements.value = pageBreak
}

const calcPageBreak = () => {
  const parentHeight = props.parentHeight
  const pageHeight = props.pageDimensions.dimensions.height
  const quotient = parentHeight / pageHeight
  const pageBreak = [] as number[]
  const multiple = Math.floor(quotient)

  let total = 0
  const arr = new Array(multiple).fill(0)
  arr.forEach(() => {
    total = total + (pageHeight - 48 * 2)
    pageBreak.push(total)
  })
  childElements.value = pageBreak
}

watch(props, newValue => {
  if (newValue && props.pageVersion > 1) {
    calcPageBreak()
  } else {
    initialPageProc()
  }
})
</script>

<template>
  <div v-for="(child, index) in childElements" :key="index" class="child">
    <div class="pg-break" :style="`top:calc(${child + 22}px)`">
      {{ $t('common.pdfPageBreak') }}
    </div>
  </div>
</template>

<style lang="scss">
.pg-break {
  position: absolute;
  width: 100px;
  left: -104px;
  border-top: 2px dotted gray;
  color: rgb(141, 141, 141);
  font-size: 10px;
  padding: 2px;
  user-select: none;
  font-weight: 600;
}
</style>
