import { useMovable } from './useMovable'

export enum PositionTypeEnum {
  BOTTOM_TOP = 'bottom_top',
  RIGHT_LEFT = 'right_left',
}

export const usePosition = () => {
  const { movableRefMap } = useMovable()

  const getNewPositionForElement = (
    elementId: string,
    pageIndex: string | number,
    pos: PositionTypeEnum
  ) => {
    const selector = '.snapContainer-' + pageIndex
    const container = document.querySelector(selector)
    const containerRect = container?.getBoundingClientRect()
    if (containerRect) {
      const elementRect = movableRefMap.value[elementId].getRect()

      const space = 15
      const delta = 15

      const position = { top: elementRect.top + 5, left: elementRect.left + 5 }
      const right = containerRect.width - elementRect.left
      const bottom = containerRect.height - elementRect.top

      if (pos === PositionTypeEnum.RIGHT_LEFT) {
        // placing it right
        if (
          elementRect.left + elementRect.width * 2 + space <=
            containerRect.width &&
          elementRect.top <= containerRect.height
        ) {
          position.left = elementRect.left + elementRect.width + space
          position.top = elementRect.top + delta

          // placing it left
        } else if (
          right + elementRect.width * 2 + space <= containerRect.width &&
          elementRect.top <= containerRect.height
        ) {
          position.left =
            containerRect.width - (right + elementRect.width + space)
          position.top = elementRect.top + delta
        }

        return position
      }

      if (pos === PositionTypeEnum.BOTTOM_TOP) {
        // placing bottom
        if (
          elementRect.top + elementRect.height * 2 + space <=
            containerRect.height &&
          elementRect.left <= containerRect.width
        ) {
          position.top = elementRect.top + elementRect.height + space
          position.left = elementRect.left + delta
          // placing it top
        } else if (
          bottom + elementRect.height * 2 + space <= containerRect.height &&
          elementRect.left <= containerRect.width
        ) {
          position.top =
            containerRect.height - (bottom + elementRect.height + space)
          position.left = elementRect.left + delta
        }
        return position
      }
    }
    return null
  }

  return {
    getNewPositionForElement,
  }
}
