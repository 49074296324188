<script setup lang="ts">
import CImage from './elements/CImage.vue'
import CPageBreak from './elements/CPageBreak.vue'
import CProductList from './elements/CProductList/CProductList.vue'
import CSignature from './elements/CSignature.vue'
import CTable from './elements/CTable.vue'
import CText from './elements/CText.vue'
import CVideo from './elements/CVideo.vue'
import OverlayActions from './OverlayActions.vue'
import { PropType } from 'vue'
import {
  IElement,
  ELEMENTS_LOOKUP,
  ElementDropContainer,
} from '@gohighlevel/ghl-proposals-common'
import { useBuilderStore } from '@/store/builder'
import RenderFloatingComponents from '../common/RenderFloatingComponents.vue'

const emits = defineEmits([
  'onDropElement',
  'onMoveStart',
  'onMoveEnd',
  'click',
  'mouseOver',
  'mouseLeave',
])

const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
    required: true,
  },
  dragEnabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  rowIndex: {
    type: Number,
    required: true,
  },
  colIndex: {
    type: Number,
    required: true,
  },
  isLastRow: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLastCol: {
    type: Boolean,
    required: false,
    default: false,
  },
  pageId: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: false,
    default: '100%',
  },
  rowId: {
    type: String,
    required: false,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  snapContainerClass: {
    type: String,
    required: true,
  },
  boundContainerClass: {
    type: String,
    required: true,
  },
  mainIndex: {
    type: Number,
    required: true,
  },
})
const store = useBuilderStore()

const onMoveStart = (...args: any[]) => {
  emits('onMoveStart', ...args)
}

const onMoveEnd = (...args: any[]) => {
  emits('onMoveEnd', ...args)
}
</script>

<template>
  <ElementDropContainer
    :element="element"
    :rowIndex="rowIndex"
    :colIndex="colIndex"
    @onDropElement="(...args) => $emit('onDropElement', ...args)"
    :isLastRow="isLastRow"
    :isLastCol="isLastCol"
    :dragEnabled="dragEnabled"
    @click="(...args) => $emit('click', ...args)"
    @mouseLeave="e => $emit('mouseLeave', e)"
    @mouseOver="e => $emit('mouseOver', e, element)"
    @onMoveStart="(...args) => $emit('onMoveStart', ...args)"
    @onMoveEnd="(...args) => $emit('onMoveEnd', ...args)"
    :pageId="pageId"
    :width="width"
    :key="element.id + '-' + rowIndex + '-' + colIndex + '-' + pageId"
    :draggable="
      store.activeElementId === element.id && store.moveElementDragState
    "
    :rowId="rowId"
    :snap-container-class="snapContainerClass"
    :mainIndex="mainIndex"
  >
    <template #target>
      <div
        ref="elContainerRef"
        class="flex flex-1 w-full rounded-smooth docView"
      >
        <!-- TODO: make it dynamic component -->
        <CSignature
          v-if="element && element.type === ELEMENTS_LOOKUP.SIGNATURE"
          :element="element"
        />
        <CText
          v-if="element && element.type === ELEMENTS_LOOKUP.TEXT"
          :element="element"
        />
        <CImage
          v-if="element && element.type === ELEMENTS_LOOKUP.IMAGE"
          :element="element"
        />
        <CVideo
          v-if="element && element.type === ELEMENTS_LOOKUP.VIDEO"
          :element="element"
        />
        <CTable
          v-if="element && element.type === ELEMENTS_LOOKUP.TABLE"
          :element="element"
        />
        <CPageBreak
          v-if="element && element.type === ELEMENTS_LOOKUP.PAGE_BREAK"
          :element="element"
        />
        <CProductList
          v-if="element && element.type === ELEMENTS_LOOKUP.PRODUCT_LIST"
          :element="element"
        />
      </div>
      <RenderFloatingComponents
        v-if="props.element?.children && props.element?.children.length > 0"
        :parentId="(props.element?.id as string)"
        :page-index="props.pageIndex"
        :child-components-with-draggable="
          element?.children?.filter(
            child =>
              child?.component?.isDraggable &&
              child?.type !== ELEMENTS_LOOKUP.CHECKBOX
          ) || []
        "
        :snap-container-class="props.snapContainerClass"
        :bound-container-class="props.boundContainerClass"
        :key="`floating-components-${props.element?.id}-${props.pageIndex}-${props.rowIndex}-${props.colIndex}`"
      />
    </template>
    <template #overlay-section>
      <OverlayActions
        :element="element"
        :elementId="(element?.id as string)"
        @dragstart="onMoveStart"
        @dragend="onMoveEnd"
      />
    </template>
  </ElementDropContainer>
</template>
