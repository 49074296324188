export const TableCellAddAttributes: any = {
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        renderHTML: (attributes: any) => {
          if (!attributes.backgroundColor) {
            return {}
          }
          return {
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
        parseHTML: (element: any) => {
          return element.style.backgroundColor.replace(/['"]+/g, '')
        },
      },
      borderColor: {
        default: null,
        renderHTML: (attributes: any) => {
          if (!attributes.borderColor) {
            return {}
          }

          return {
            style: `border-color: ${attributes.borderColor}`,
          }
        },
        parseHTML: (element: any) => {
          return element.style.borderColor.replace(/['"]+/g, '')
        },
      },
      colwidth: {
        default: null,
        parseHTML: (element: any) => {
          const colwidth = element.getAttribute('colwidth')
          return colwidth ? colwidth.split(',').map(Number) : null
        },
      },
      width: {
        default: null,
        renderHTML: (attributes: any) => {
          if (!attributes.colwidth) {
            return {}
          }

          return {
            style: `width: ${attributes.colwidth[0]}px`,
          }
        },
        parseHTML: (element: any) => {
          return element.style.width.replace(/['"]+/g, '')
        },
      },
    }
  },
}
