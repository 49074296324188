import { parentRoutes, routes } from '@/router'
import {
  PostmateParentRoute,
  PostmateRouteChangeOptions,
} from '@gohighlevel/ghl-proposals-common'
import { useI18n } from 'vue-i18n'

import { useAppStore } from '@/store/app'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const isRouteChangeConfirmed = ref(false)
export const usePostmate = () => {
  const store = useAppStore()
  const route = useRoute()
  const { t } = useI18n()

  const validateUnsavedChanges = () => {
    return new Promise(resolve => {
      if (route.name === 'builder' && store.unsavedChanges) {
        if (window.confirm(t('validations.warnings.unsavedChanges'))) {
          isRouteChangeConfirmed.value = true
          resolve(true)
        } else {
          resolve(false)
        }
      } else {
        isRouteChangeConfirmed.value = true
        resolve(true)
      }
    })
  }
  const emitRouteChangeToParentApp = (
    path: string,
    options?: PostmateRouteChangeOptions
  ) => {
    if (!path) return
    try {
      if (store.handshake) {
        store.handshake.then((parent: any) => {
          validateUnsavedChanges().then(confirm => {
            if (confirm) {
              parent.emit('route-change', {
                router: path,
                ...options,
              })
            } else {
              console.info('Unsaved changes alert has been declined by user.')
            }
          })
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const redirectOnParentRoute = (
    route: PostmateParentRoute,
    options?: PostmateRouteChangeOptions
  ) => {
    try {
      const routePath = [...parentRoutes, ...routes].find(
        r => r.name === route.name
      )
      let routeFullPath = routePath?.path
      if (route.params) {
        Object.keys(route.params).forEach(key => {
          routeFullPath = routeFullPath?.replace(`:${key}`, route.params[key])
        })
      }
      if (!routeFullPath) throw new Error('Route path not found')
      emitRouteChangeToParentApp(routeFullPath, options)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    redirectOnParentRoute,
    validateUnsavedChanges,
    isRouteChangeConfirmed,
  }
}
