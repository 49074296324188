import {
  MaybeElementRef,
  OnClickOutsideHandler,
  OnClickOutsideOptions,
  onClickOutside,
} from '@vueuse/core'

export const useClickOutSide = () => {
  const onClickOutSide = <T extends OnClickOutsideOptions>(
    target: MaybeElementRef,
    handler: OnClickOutsideHandler<{
      detectIframe: T['detectIframe']
    }>,
    options?: T
  ) => {
    onClickOutside(target, handler, options)
  }
  return {
    onClickOutSide,
  }
}
