<script setup lang="ts">
import { computed } from 'vue'
import {
  UICheckbox,
  UITextSmRegular,
  UIEmpty,
  UISpin,
  UIAlert,
  UIButton,
  UIPopover,
  UITextXsMedium,
} from '@gohighlevel/ghl-ui'
import { ITax } from '@gohighlevel/ghl-proposals-common'
import { useProductListStore } from '@/store/productListStore'
import { useProductListActions } from '@/composable/useProductListActions'
import { useCurrency, usePostmate } from '@/composition'
import { useRoute } from 'vue-router'
import { isNil } from 'lodash'
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'

const route = useRoute()
const { getCurrencySymbol } = useCurrency()
const priceCurrencySymbol = computed(() =>
  getCurrencySymbol(activeRow?.value?.currency)
)
const { redirectOnParentRoute } = usePostmate()
const props = withDefaults(defineProps<{ taxes: ITax[]; loading: boolean }>(), {
  loading: false,
})
const productListStore = useProductListStore()
const activeRow = computed(() => productListStore.activeRow)
const { updateSelectedLineItem } = useProductListActions()

const ifTaxIsSelected = (tax: ITax) => {
  return activeRow.value?.taxes?.find(({ _id }) => tax._id === _id)
}

const isItemZeroPrice = () => {
  return activeRow.value?.price === 0 || isNil(activeRow.value?.price)
}

const onSelect = (tax: ITax) => {
  if (activeRow.value) {
    if (ifTaxIsSelected(tax)) {
      activeRow.value.taxes = [
        ...(activeRow.value
          ? activeRow.value.taxes.filter(({ _id }) => _id !== tax._id)
          : []),
      ]
      updateSelectedLineItem('taxes', activeRow.value.taxes)
    } else {
      activeRow.value.taxes = [
        ...(activeRow.value ? [...activeRow.value.taxes, tax] : []),
      ]
      updateSelectedLineItem('taxes', activeRow.value.taxes)
    }
  }
}
</script>

<template>
  <template v-if="props.loading">
    <UIEmpty id="taxes-loading" description="Loading...">
      <template #media>
        <UISpin> {{ $t('common.checkbox') }}</UISpin>
      </template>
    </UIEmpty>
  </template>
  <template v-else>
    <div
      class="flex gap-2 flex-col py-4 border-t"
      v-if="props?.taxes?.length > 0"
    >
      <div class="w-full flex items-center mb-4">
        <UITextSmRegular class="text-gray-700 font-medium">{{
          $t('common.addTax')
        }}</UITextSmRegular>
        <UIPopover
          placement="right"
          to="body"
          :width="150"
          :animated="true"
          v-if="isItemZeroPrice()"
        >
          <template #trigger>
            <InfoCircleIcon class="ml-2 h-4 w-4 text-gray-500" />
          </template>
          <UITextXsMedium class="ml-2">{{
            `Taxes require a product value above ${priceCurrencySymbol}0`
          }}</UITextXsMedium>
        </UIPopover>
      </div>
      <div
        :class="`${
          ifTaxIsSelected(tax) ? 'selected' : ''
        } tax-card flex-col border-2 border-solid flex p-3 cursor-pointer hover:bg-primary-50 hover:border-primary-600 rounded-lg ${
          isItemZeroPrice() ? 'pointer-events-none opacity-50' : ''
        }`"
        v-for="tax in props.taxes"
        v-bind:key="tax._id"
        @click="() => onSelect(tax)"
      >
        <UICheckbox :id="tax._id" :checked="ifTaxIsSelected(tax) ? true : false"
          >{{ tax.name
          }}<UITextSmRegular class="pl-1"
            >({{ tax.rate }}%)</UITextSmRegular
          ></UICheckbox
        >
        <UITextSmRegular class="pl-7">{{
          tax.description || '-'
        }}</UITextSmRegular>
      </div>
    </div>
    <div v-else class="flex gap-2 flex-col py-4 border-t">
      <div>
        <UITextSmRegular class="text-gray-700 font-medium">{{
          $t('common.addTax')
        }}</UITextSmRegular>
      </div>
      <UIAlert id="tax-alert" type="warning" :closable="false">
        <template #title>No Taxes Configured</template>
        <template #content
          >Click
          <UIButton
            :text="true"
            id="click_to_create_taxes"
            @click="
              redirectOnParentRoute(
                {
                  name: 'payment-settings-taxes',
                  params: { locationId: route?.params.locationId },
                },
                { newTab: true }
              )
            "
            >here</UIButton
          >
          to configure taxes</template
        >
      </UIAlert>
    </div>
  </template>
</template>

<style lang="scss">
.tax-card {
  &.selected {
    border: 2px solid var(--primary-600);
    background-color: var(--primary-50);
    color: var(--primary-700) !important;
    .n-checkbox__label {
      color: var(--primary-800) !important;
    }
  }
  .n-checkbox__label {
    font-weight: 500;
    display: inline-flex;
    align-items: center;
  }
  &:hover {
    color: var(--primary-700) !important;
    .n-checkbox__label {
      color: var(--primary-800) !important;
    }
  }
}
</style>
