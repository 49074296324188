<script setup lang="ts">
import {
  Color,
  LineHeight,
  Link,
  RequiredExtensions,
  TextAlign,
  TextBlocks,
  TextSize,
  UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import StarterKit from '@tiptap/starter-kit'
import ListItem from '@tiptap/extension-list-item'
import HardBreak from '@tiptap/extension-hard-break'
import { Editor } from '@tiptap/vue-3'
import { PropType, computed, ref, watch } from 'vue'
import {
  MagicPlaceholderExtension,
  ELEMENTS_META,
  ExtendedFontFamily,
  IElement,
} from '@gohighlevel/ghl-proposals-common'
import { EditorState } from '@tiptap/pm/state'
import { useElementOption } from '@/composable/elementOptions'
import { useDocument } from '@/composition'
import { useBuilderStore } from '@/store/builder'

const editor = ref<Editor>()
const store = useBuilderStore()
const { updateOption } = useElementOption()
const { loadingCustomValues } = useDocument()

const props = defineProps({
  genKey: { type: String },
  element: {
    type: Object as PropType<IElement>,
  },
  customValues: {
    type: Object as PropType<Record<string, string>>,
    required: true,
  },
})
const isEditable = computed(() => store.isDocumentEditable)

const CustomBulletList = BulletList.extend({
  addCommands() {
    return {
      ...this.parent?.(),
      toggleCustomBulletList: () => editor => {
        return editor.commands.toggleBulletList()
      },
    }
  },
})

const HardBreakExtension = HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        if (
          this.editor.isActive('orderedList') ||
          this.editor.isActive('bulletList')
        ) {
          return this.editor.chain().createParagraphNear().run()
        }
        return this.editor.commands.setHardBreak()
      },
    }
  },
})

editor.value = new Editor({
  content: props.element?.component.options.text,
  extensions: [
    StarterKit.configure({
      paragraph: {
        HTMLAttributes: {
          class: 'break-inside-auto', // Your custom class name
        },
      },
    }),
    MagicPlaceholderExtension.configure({
      placeholderValues: props.customValues,
      loading: loadingCustomValues.value,
    }),
    TextSize,
    RequiredExtensions,
    TextStyle,
    ExtendedFontFamily,
    TextBlocks,
    Color,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Underline,
    Link.configure({
      openOnClick: false,
      protocols: ['ftp', 'mailto', '#popup', 'sms', 'tel', '{{', '/'],
      autolink: true,
      linkOnPaste: false,
    }),
    LineHeight,
    ListItem,
    CustomBulletList,
    HardBreakExtension.configure({
      HTMLAttributes: {
        class: 'break-after-auto page-break',
      },
    }),
  ],
  autofocus: true,
  editable: isEditable.value,
  onUpdate({ editor }) {
    updateOption(
      ELEMENTS_META.TEXT,
      editor.getHTML(),
      props?.element?.id,
      props?.element?.type
    )
  },
  parseOptions: {
    preserveWhitespace: true,
  },
  editorProps: {
    attributes: (state: EditorState) => {
      return {
        ...state.doc.attrs,
        style: 'font-family: sans-serif', // Default font-family
      }
    },
  },
})
watch(isEditable, () => {
  editor.value?.setEditable(isEditable.value)
})

editor.value.on('focus', () => {
  store.updateEditorInstance(editor.value as unknown as typeof Editor)
})
editor.value.off('focus', () => {
  store.updateEditorInstance(editor.value as unknown as typeof Editor)
})

// Only for undo/redo operation to work since tiptap editor does not refresh on updating pages state
watch(
  () => store.updateEditors,
  () => {
    editor.value?.commands.setContent(props.element?.component.options.text)
  },
  { deep: true }
)
watch(
  () => store.moveElementDragState,
  val => {
    editor.value?.setEditable(!val)
  }
)
</script>

<template>
  <UIRichTextEditor :editor="editor" />
</template>
