<script setup lang="ts" generic="T">
import { computed, ref } from 'vue'
import { PropType } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
const props = defineProps({
  list: {
    type: Array as PropType<T[]>,
    default: () => [],
    required: true,
  },
  handleClass: {
    type: String,
    default: '.handle',
    required: false,
  },
  draggable: {
    type: Boolean,
    default: true,
  },
  class: {
    type: String,
    required: false,
  },
})
const drag = ref(false)
const emits = defineEmits(['onUpdate'])
const dragOptions = computed(() => ({
  animation: 200,
  group: 'description',
  disabled: !props.draggable,
  ghostClass: 'ghost',
}))
const onDragStart = () => {
  drag.value = true
}
const onDragEnd = (e: any) => {
  emits('onUpdate', { oldIndex: e.oldIndex, newIndex: e.newIndex })
  drag.value = false
}
</script>

<template>
  <VueDraggableNext
    tag="ul"
    v-bind:model-value="props.list"
    :class="`dragArea list-group w-full p-0 list-none ${props.class}`"
    item-key="id"
    style="list-style: none; margin: 0px"
    v-bind="dragOptions"
    @end="onDragEnd"
    @start="onDragStart"
  >
    <transition-group type="transition" :name="'no-move'">
      <template v-for="(element, index) in props.list" :key="element.id">
        <li
          class="list-none list-item"
          :class="{ 'list-group-item': props.draggable }"
        >
          <slot name="listItem" :data="element" :index="index"></slot>
        </li>
      </template>
    </transition-group>
  </VueDraggableNext>
</template>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 1;
  background: #f2f4f7;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}
</style>
