<script setup lang="ts">
import { useAppStore } from '@/store/app'
import { computed } from 'vue'
import RecipientCard from './RecipientCard.vue'
import {
  APP_STORE_META,
  DragHandleSVG,
  DraggableList,
} from '@gohighlevel/ghl-proposals-common'
import { useElementOption } from '@/composable/elementOptions'
import { useBuilderStore } from '@/store/builder'

const appStore = useAppStore()
const store = useBuilderStore()
const { updateDocumentOption } = useElementOption()

const reorderArray = ({
  oldIndex,
  newIndex,
}: {
  oldIndex: number
  newIndex: number
}) => {
  const newArray = [...(recipients?.value || [])]
  const movedItem = newArray.splice(oldIndex, 1)[0]
  newArray.splice(newIndex, 0, movedItem)

  // Update the signingOrder property
  if (oldIndex < newIndex) {
    for (let i = oldIndex; i <= newIndex; i++) {
      newArray[i].signingOrder = i + 1
    }
  } else {
    for (let i = newIndex; i <= oldIndex; i++) {
      newArray[i].signingOrder = i + 1
    }
  }
  updateDocumentOption(
    APP_STORE_META.RECIPIENTS,
    newArray.sort((a, b) => a.signingOrder - b.signingOrder)
  )
  return newArray
}

const recipients = computed(() => {
  return appStore.document?.recipients
})
const isDocumentEditable = computed(
  () => appStore.document.enableSigningOrder && store.isDocumentEditable
)
</script>

<template>
  <div class="flex flex-col" v-if="recipients && recipients.length > 0">
    <div class="border-b"></div>
    <DraggableList
      :list="recipients"
      @on-update="reorderArray"
      :draggable="isDocumentEditable"
      :class="'px-2 box-border'"
    >
      <template #listItem="slotProps">
        <div
          class="bg-white"
          :class="{
            'border-b': slotProps.index !== recipients.length - 1,
          }"
        >
          <div class="w-full flex">
            <div
              class="w-1/12 gap-2 flex flex-col-reverse justify-between bg-gray-500 items-center rounded-sm my-2 recipient-drag"
              v-if="isDocumentEditable"
            >
              <span class="handle">
                <DragHandleSVG class="fill-white" />
              </span>
              <span
                class="min-w-[18px] text-center rounded-sm font-[14px] font-semibold mt-1 leading-tight sign-count"
                >{{ slotProps.data.signingOrder }}</span
              >
            </div>
            <div
              :class="{
                'w-11/12': isDocumentEditable,
                'w-full': !appStore.document.enableSigningOrder,
              }"
            >
              <RecipientCard
                :recipient="slotProps.data"
                :delete="true"
                :show-recipient-tag="true"
              />
            </div>
          </div>
        </div>
      </template>
    </DraggableList>
  </div>
</template>

<style lang="scss">
.sign-count {
  color: #fff;
  background-color: rgb(47 53 66);
  box-shadow: inset 0px 0px 14px 0px #667085;
}
.recipient-drag {
  .handle {
    svg {
      path {
        stroke: white;
      }
    }
  }
}
</style>
