<script setup lang="ts">
import { UIFormItem, UISelect, UIAlert, UIButton } from '@gohighlevel/ghl-ui'
import { useElementOption } from '@/composable/elementOptions'
import { useContact, useDocument } from '@/composition'
import { useAppStore } from '@/store/app'
import { computed, onMounted, ref } from 'vue'
import {
  IRecipient,
  RecipientEntityTypeEnum,
  APP_STORE_META,
  useTypography,
  renderLabelAvatarOnSelect,
  getRecipientParamsFromContact,
} from '@gohighlevel/ghl-proposals-common'
import { debounce } from '@/util'
import NoEmailErrorModal from './NoEmailErrorModal.vue'
import { useBuilderStore } from '@/store/builder'
import { useI18n } from 'vue-i18n'
import { useEmitter } from '@/composable/useEmitter'

const { t } = useI18n()
const { toCapitalize } = useTypography()

const convertToOptions = (list = [] as IRecipient[]) => {
  return list.reduce((acc, contact: IRecipient) => {
    acc.push({
      ...contact,
      label: toCapitalize(
        contact?.contactName || `${contact.firstName} ${contact.lastName}`
      ).trim(),
      value: contact.id,
    })
    return acc
  }, [] as (IRecipient & { value: string; label: string })[])
}

const appStore = useAppStore()
const emitter = useEmitter()
const recipient = computed(() => {
  const primaryRecipient = appStore.document?.recipients.filter((el: any) => {
    return el.isPrimary === true
  })
  return primaryRecipient[0] ?? null
})
const { syncRecipient, syncContactIdInLinkedCustomValues } = useBuilderStore()
const { loading, searchContacts } = useContact()
const { updateDocumentOption } = useElementOption()
const store = useBuilderStore()
const { saveDocument, fetchAllCustomValues } = useDocument()
const optionsRef = ref(
  recipient.value ? convertToOptions([{ ...recipient.value }]) : []
)
const showErrorModal = ref(false)

const props = defineProps({
  labelName: { type: String, default: 'Primary Client' },
})
const isPrimaryRecipientExist = computed(() => {
  const primaryRecipient = appStore.document?.recipients.find(elem => {
    return elem.isPrimary === true
  })
  return primaryRecipient ? true : false
})

const rule = {
  trigger: ['input', 'blur'],
  validator() {
    if (!recipient.value?.id) {
      return new Error(t('validations.required.selectContact'))
    }
  },
}

const onClickNewItem = () => {
  emitter.emit('open-create-contact-model')
}

const fetchContacts = async (searchStr: string) => {
  const response = await searchContacts(searchStr, 10)
  optionsRef.value = convertToOptions(
    response?.contacts as unknown as IRecipient[]
  )
}

onMounted(() => {
  fetchContacts('')
  emitter.on('contact-added', newContact => {
    const duplicateContact = optionsRef.value.find((el: any) => {
      return el.id === newContact.id
    })

    if (!duplicateContact) {
      optionsRef.value = convertToOptions([...optionsRef.value, newContact])
    }
  })
})

const handleSearch = async (searchStr: string) => {
  await fetchContacts(searchStr)
}

const onChange = (value: string) => {
  const item = optionsRef.value.find(
    (contact: IRecipient) => contact.id === value
  ) as IRecipient
  if (item?.id && item?.email) {
    const allRecipientExceptPrimary = appStore.document?.recipients.filter(
      elem => {
        return elem.isPrimary === false && item.id !== elem.id
      }
    )

    const contactDetails = getRecipientParamsFromContact(
      item,
      RecipientEntityTypeEnum.CONTACTS,
      true
    )

    const allRecipients = [...allRecipientExceptPrimary, contactDetails]
    updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
    syncRecipient()
    syncContactIdInLinkedCustomValues()
    saveDocument().then(() => {
      fetchAllCustomValues()
    })
  } else {
    openErrorModal()
    if (!recipient.value?.email) {
      updateDocumentOption(APP_STORE_META.RECIPIENTS, [])
    }
  }
}

const openErrorModal = () => {
  showErrorModal.value = !showErrorModal.value
}

const closeModal = () => {
  showErrorModal.value = false
}
</script>

<template>
  <div :key="props.labelName">
    <NoEmailErrorModal :show="showErrorModal" @close-modal="closeModal" />
    <div class="flex flex-col justify-items-end proposal-pages-scroll">
      <div
        class="my-2"
        v-if="recipient && !recipient?.firstName && !recipient?.lastName"
      >
        <UIAlert
          id="nameMissingInPrimaryUserWarning"
          type="warning"
          :closable="false"
        >
          <template #title>{{
            $t('common.nameMissingInPrimaryUserWarning')
          }}</template>
        </UIAlert>
      </div>

      <div class="flex items-center justify-between">
        <label class="block text-sm font-medium text-gray-700">
          {{ $t('common.primaryClient') }}
        </label>
        <UIButton
          id="addNewClient"
          @click="onClickNewItem"
          :text="true"
          v-if="!isPrimaryRecipientExist"
        >
          {{ $t('common.addNewClient') }}
        </UIButton>
      </div>

      <UIFormItem :rule="rule" :key="props.labelName" class="-mt-6">
        <UISelect
          :disabled="!store.isDocumentEditable"
          :id="`${props.labelName}-${recipient?.id}`"
          :value="recipient?.id ?? null"
          :placeholder="$t('common.contacts')"
          filterable
          :loading="loading"
          :options="optionsRef"
          remote
          :onUpdate:value="onChange"
          :onSearch="debounce(handleSearch, 300)"
          :render-label="renderLabelAvatarOnSelect"
        />
      </UIFormItem>
    </div>
    <!-- <RecipientCard
      :recipient="recipient"
      :show-recipient-tag="true"
      :delete="false"
    /> -->
  </div>
</template>
