import {
  APP_STORE_META,
  ComponentOptionsTypes,
  CustomDocumentFields,
  GROUP_LOOKUP,
  IRecipient,
} from '@gohighlevel/ghl-proposals-common'

import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'
import { computed } from 'vue'

export function useElementOption() {
  const store = useBuilderStore()
  const appStore = useAppStore()

  const activeElement = computed(() => store.activeElement)
  const activeElementId = computed(() => store.activeElement?.id)

  function updateOption(
    key: string,
    value: ComponentOptionsTypes | number,
    id?: string,
    type?: string
  ) {
    store.updateElementOption({
      id: id || (activeElement.value?.id as string),
      key,
      value,
      type: type || activeElement.value?.type,
    })
  }

  function updateStyles(key: string, value: any, id?: string, type?: string) {
    store.updateElementStyle({
      id: id || (activeElement.value?.id as string),
      key,
      value,
      type: type || activeElement.value?.type,
    })
  }

  function updateDocumentOption(
    key: keyof CustomDocumentFields,
    value: CustomDocumentFields[keyof CustomDocumentFields]
  ) {
    if (key === APP_STORE_META.RECIPIENTS) {
      appStore.updateDocumentOption(
        key,
        (value as IRecipient[])?.map((recipient, index) => {
          return {
            ...recipient,
            signingOrder: index + 1,
          }
        })
      )
      store.autoAssignIfRecipientIdIsNotPresentInRecipientList()
    } else {
      appStore.updateDocumentOption(key, value)
    }
  }

  function updateGroupOption(
    key: string,
    value: ComponentOptionsTypes | number,
    id: string
  ) {
    store.updateGroupOption({
      id,
      key,
      value,
    })
  }

  function updateGroupStyle(key: string, value: any | number, id: string) {
    store.updateGroupStyle({
      id,
      key,
      value,
      type: GROUP_LOOKUP.GROUP_FIELDS,
    })
  }

  return {
    updateOption,
    activeElement,
    activeElementId,
    updateStyles,
    updateDocumentOption,
    updateGroupOption,
    updateGroupStyle,
  }
}
