import {
  FetchBusinessProductByLocationIdResponse,
  ICreateProductPayload,
} from '@gohighlevel/ghl-proposals-common'

import { ProductService } from '@/service/ProductService'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export const useProducts = () => {
  const loading = ref(false)
  const route = useRoute()

  const fetchBusinessProducts = async (
    search = ''
  ): Promise<FetchBusinessProductByLocationIdResponse> => {
    try {
      loading.value = true
      const locationId = route.params.locationId as string
      const { data } = await ProductService.fetchProductListByLocationId({
        locationId,
        search,
      })
      return data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      loading.value = false
    }
  }
  const createNewProduct = async (
    payload: Omit<ICreateProductPayload, 'locationId'>
  ) => {
    try {
      loading.value = true
      const locationId = route.params.locationId as string
      const { data } = await ProductService.createNewProduct({
        ...payload,
        locationId,
      })
      return data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      loading.value = false
    }
  }
  return {
    loading,
    fetchBusinessProducts,
    createNewProduct,
  }
}
