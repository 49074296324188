import * as moment from 'moment-timezone'

import { TimeZoneEnum } from '@gohighlevel/ghl-proposals-common'
import { sortBy } from 'lodash'

const timezonesWhiteList = Object.values(TimeZoneEnum) as string[]

export const timezones = sortBy(
  moment.tz
    .names()
    .filter(tz => {
      // tz === this.timezone
      return timezonesWhiteList.includes(tz)
    })
    .map(zone => {
      const now = moment.tz(zone)
      return {
        label: `GMT${now.format('Z')} ${zone} (${now.format('z')})`,
        value: zone,
        currentTime: now.format('hh:mm A'),
      }
    }),
  m => {
    return moment.tz(m.value).utcOffset()
  }
)

export const getTimeZoneAbbreviation = (timezone: TimeZoneEnum) => {
  return moment.tz(timezone).format('z')
}
