<script lang="ts" setup>
import { UITextMdMedium, UITextMdNormal } from '@gohighlevel/ghl-ui'
import { computed, PropType } from 'vue'
import {
  IElement,
  IRecipient,
  MovableComponentEvents,
  SignatureElementOptions,
} from '../../types'

import { PencilLineIcon } from '@gohighlevel/ghl-icons/24/outline'
import { MovableComponentWrapper } from '../MovableComponentWrapper'

import { getSignatureElementColorStyles } from '../../utils'
import CommonTooltip from '../Tooltip/CommonTooltip.vue'

const props = defineProps({
  element: {
    type: Object as PropType<IElement<SignatureElementOptions>>,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  snapContainer: {
    type: String,
  },
  boundContainer: {
    type: String,
  },
  storeMap: {
    type: Object,
    required: true,
  },
  compositionMap: {
    type: Object,
    required: true,
  },
})
const isActiveElement = computed(() => props.isActive)
const builderStore = props.storeMap.useBuilderStore()
const emits = defineEmits([MovableComponentEvents.ON_ACTIVE])
const { updateStyles, updateOption } = props.compositionMap.useElementOption()
const store = props.storeMap.useAppStore()

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id }) => id === props.element?.component.options.recipient
    ) || ({} as IRecipient)
  )
})
const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return firstName ? `${firstName} ${lastName || ''}` : ''
})
const customClassesForImg = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return [styles?.align]
})

const customStyleForImageParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
  }
})

const onDrag = (styles: any) => {
  updateStyles('position', styles, props.element?.id)
  updateOption('isGhost', true, props.element?.id)
}

const onScale = ({ scaleX, scaleY }: { scaleX: number; scaleY: number }) => {
  updateStyles('scale', { scaleX, scaleY }, props.element?.id)
}
const onResize = ({ height, width }: { height: number; width: number }) => {
  updateStyles('dimensions', { height, width }, props.element?.id)
}
const onActive = (e: any, isActive: boolean) => {
  emits(MovableComponentEvents.ON_ACTIVE, e, isActive)
}
function del(e: any) {
  e.stopPropagation()
  builderStore.deleteElement(props?.element?.id as string)
  builderStore.syncRecipient()
}

function clone(e: any) {
  e.stopPropagation()
  builderStore.cloneElement(props?.element?.id as string)
}
const styles = computed(() => getSignatureElementColorStyles(recipient.value))
const dimensions = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  return dimensionsObj || { height: 68, width: 190 }
})
const colorStyles = computed(() => {
  const dimensionsObj = dimensions.value

  return {
    ...styles.value,
    color: styles.value.placeholderColor,
    marginTop: '-1px',
    marginLeft: '-1px',
    border: `1px solid ${styles.value.borderColor} !important`,
    'box-shadow': 'none !important',
    overflow: 'hidden',
    ...(dimensionsObj
      ? {
          height: `${dimensionsObj.height}px`,
          width: `${dimensionsObj.width}px`,
        }
      : {}),
  }
})
const position = computed(() => {
  const pos = props.element?.responsiveStyles.large.position
  return pos || { top: 0, left: 0, bottom: 0 }
})
const scale = computed(() => {
  const scaleObj = props.element?.responsiveStyles.large.scale
  return scaleObj || { scaleX: 1, scaleY: 1 }
})
const align = computed(() => props.element?.responsiveStyles.large.align)

const onDragEnd = (options: any) => {
  builderStore.moveFloatingElement(props.element?.id as string, options)
}
</script>

<template>
  <MovableComponentWrapper
    :key="`moveable-element-${props.element?.id}`"
    :id="(props.element?.id as string)"
    @on-drag="onDrag"
    @on-scale="onScale"
    @on-active="onActive"
    @on-resize="onResize"
    @on-drag-end="onDragEnd"
    :isActiveElement="isActiveElement"
    :isEditable="builderStore.isDocumentEditable"
    :position="position"
    :scale="scale"
    :align="align"
    :dimensions="dimensions"
    :isGhost="props.element?.component.options.isGhost"
    :snap-container="props.snapContainer"
    :bound-container="props.boundContainer"
    :isDragElement="props.element?.component.isDraggable"
    :resizable="true"
    :keep-ratio="false"
    :colorOverride="styles.placeholderColor"
    :inactiveColorOverride="styles.borderColor"
  >
    <template #component>
      <span class="option--required">*</span>
      <div class="el-wrapper signature-el">
        <div
          :style="{
            ...customStyleForImageParent,
            width:
              props.element?.version < 2 &&
              !props.element?.component.isDraggable
                ? 'auto'
                : '100%',
          }"
          class="w-full h-full"
          :class="{
            [customClassesForImg.join(' ') as string]: true,
          }"
        >
          <div
            class="flex"
            :class="{
              'flex-1':
                props.element?.version >= 1 &&
                props.element?.component.isDraggable,
              'flex-col':
                props.element?.version < 2 &&
                !props.element?.component.isDraggable,
            }"
          >
            <div
              class="capitalize"
              v-if="recipientName && props.element.component.options.showName"
              :class="{
                'recipient-name':
                  props.element?.version > 0 &&
                  props.element?.component.isDraggable,
              }"
            >
              <UITextMdMedium>{{ recipientName }}</UITextMdMedium>
            </div>
            <div
              :style="colorStyles"
              :class="{
                'justify-center rounded-sm border whitespace-pre-line':
                  !recipient.imgUrl,
              }"
              class="flex items-center text-[14px]"
            >
              <div
                v-if="recipient.imgUrl"
                class="flex h-full max-w-full"
                style="flex: 1 0 auto; max-width: 100%"
              >
                <img
                  style="height: 100%; margin: auto 0; max-width: 100%"
                  :src="props.element.component.options.src || recipient.imgUrl"
                  crossorigin="anonymous"
                />
              </div>
              <div v-else class="flex items-center overflow-hidden">
                <PencilLineIcon class="h-4 w-4 mr-2" />
                <UITextMdNormal
                  :style="{
                    'text-overflow': 'ellipsis',
                    'white-space': 'nowrap',
                    overflow: 'hidden',
                  }"
                  >{{ props.element?.component.options.text }}</UITextMdNormal
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #popup-options>
      <CommonTooltip @on-clone="clone" @on-delete="del" />
    </template>
  </MovableComponentWrapper>
</template>

<style lang="scss">
.signature-el {
  position: relative;
  .recipient-name {
    position: absolute;
    top: -25px;
    left: 0;
  }
}
</style>
