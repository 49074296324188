<script lang="ts" setup>
import { useBuilderStore } from '@/store/builder'
import {
  RTEControlBold,
  RTEControlColorPicker,
  RTEControlEmbedLink,
  RTEControlFontFamily,
  RTEControlFontSize,
  RTEControlItalic,
  RTEControlLineHeight,
  RTEControlList,
  RTEControlResetStyles,
  RTEControlStrike,
  RTEControlTextAlignment,
  RTEControlTextBlocks,
  RTEControlUnderline,
  RTEControlCustomSelect,
} from '@gohighlevel/ghl-text-editor'
import { UIDivider } from '@gohighlevel/ghl-ui'
import { useAppStore } from '@/store/app'
import {
  useCustomValues,
  CustomSelectTrigger,
} from '@gohighlevel/ghl-proposals-common'

const store = useBuilderStore()
const appStore = useAppStore()
const { staticCustomValues, customMenuOptionsWithoutOpp } =
  useCustomValues('customValues')
</script>

<template>
  <div
    :key="'editor-toolbar-' + store.activeElementId"
    id="editor-toolbar"
    :class="`flex justify-center items-center space-x-2 ${
      !store.isDocumentEditable ? 'pointer-events-none opacity-50' : ''
    }`"
  >
    <template v-if="store.editor">
      <RTEControlFontFamily
        :fallback-font="'sans-serif'"
        :editor="store.editor"
        :language="appStore.locale"
      />
      <RTEControlFontSize
        :editor="store.editor"
        :language="appStore.locale"
        :fallbackFontSize="16"
      />
      <div>
        <RTEControlTextBlocks
          :editor="store.editor"
          :language="appStore.locale"
          :options="[
            'Heading1',
            'Heading2',
            'Heading3',
            'Heading4',
            'Heading5',
            'Paragraph',
          ]"
        />
      </div>

      <UIDivider :vertical="true" class="divider" />
      <RTEControlBold :editor="store.editor" :language="appStore.locale" />
      <RTEControlItalic :editor="store.editor" :language="appStore.locale" />
      <RTEControlUnderline :editor="store.editor" :language="appStore.locale" />
      <RTEControlStrike :editor="store.editor" :language="appStore.locale" />
      <UIDivider :vertical="true" class="divider" />
      <RTEControlEmbedLink :editor="store.editor" :language="appStore.locale" />
      <UIDivider :vertical="true" class="divider" />
      <RTEControlTextAlignment
        :align="'justify'"
        :editor="store.editor"
        :language="appStore.locale"
      />
      <RTEControlTextAlignment
        :align="'left'"
        :editor="store.editor"
        :language="appStore.locale"
      />
      <RTEControlTextAlignment
        :align="'center'"
        :editor="store.editor"
        :language="appStore.locale"
      />
      <RTEControlTextAlignment
        :align="'right'"
        :editor="store.editor"
        :language="appStore.locale"
      />
      <UIDivider :vertical="true" class="divider" />
      <RTEControlLineHeight
        :editor="store.editor"
        :language="appStore.locale"
        fallback-line-height="1.5"
        :line-height-options="[
          { value: '0.5', label: '0.5' },
          { value: '0.75', label: '0.75' },
          { value: '1', label: '1' },
          { value: '1.15', label: '1.15' },
          { value: '1.5', label: '1.5' },
          { value: '2', label: '2' },
        ]"
      />
      <UIDivider :vertical="true" class="divider" />
      <RTEControlList
        :editor="store.editor"
        listType="bulletList"
        :language="appStore.locale"
      />
      <RTEControlColorPicker
        :editor="store.editor"
        :language="appStore.locale"
      />
      <RTEControlResetStyles
        :editor="store.editor"
        :language="appStore.locale"
      />
      <RTEControlCustomSelect
        v-if="staticCustomValues"
        :editor="store.editor"
        :custom-menus="customMenuOptionsWithoutOpp"
      >
        <template #trigger="slotProps">
          <CustomSelectTrigger
            :title="slotProps.title"
            :icon="slotProps.icon"
          />
        </template>
      </RTEControlCustomSelect>
    </template>
  </div>
</template>

<style lang="scss">
#toolbar_left {
  align-items: center;
  height: 100%;
  width: 10rem;
  justify-content: space-evenly;
  border-right: 1px solid #eaecf0;
}
#toolbar_select--text_block {
  min-width: 120px;
}
#toolbar_select--font_family {
  min-width: 145px;
}
.n-popover.n-popover-shared.n-popover--raw.n-popover-shared--show-arrow {
  .n-popover__content {
    background-color: #fff;
  }
}
.editor__line-height {
  min-width: 72px !important;
}
</style>
