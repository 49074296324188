<script lang="ts" setup>
import { useDocument } from '@/composition'
import { editor } from '@/helper/const'
import { useBuilderStore } from '@/store/builder'
import { IElement } from '@gohighlevel/ghl-proposals-common'
import { PropType, computed, ref, watch } from 'vue'
import RichTextTableContent from '../RichTextTableContent.vue'
import { useAppStore } from '@/store/app'

const props = defineProps({
  element: {
    type: Object as PropType<IElement>,
    required: true,
  },
})
const { loadingCustomValues } = useDocument()
const store = useBuilderStore()
const appStore = useAppStore()
const flatCustomValues = computed(() => ({
  ...store.flatCustomValues,
  ...appStore.flatDocumentVariables,
}))
const refreshCount = ref(0)

const isEditable = computed(() => store.isDocumentEditable)

watch(
  () => [flatCustomValues.value, loadingCustomValues.value],
  () => {
    refreshCount.value = 1 + refreshCount.value
  }
)

const customStyleForTextParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
  }
})
</script>

<template>
  <div v-if="editor" class="w-full">
    <div :style="customStyleForTextParent" class="w-full box-border">
      <div
        class="el-wrapper table-el w-full"
        :class="!isEditable ? 'pointer-events' : ''"
        :key="refreshCount"
      >
        <RichTextTableContent
          :element="props.element"
          :custom-values="flatCustomValues"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 1px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.pointer-events {
  pointer-events: none;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
