import { EnvConfigType, Envs } from '@gohighlevel/ghl-proposals-common'

const config: EnvConfigType = {
  development: {
    mode: 'dev',
    PROPOSAL_SERVICE_URL: 'http://localhost:5092/proposals',
    MEDIA_CENTER_URL: 'https://hl-media-center.leadconnectorhq.com',
    CONTACT_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/contacts',
    PRODUCT_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/products',
    DOCUMENT_VIEWER_URL: 'http://localhost:3041',
    TAX_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments/taxes',
    PUBLIC_API_URL: 'https://public-api-dot-highlevel-staging.appspot.com',
    USER_SERVICE_URL: 'https://staging.services.leadconnectorhq.com/users',
    CUSTOM_DATA_SERVICE_URL: 'https://staging.services.leadconnectorhq.com',
    PAYMENTS_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments',
  },
  staging: {
    mode: 'staging',
    PROPOSAL_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/proposals',
    MEDIA_CENTER_URL: 'https://hl-media-center.leadconnectorhq.com',
    CONTACT_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/contacts',
    PRODUCT_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/products',
    DOCUMENT_VIEWER_URL: 'https://staging.sendlink.co',
    TAX_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments/taxes',
    PUBLIC_API_URL: 'https://public-api-dot-highlevel-staging.appspot.com',
    USER_SERVICE_URL: 'https://staging.services.leadconnectorhq.com/users',
    CUSTOM_DATA_SERVICE_URL: 'https://staging.services.leadconnectorhq.com',
    PAYMENTS_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments',
  },
  beta: {
    mode: 'beta',
    PROPOSAL_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/proposals',
    MEDIA_CENTER_URL: 'https://media-center-prod.leadconnectorhq.com',
    CONTACT_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/contacts',
    PRODUCT_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/products',
    DOCUMENT_VIEWER_URL: 'https://staging.sendlink.co',
    TAX_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments/taxes',
    PUBLIC_API_URL: 'https://rest.gohighlevel.com',
    USER_SERVICE_URL: 'https://staging.services.leadconnectorhq.com/users',
    CUSTOM_DATA_SERVICE_URL: 'https://beta.services.msgsndr.com',
    PAYMENTS_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/payments',
  },
  production: {
    mode: 'production',
    PROPOSAL_SERVICE_URL: 'https://services.leadconnectorhq.com/proposals',
    MEDIA_CENTER_URL: 'https://media-center-prod.leadconnectorhq.com',
    CONTACT_SERVICE_URL: 'https://services.leadconnectorhq.com/contacts',
    PRODUCT_SERVICE_URL: 'https://services.leadconnectorhq.com/products',
    DOCUMENT_VIEWER_URL: 'https://sendlink.co',
    TAX_SERVICE_URL: 'https://services.leadconnectorhq.com/payments/taxes',
    PUBLIC_API_URL: 'https://rest.gohighlevel.com',
    USER_SERVICE_URL: 'https://services.leadconnectorhq.com/users',
    CUSTOM_DATA_SERVICE_URL: 'https://services.leadconnectorhq.com',
    PAYMENTS_SERVICE_URL: 'https://services.leadconnectorhq.com/payments',
  },
}

const override = false

const overrideConfig = {}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const envConfig = config[process.env.NODE_ENV as Envs]

export default override ? { ...envConfig, ...overrideConfig } : envConfig
