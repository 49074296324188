<script setup lang="ts">
import { useBuilderStore } from '@/store/builder'
import { UIDrawer, UIDrawerContent } from '@gohighlevel/ghl-ui'
import { computed, defineComponent } from 'vue'
import { ELEMENTS_LOOKUP } from '@gohighlevel/ghl-proposals-common'
import EmptyStyle from './elements-style-groups/EmptyStyle.vue'
import GroupImage from './elements-style-groups/GroupImage.vue'
import GroupPage from './elements-style-groups/GroupPage.vue'
import GroupSignature from './elements-style-groups/GroupSignature.vue'
import GroupText from './elements-style-groups/GroupText.vue'
import GroupTable from './elements-style-groups/GroupTable.vue'
import GroupVideo from './elements-style-groups/GroupVideo.vue'
import GroupTextField from './elements-style-groups/GroupTextField.vue'
import GroupDateField from './elements-style-groups/GroupDateField.vue'
import GroupInitialsField from './elements-style-groups/GroupInitialsField.vue'
import GroupCheckBoxField from './elements-style-groups/GroupCheckBoxField.vue'
import GroupCheckBoxGrouping from './elements-style-groups/GroupCheckBoxGrouping.vue'
import { GroupCProductList } from './elements-style-groups/GroupCProductList'
import { usePropertiesDrawer } from '@/composable/usePropertiesDrawer'
import { useGroups } from '@/composition'

defineComponent({
  GroupImage,
})

const store = useBuilderStore()
const { selectoGroupTargets } = useGroups()
const { isOpen, setClose } = usePropertiesDrawer()
const activeElement = computed(() => store.activeElement)
const groupTargets = computed(
  () =>
    selectoGroupTargets &&
    selectoGroupTargets.value &&
    selectoGroupTargets.value.length
)
const isActiveElementPresent = computed(
  () => activeElement.value && Object.keys(activeElement.value).length
)
const open = computed(() => isOpen.value)
</script>

<template>
  <UIDrawer
    :trap-focus="false"
    :width="350"
    id="active-elements-drawer"
    :show="open"
    :placement="'right'"
    to="#active-element-drawer-target"
    @update:show="setClose"
    style="z-index: 2001"
    class="overflow-y-visible !shadow-page h-screen"
  >
    <UIDrawerContent
      id="properties-drawer-content"
      :key="`properties-drawer-content-${activeElement?.id}-${groupTargets?.length}`"
      :title="$t('common.properties')"
    >
      <div class="flex flex-col justify-items-end overflow-y-auto max-h-[78vh]">
        <div class="px-2 mt-3">
          <GroupImage
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.IMAGE"
          />
          <GroupText
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.TEXT"
          />
          <GroupTable
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.TABLE"
          />
          <GroupVideo
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.VIDEO"
          >
          </GroupVideo>
          <GroupSignature
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.SIGNATURE"
          >
          </GroupSignature>
          <GroupTextField
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.TEXT_FIELD"
          >
          </GroupTextField>
          <GroupDateField
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.DATE_FIELD"
          >
          </GroupDateField>
          <GroupCheckBoxField
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.CHECKBOX"
          >
          </GroupCheckBoxField>
          <GroupInitialsField
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.INITIALS_FIELD"
          >
          </GroupInitialsField>
          <GroupPage
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.PAGE"
          >
          </GroupPage>
          <GroupCProductList
            :element="activeElement"
            v-if="activeElement?.type === ELEMENTS_LOOKUP.PRODUCT_LIST"
          ></GroupCProductList>

          <GroupCheckBoxGrouping
            v-if="!isActiveElementPresent && groupTargets"
          />
          <div
            v-if="!isActiveElementPresent && !groupTargets"
            class="flex w-full h-full"
          >
            <EmptyStyle />
          </div>
        </div>
      </div>
    </UIDrawerContent>
  </UIDrawer>
</template>

<style lang="scss">
#active-elements-drawer {
  .n-drawer-body-content-wrapper {
    padding: 0px;
  }
}
</style>
