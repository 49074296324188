import { useGroups } from '@/composition'
import { useBuilderStore } from '@/store/builder'
import { computed, ref, watch } from 'vue'

const isOpen = ref(false)
export const usePropertiesDrawer = () => {
  const store = useBuilderStore()
  const activeGr = computed(() => store.activeGroup)
  const activeEl = computed(() => store.activeElement)
  const { selectoGroupTargets } = useGroups()
  const groupTargets = computed(
    () =>
      selectoGroupTargets &&
      selectoGroupTargets.value &&
      selectoGroupTargets.value.length
  )

  const setOpen = () => {
    isOpen.value = true
  }
  const setClose = () => {
    isOpen.value = false
  }
  const toggleDrawer = () => {
    isOpen.value = !isOpen.value
  }
  watch([activeEl, activeGr, groupTargets], () => {
    if (
      activeEl.value ||
      (activeGr.value && Object.keys(activeGr.value).length > 0) ||
      groupTargets.value
    ) {
      setOpen()
    } else {
      setClose()
    }
  })
  return {
    isOpen,
    setOpen,
    setClose,
    toggleDrawer,
  }
}
