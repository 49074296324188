<script lang="ts" setup>
import { DOC_VAR_PREFIX } from '@/helper/const'
import {
  UIForm,
  UIFormItem,
  UIInput,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
} from '@gohighlevel/ghl-ui'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  availableVariables: {
    type: Array,
    default: () => [],
  },
})
const root = ref()
watch(
  () => props.show,
  () => {
    showModal.value = props.show
  }
)
const emit = defineEmits(['update:show', 'update:newVariable'])

const showModal = ref(false)

function submitCallback() {
  root.value
    .getForm()
    .validate()
    .then(() => {
      const form = root.value.getForm()
      const type = 'custom'
      const { fieldKey, value } = form.model
      emit('update:newVariable', { fieldKey, value, type })
      formValue.value = {
        fieldKey: '',
        value: '',
      }
      hideModal(false)
    })
    .catch(e => console.error('error', e))
}

function cancelCallback() {
  hideModal(false)
}

const specialCharRegex = /[ `!@#$%^&*()+\-=[\]{};':"\\|,.<>/?~]/
const rules = {
  value: {
    required: true,
    message: t('validations.required.documentVariable'),
    trigger: 'blur',
  },
  fieldKey: {
    required: true,
    validator(rule: any, value: any) {
      if (specialCharRegex.test(value)) {
        return new Error(t('validations.invalid.specialCharacter'))
      } else if (/\s/g.test(value)) {
        return new Error(t('validations.invalid.whiteSpace'))
      } else if (
        props.availableVariables.includes(`${DOC_VAR_PREFIX}.${value}`)
      ) {
        return new Error(t('validations.invalid.documentVariableExist'))
      }
      return true
    },
    trigger: ['input', 'blur'],
  },
}
const formValue = ref({
  fieldKey: '',
  value: '',
})

function hideModal(val: any) {
  emit('update:show', val)
}
</script>

<template>
  <UIModal id="modal" :show="props.show" @update:show="hideModal">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :description="$t('proposals.documentVariableDescription')"
        :title="$t('proposals.documentVariableTitle')"
        type="success"
        @close="cancelCallback"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIForm id="modal-form" ref="root" :model="formValue" :rules="rules">
        <UIFormItem :label="$t('common.variableName')" path="fieldKey">
          <UIInput
            id="modal-form-input"
            v-model="formValue.fieldKey"
            :placeholder="$t('common.variableName')"
          />
        </UIFormItem>

        <UIFormItem :label="$t('common.variableValue')" path="value">
          <UIInput
            id="modal-form-input1"
            v-model="formValue.value"
            :placeholder="$t('common.variableValue')"
          />
        </UIFormItem>
      </UIForm>
    </UIModalContent>

    <template #footer>
      <UIModalFooter
        id="modal-footer"
        :negativeText="$t('common.cancel')"
        :positiveText="$t('common.save')"
        @positive-click="submitCallback"
        @negative-click="cancelCallback"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>
