<script setup lang="ts">
import {
  UIRadioGroup,
  UIRadioGroupItem,
  UITextSmRegular,
  UISpace,
} from '@gohighlevel/ghl-ui'
import {
  CurrencyDollarIcon,
  CalculatorIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { computed } from 'vue'
import { PriceTypeEnum } from '../../types'

const emits = defineEmits(['onInvoiceCategoryTypeUpdate'])

const props = defineProps({
  invoiceType: {
    type: String,
    required: true,
  },
})

const invoiceCategoryType = computed(() => props.invoiceType)

const onChangeInvoiceCategoryType = (value: PriceTypeEnum) => {
  emits('onInvoiceCategoryTypeUpdate', value)
}
</script>

<template>
  <div class="mt-3 mb-6">
    <div class="mt-2">
      <UITextSmRegular class="text-gray-700 font-medium my-4">{{
        $t('common.invoiceType')
      }}</UITextSmRegular>
    </div>

    <div class="py-8 mb-8">
      <UIRadioGroup
        v-model:value="invoiceCategoryType"
        @update:value="onChangeInvoiceCategoryType"
        name="invoice_type_category"
      >
        <UISpace horizontal>
          <UIRadioGroupItem :value="PriceTypeEnum.ONETIME" :compact="true">
            <template #icon><CurrencyDollarIcon class="w-4 h-4" /></template>
            <template #content>
              <div>One Time</div>
            </template>
          </UIRadioGroupItem>

          <UIRadioGroupItem :value="PriceTypeEnum.RECURRING" :compact="true">
            <template #icon><CalculatorIcon class="w-4 h-4" /></template>
            <template #content>
              <div>Recurring</div>
            </template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
    </div>
  </div>
</template>

<style lang="scss">
.hl-radio-group-item.n-radio-button.compact-radio-item {
  padding: 8px 8px;
}
</style>
