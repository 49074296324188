<script lang="ts" setup>
import { useEmitter } from '@/composable/useEmitter'
import { Image01Icon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UIRadioGroup,
  UIRadioGroupItem,
  UISpace,
} from '@gohighlevel/ghl-ui'
import { onBeforeUnmount, PropType, ref, watch } from 'vue'
import {
  ELEMENTS_META,
  ELEMENTS_STYLES,
  IElement,
  UIBuilderSpacing,
  getMarginProperties,
  getPaddingProperties,
  onChangeMarginPadding,
} from '@gohighlevel/ghl-proposals-common'
import { useElementOption } from '../../../composable/elementOptions'
import ColorPickerComponent from '../../common/ColorPickerComponent.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  element: Object as PropType<IElement>,
})

const { updateOption, updateStyles } = useElementOption()

const bgColor = ref(props.element?.responsiveStyles?.large?.backgroundColor)
const margin = ref(getMarginProperties(props.element))
const padding = ref(getPaddingProperties(props.element))

watch(props, _newValue => {
  if (props.element) {
    bgColor.value = props.element?.responsiveStyles?.large?.backgroundColor
    margin.value = getMarginProperties(props.element)
    padding.value = getPaddingProperties(props.element)
  }
})

const emitter = useEmitter()
emitter.on('media-library-selected', onSelectImage)
onBeforeUnmount(() => {
  emitter.off('media-library-selected', onSelectImage)
})

function onSelectImage(image: any) {
  if (!image) return
  updateOption(ELEMENTS_META.SRC, image.url)
}

function openMediaPicker() {
  emitter.emit('open-media-picker', true)
}

const positiveNumberRegex = /^\d*\.?\d{0,2}$/
const rules = {
  height: {
    trigger: ['input', 'blur', 'change'],
    required: true,
    validator() {
      const heightProps = props.element?.responsiveStyles?.large?.height

      if (!heightProps && props.element?.component.options.src) {
        return new Error(t('validations.required.height'))
      }
      if (heightProps && !positiveNumberRegex.test(heightProps)) {
        return new Error(t('validations.invalid.positiveNumbers'))
      }
      return true
    },
  },
  width: {
    trigger: ['input', 'blur', 'change'],
    required: true,
    validator() {
      const widthProps = props.element?.responsiveStyles?.large?.width

      if (!widthProps && props.element?.component.options.src) {
        return new Error(t('validations.required.width'))
      }
      if (widthProps && !positiveNumberRegex.test(widthProps)) {
        return new Error(t('validations.invalid.positiveNumbers'))
      }
      return true
    },
  },
}

/*
 * height and width should not be empty.
 * if its empty the calculation of offsite in PDF generation will be wrong and because of that,
 * the content will be eliminated from the PDF - So we should have the height & width
 */
const updateHeightOrWidth = (key: string, value: number) => {
  if (value && value > 0) {
    updateStyles(key, value)
  }
}
const onBoxStyleChange = onChangeMarginPadding(updateStyles)
</script>

<template>
  <div class="hl-proposal-sidebar-content">
    <UIFormItem :label="$t('common.imageUrl')">
      <UIInputGroup>
        <UIInput
          id="image-src"
          :model-value="element?.component.options.src"
          @update:model-value="val => updateOption(ELEMENTS_META.SRC, val)"
        />
        <UIButton
          id="image-picker"
          class="ml-2"
          variant="outline"
          @click="openMediaPicker"
        >
          <Image01Icon class="w-4 h-4" />
        </UIButton>
      </UIInputGroup>
    </UIFormItem>
    <!-- <UIFormItem label="Image Alt Text">
      <UIInput
        id="image-alt-text"
        :model-value="element?.component.options.altText"
        @update:model-value="val => updateOption(ELEMENTS_META.ALT_TEXT, val)"
      />
    </UIFormItem>
    <UIFormItem label="Link">
      <UIInput
        id="image-action-link"
        :model-value="element?.component.options.href"
        placeholder="www.example.com"
        @update:model-value="val => updateOption(ELEMENTS_META.HREF, val)"
      >
        <template #prefix>https://</template>
      </UIInput>
    </UIFormItem> -->
    <UIFormItem :label="$t('common.align')">
      <UIRadioGroup
        :value="element?.responsiveStyles.large.align"
        @update:value="val => updateStyles(ELEMENTS_STYLES.ALIGN, val)"
      >
        <UISpace>
          <UIRadioGroupItem value="img-left">
            <template #content> {{ $t('common.left') }}</template>
          </UIRadioGroupItem>
          <UIRadioGroupItem value="img-center">
            <template #content> {{ $t('common.center') }}</template>
          </UIRadioGroupItem>
          <UIRadioGroupItem value="img-right">
            <template #content>{{ $t('common.right') }}</template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
    </UIFormItem>

    <UIFormItem :label="$t('common.imageEffect')">
      <UIRadioGroup
        :value="element?.responsiveStyles.large.imageEffect"
        @update:value="val => updateStyles(ELEMENTS_STYLES.IMAGE_EFFECT, val)"
      >
        <UISpace>
          <UIRadioGroupItem value="img-full-color">
            <template #content>{{ $t('common.fullColor') }}</template>
          </UIRadioGroupItem>
          <UIRadioGroupItem value="img-black-white">
            <template #content>{{ $t('common.blackAndWhite') }}</template>
          </UIRadioGroupItem>
        </UISpace>
      </UIRadioGroup>
    </UIFormItem>

    <ColorPickerComponent
      v-model="bgColor"
      :label="$t('common.backgroundColor')"
      @update:modelValue="
        updateStyles(
          ELEMENTS_STYLES.BACKGROUND_COLOR,
          bgColor,
          props.element?.id,
          props.element?.type
        )
      "
    />

    <UIFormItem :label="$t('common.height')" :rule="rules.height">
      <UIInput
        id="image-height"
        :modelValue="element?.responsiveStyles.large.height"
        placeholder=""
        @update:modelValue="
          val => updateHeightOrWidth(ELEMENTS_STYLES.HEIGHT, val)
        "
      >
        <template #suffix>px</template>
      </UIInput>
    </UIFormItem>
    <UIFormItem :label="$t('common.width')" :rule="rules.width">
      <UIInput
        id="image-width"
        :model-value="element?.responsiveStyles.large.width"
        placeholder=""
        @update:model-value="
          val => updateHeightOrWidth(ELEMENTS_STYLES.WIDTH, val)
        "
      >
        <template #suffix>px</template>
      </UIInput>
    </UIFormItem>
    <UIBuilderSpacing :element="props.element" @onUpdate="onBoxStyleChange" />
  </div>
</template>

<style lang="scss">
.hl-proposal-sidebar-content {
  .hl-radio-group-item-action {
    display: none;
  }

  .hl-radio-group-item.n-radio-button {
    padding: 5px;
  }
}
</style>
