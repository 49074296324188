<template>
  <div :tabindex="tabindex" @blur="isOpen = true" class="focus:outline-none">
    <button
      ref="btnColorPicker"
      type="button"
      @click="toggleColorPicker"
      class="w-10 h-10 rounded-full focus:outline-none focus:shadow-outline inline-flex p-2 shadow"
      :style="`background: ${state.colorSelected}; color: white;box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);`"
    >
      <svg
        class="w-6 h-6 fill-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="none"
          d="M15.584 10.001L13.998 8.417 5.903 16.512 5.374 18.626 7.488 18.097z"
        />
        <path
          d="M4.03,15.758l-1,4c-0.086,0.341,0.015,0.701,0.263,0.949C3.482,20.896,3.738,21,4,21c0.081,0,0.162-0.01,0.242-0.03l4-1 c0.176-0.044,0.337-0.135,0.465-0.263l8.292-8.292l1.294,1.292l1.414-1.414l-1.294-1.292L21,7.414 c0.378-0.378,0.586-0.88,0.586-1.414S21.378,4.964,21,4.586L19.414,3c-0.756-0.756-2.072-0.756-2.828,0l-2.589,2.589l-1.298-1.296 l-1.414,1.414l1.298,1.296l-8.29,8.29C4.165,15.421,4.074,15.582,4.03,15.758z M5.903,16.512l8.095-8.095l1.586,1.584 l-8.096,8.096l-2.114,0.529L5.903,16.512z"
        />
      </svg>
    </button>
    <teleport to="body">
      <div ref="colorPickerContainer" class="z-[9999] shadow-lg">
        <div
          v-if="isOpen"
          class="rounded-md bg-white border-2 color-picker-container float-left"
        >
          <div class="flex justify-between h-10">
            <div
              v-for="tab in state.tabs"
              :key="tab"
              class="text-center text-sm font-medium w-full h-full cursor-pointer text-gray-600 flex items-center justify-center"
              :class="{ 'bg-gray-200 border-b': state.activeTab !== tab }"
              @click="state.activeTab = tab"
            >
              {{ tab }}
            </div>
          </div>
          <div
            class="flex flex-wrap -mx-4 px-4 py-3"
            v-if="state.activeTab === 'Simple'"
          >
            <div v-for="(color, index) in state.colors" :key="index">
              <div class="px-2">
                <template v-if="state.colorSelected === color">
                  <div
                    class="w-8 h-8 inline-flex rounded-full cursor-pointer border-4 border-white"
                    :style="`background: ${color}; box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);`"
                  ></div>
                </template>

                <template v-if="state.colorSelected != color">
                  <div
                    @click="changeColor(color, $event)"
                    @keydown="state.colorSelected = color"
                    role="checkbox"
                    tabindex="0"
                    :aria-checked="state.colorSelected"
                    class="w-8 h-8 inline-flex rounded-full cursor-pointer border-4 border-white focus:outline-none focus:shadow-outline"
                    :style="`background: ${color}; box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);`"
                  ></div>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="flex justify-center">
            <ColorPicker
              class="py-2"
              theme="light"
              :color="state.color"
              :sucker-hide="true"
              @changeColor="
                color => {
                  const { r, g, b, a } = color.rgba
                  state.colorSelected = `rgba(${r}, ${g}, ${b}, ${a})`
                }
              "
              :colors-default="[]"
              :key="state.updatePicker"
            />
          </div>
          <div class="absolute bottom-0 w-full p-3">
            <button
              @click="changeToDefault"
              class="focus:outline-none bg-gray-200 p-2 border border-gray-400 w-full rounded text-xs hover:bg-gray-100"
            >
              {{ $t('common.resetToDefault') }}
            </button>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue'
const tabs = ['Simple', 'Advanced']
import { createPopper } from '@popperjs/core'
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'

const colors = [
  '#2196F3',
  '#009688',
  '#9C27B0',
  '#FFEB3B',
  '#afbbc9',
  '#4CAF50',
  '#2d3748',
  '#f56565',
  '#ed64a6',
  '#A0AEC0',
  '#F6E05E',
  '#68D391',
  '#63B3ED',
  '#7F9CF5',
  '#F687B3',
  '#B794F4',
  '#FFF5F7',
  '#B83280',
  '#FED7E2',
  '#A3BFFA',
  '#2A4365',
  '#BEE3F8',
  '#81E6D9',
  '#ffffff',
  '#EAF0F6',
  '#00a4bd',
  '#99acc2',
  '#333333',
  '#23496D',
]
export default defineComponent({
  components: { ColorPicker },
  props: {
    modelValue: String,
    defaultColor: {
      type: String,
      default: '',
    },
    selectedColor: {
      type: String,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const colorPickerContainer = ref()
    const btnColorPicker = ref()
    const isOpen = ref(false)
    const state: any = reactive({
      colors,
      color: props.modelValue || '#2196F3',
      colorSelected: computed({
        get: (): string => props.selectedColor || state.color,
        set: (val: { hex: string } | string) => {
          state.color = typeof val === 'string' ? val : val.hex
          return emit('update:modelValue', state.color)
        },
      }),
      tabs,
      activeTab: 'Simple',
      updatePicker: 0,
    })
    function toggleColorPicker(_e: MouseEvent) {
      if (isOpen.value) {
        isOpen.value = false
      } else {
        isOpen.value = true
        createPopper(btnColorPicker.value, colorPickerContainer.value, {
          placement: 'bottom',
        })
      }
      // _e.stopPropagation()
      // _e.preventDefault()
    }
    function changeToDefault(e: MouseEvent) {
      state.color = props.defaultColor
      state.colorSelected = props.defaultColor
      state.updatePicker++
      e.stopPropagation()
    }
    function changeColor(color: any, e: MouseEvent) {
      e.preventDefault()
      e.stopPropagation()
      state.colorSelected = color
    }

    onMounted(() => {
      function handler(event: MouseEvent) {
        const target = event.target as HTMLElement
        const active = document.activeElement
        if (btnColorPicker.value.contains(target)) return
        if (!colorPickerContainer.value.contains(target)) isOpen.value = false
        if (active !== document.body && active?.contains(target)) return // Keep focus on newly clicked/focused element
      }

      window.addEventListener('click', handler)
      onUnmounted(() => window.removeEventListener('click', handler))
    })

    return {
      state,
      colorPickerContainer,
      btnColorPicker,
      isOpen,
      toggleColorPicker,
      changeToDefault,
      changeColor,
    }
  },
})
</script>

<style scoped></style>
