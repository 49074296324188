import { GroupFields, GroupFieldsOptions, IElement } from '../types'

/*
 * pass the elements current elements array
 *   eg: elements = [checkbox_1, checkbox_2]
 *   prefix = checkbox
 * */
export const generateFieldId = (elements: string[], prefix: string) => {
  if (elements.length === 0) return `${prefix}_${1}`
  const regex = new RegExp(`${prefix}_(\\d+)`)
  const highestNumber = Math.max(
    ...elements.map(el => {
      const match = el.match(regex)
      return match ? parseInt(match[1], 10) : 0
    })
  )
  const newNumber = highestNumber + 1
  const newId = `${prefix}_${newNumber}`
  return newId
}

export const getNextIdForGroup = (
  currentGroups: GroupFields<GroupFieldsOptions>[],
  groupType: string
) => {
  const fields = currentGroups.reduce((acc, g) => {
    if (g?.component?.options?.fieldId) {
      acc.push(g?.component?.options?.fieldId as string)
    }
    return acc
  }, [] as string[])
  return generateFieldId(fields, groupType)
}

export const getNextId = (pages: IElement[], prefix: string, type: string) => {
  const fields = pages.reduce((acc, page) => {
    page.children.forEach(child => {
      if (child.type === type) {
        if (child?.component?.options?.fieldId) {
          acc.push(child?.component?.options?.fieldId)
        }
      }
    })
    return acc
  }, [] as string[])
  return generateFieldId(fields, prefix)
}
